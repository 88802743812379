import React from 'react';
// Images
import TomImage from '../../../assets/images/staff/tom1.png';

function HeaderDisplaySection() {
  return (
    <section className='grid w-full text-colour1 overflow-hidden'>
      <div
        style={{
          backgroundImage: `url(${TomImage})`,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      ></div>

      {/* Banners */}
      <section className='hidden lg:grid grid-rows-3 absolute bottom-2 right-0 gap-y-2 h-fit z-30 text-xl font-bold text-right justify-items-end'>
        <article className='grid bg-colour5 pt-1 pl-1 pb-1 rounded-tl-2xl rounded-bl-2xl w-fit h-fit'>
          <div className='border-solid border-colour1 border-t-2 border-b-2 border-l-2 rounded-tl-xl rounded-bl-xl px-10 py-2 shadow-lg bg-gradient-to-b from-[#d47305] to-colour5'>
            <span>UNIQUE SITES</span>
          </div>
        </article>
        <article className='grid bg-colour5 pt-1 pl-1 pb-1 rounded-tl-2xl rounded-bl-2xl w-fit h-fit'>
          <div className='border-solid border-colour1 border-t-2 border-b-2 border-l-2 rounded-tl-xl rounded-bl-xl px-10 py-2 shadow-lg bg-gradient-to-b from-[#d47305] to-colour5'>
            <span>FREE SEO UPDATES</span>
          </div>
        </article>
        <article className='grid bg-colour5 pt-1 pl-1 pb-1 rounded-tl-2xl rounded-bl-2xl w-fit h-fit'>
          <div className='border-solid border-colour1 border-t-2 border-b-2 border-l-2 rounded-tl-xl rounded-bl-xl px-10 py-2 shadow-lg bg-gradient-to-b from-[#d47305] to-colour5'>
            <span>FREE ONE PAGE WEBSITE!</span>
          </div>
        </article>
      </section>
    </section>
  );
}

export default HeaderDisplaySection;
