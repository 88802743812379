import React from 'react';
// Components
import PortfolioAndContactCta from '../cta/PortfolioAndContactCta'

function PortfolioPageMainContainer() {
  return (
    <main role='main'>
      <PortfolioAndContactCta />
    </main>
  );
}

export default PortfolioPageMainContainer;
