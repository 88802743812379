// // React Icons
import {
  FaUserAlt,
} from 'react-icons/fa';
import { GrUserAdmin } from 'react-icons/gr';
import {
  MdForum,
} from 'react-icons/md';
import { HiBookOpen } from 'react-icons/hi';
import { TbMathFunction } from 'react-icons/tb';
import { TbTransformPointTopRight } from 'react-icons/tb';
// Images - curcuit prortfolio
import Circuit1 from '../../assets/images/projects/arduplot-3d-printed-robot.png';
import Circuit3 from '../../assets/images/projects/arduplot-3d-printed-robot-arm.png';
import Circuit4 from '../../assets/images/projects/arduplot-circuit-board-and-housing.png';
import ArduWeb from '../../assets/images/projects/arduplot-website-programming-controller.png';
import Arduino from '../../assets/images/tech/arduino-circuit-board-design.png';
// Video
import ArduplotVideo from '../../assets/video/device/arduplot_robot_demo_working_product.mp4';
// Website Examples
import CoffeeWebsite from '../../assets/images/examples/coffee-shop-home-page-landing-page-website.png';
import PubWebsite from '../../assets/images/examples/pub-resturant-hotel-landing-page-food-website-home.jpg';
import HairSalonWebsite from '../../assets/images/examples/hair-salon-beauty-treatment-home-page-landing-page-website.jpg';
// Portfolio - Arduplot
import ArduPlot3DPorfolioThumbnail from '../../assets/images/portfolio/arduplot3d-website-home-page-portfolio-example.png'
import MyPlanetPorfolioThumbnail from '../../assets/images/portfolio/myplanet-website-home-page-portfolio-example.png'
import MonCardsPorfolioThumbnail from '../../assets/images/portfolio/trading-card-website-home-page-portfolio-example.png'
import PubExamplePorfolioThumbnail from '../../assets/images/portfolio/pub-website-home-page-portfolio-example.png'
import BarberExamplePorfolioThumbnail from '../../assets/images/portfolio/barber-shop-website-home-page-portfolio-example.png'
import FlowersByIreneThumbnail from '../../assets/images/portfolio/flowers-by-irene-website-home-page-portfolio-example.png'
import TimelessPiecesThumbnail from '../../assets/images/portfolio/luxury-watch-website-home-page-portfolio-example.png'
import FreshStopThumbnail from '../../assets/images/portfolio/vegatable-shop-website-home-page-portfolio-example.png'
import FitPalThumbnail from '../../assets/images/portfolio/fitpal-sports-watch-website-home-page-portfolio-example.png'
import MrLittleBricksPorfolioThumbnail from '../../assets/images/portfolio/lego-mr-little-bricks-website-home-page-portfolio-example.png'

// Example projects
// Websites
let displayItemId = 0;
export const displayProjectsArray = [
  {
    id: displayItemId++,
    label: 'Coffee Shop Website',
    productType: 'Web',
    description:
      'A sleek and modern website designed for a cozy coffee shop. Featuring an inviting layout, easy navigation, and rich imagery that brings the aroma of freshly brewed coffee to life.',
    mainImageSource: CoffeeWebsite,
  },
  {
    id: displayItemId++,
    label: 'Pub & Restaurant Website',
    productType: 'Web',
    description:
      'A warm and engaging online presence for a traditional pub and restaurant. This site showcases menu highlights, upcoming events, and charming accommodations, all wrapped in a rustic yet modern aesthetic.',
    mainImageSource: PubWebsite,
  },
  {
    id: displayItemId++,
    label: 'Hair Salon Website',
    productType: 'Web',
    description:
      'A stylish and elegant website crafted for a high-end hair salon. It highlights beauty services, showcases treatment options, and allows easy appointment bookings—all within a clean and sophisticated design.',
    mainImageSource: HairSalonWebsite,
  },
];

// Portfolio page websites
let portfolioPageDataId = 0;
export const portfolioPageDisplayProjectsDataArray = [
  {
    id: portfolioPageDataId++,
    title: 'Timeless Pieces',
    tags: ['sales', 'watches', 'shop'],
    thumbnail: { imagePath: TimelessPiecesThumbnail, altText: 'Timeless Pieces luxury watches and timepieces.' },
    slug: 'timeless-pieces',
    github: 'https://github.com/webdesignsbytom/bytetoast_studio/tree/master/portfolio/src/pages/timelessPiece',
    demoUrl: 'https://portfolio.bytetoast-studio.co.uk/timeless-pieces',
    images: [
      { imagePath: TimelessPiecesThumbnail, altText: 'Timeless Pieces luxury watches and timepieces.' },
      { imagePath: TimelessPiecesThumbnail, altText: 'Timeless Pieces luxury watches and timepieces.' },
      { imagePath: TimelessPiecesThumbnail, altText: 'Timeless Pieces luxury watches and timepieces.' },
    ],
    skills: [
      { name: 'Animated Hero', icon: <TbMathFunction size={20} /> },
      { name: 'Artistic Design', icon: <HiBookOpen size={20} /> },
      { name: 'Shop and Cart', icon: <MdForum size={20} /> },
      { name: 'Newsletter Signup', icon: <GrUserAdmin size={20} /> },
      {
        name: 'Analytics',
        icon: <TbTransformPointTopRight size={20} />,
      },
    ],
    headline: 'Timeless Pieces luxury watches and timepieces.',
    description: 'Timeless Pieces is a classy, classic and inspiring design for a gentlemans watch store. An online shop has to reflect the product you are selling. The artist hero section immediately grabs the eye of the customer. The animation shifting between watches will impress a buying and show off different styles. A simple store with no confusing images or cartoon guides. Just class a luxury as we easily take payments. ',
    featuredComponent: 'Hero Section Animation',
  },
  {
    id: portfolioPageDataId++,
    title: 'FitPal Sports Tracking Wearable Technology',
    tags: ['sports', 'sales', 'shop'],
    thumbnail: { imagePath: FitPalThumbnail, altText: 'FitPal Sports Tracking Wearable Technology website.' },
    slug: 'fitpal',
    github: 'https://github.com/webdesignsbytom/bytetoast_studio/tree/master/portfolio/src/pages/fitpal',
    demoUrl: 'https://portfolio.bytetoast-studio.co.uk/fitpal',
    images: [
      { imagePath: FitPalThumbnail, altText: 'FitPal Sports Tracking Wearable Technology website.' },
      { imagePath: FitPalThumbnail, altText: 'FitPal Sports Tracking Wearable Technology website.' },
      { imagePath: FitPalThumbnail, altText: 'FitPal Sports Tracking Wearable Technology website.' },
    ],
    skills: [
      { name: 'Search Bar', icon: <TbMathFunction size={20} /> },
      { name: 'Discount Codes', icon: <HiBookOpen size={20} /> },
      { name: 'Store Front', icon: <MdForum size={20} /> },
    ],
    headline: 'FitPal Sports Tracking Wearable Technology',
    description: 'FitPal is a sales website and membership program sign up platform. Allowing users to purchase the latest sports tech and track there progress all in one place. With a sleek and colourful modern home page, this site is a great way to attract customers to a niche or colourful product.',
    featuredComponent: 'Booking for food',
  },
  {
    id: portfolioPageDataId++,
    title: 'ArduPlot3D',
    tags: ['wireless', 'plotting', 'canvas'],
    thumbnail: { imagePath: ArduPlot3DPorfolioThumbnail, altText: 'ArduPlot3D' },
    slug: 'arduplot3d',
    github: 'https://github.com/webdesignsbytom/arduPlot3D',
    demoUrl: 'https://arduplot3d.netlify.app',
    images: [
      { imagePath: ArduPlot3DPorfolioThumbnail, altText: 'ArduPlot3D' },
      { imagePath: ArduPlot3DPorfolioThumbnail, altText: 'ArduPlot3D' },
      { imagePath: ArduPlot3DPorfolioThumbnail, altText: 'ArduPlot3D' },
    ],
    skills: [
      { name: 'Advanced Algorithms', icon: <TbMathFunction size={20} /> },
      { name: 'Interactive Tutorial', icon: <HiBookOpen size={20} /> },
      { name: 'Plotting canvas', icon: <MdForum size={20} /> },
      { name: 'Bluetooth/WiFi', icon: <GrUserAdmin size={20} /> },
      {
        name: 'Graphical language',
        icon: <TbTransformPointTopRight size={20} />,
      },
      { name: 'Robot design', icon: <FaUserAlt size={20} /> },
    ],
    headline: 'Programming robots on how to move.',
    description: 'This website works with the ArduPlot robot built using arduino technologies. Using the canvas element to create a plotting grid the size of a mobile phone. The user can create various types of plot points using the buttons to select from. Tap, drag, timeout, move and pinching movements. The website converts this plot data to a grahpical plotting laugauge we custom made. This tells the machine how and where to move and at what speeds. You can transfer the data via SD card, bluetooth or wifi with certain models.',
    featuredComponent: 'Plotting Canvas',
    featuredDisplay: '',
  },
  {
    id: portfolioPageDataId++,
    title: 'Mr Little Bricks',
    tags: ['youtube', 'profile', 'influence'],
    thumbnail: { imagePath: MrLittleBricksPorfolioThumbnail, altText: 'Mr Little Bricks lego youtuber' },
    slug: 'mr-little-bricks',
    github: 'https://github.com/webdesignsbytom/mr-little-bricks',
    demoUrl: 'https://mr-little-bricks.netlify.app/',
    images: [
      { imagePath: MrLittleBricksPorfolioThumbnail, altText: 'Mr Little Bricks lego youtuber' },
      { imagePath: MrLittleBricksPorfolioThumbnail, altText: 'Mr Little Bricks lego youtuber' },
      { imagePath: MrLittleBricksPorfolioThumbnail, altText: 'Mr Little Bricks lego youtuber' },
    ],
    skills: [
      { name: 'Custom Logo', icon: <TbMathFunction /> },
      { name: 'Brick Based Art', icon: <HiBookOpen /> },
      { name: 'Embedded Videos', icon: <MdForum /> },
      { name: 'Custom Photos', icon: <GrUserAdmin /> },
    ],
    headline: 'YouTuber Mr Little Bricks Lego Website',
    description: 'Build to widely publisise the YouTube channel Mr Little Bricks, a fun and appropriate take on a Lego builders website. Focusing on the user profile and showing and linking to as many YouTube videos as possible. ',
    featuredComponent: 'Movable Lego Pieces',
  },
  {
    id: portfolioPageDataId++,
    title: 'My Planet',
    tags: ['members', 'interactive', 'media'],
    thumbnail: { imagePath: MyPlanetPorfolioThumbnail, altText: 'My Planet travel and adventure planning.' },
    slug: 'myplanet',
    github: 'https://github.com/webdesignsbytom/my_planet',
    demoUrl: 'https://myplanetview.netlify.app/',
    images: [
      { imagePath: MyPlanetPorfolioThumbnail, altText: 'My Planet travel and adventure planning.' },
      { imagePath: MyPlanetPorfolioThumbnail, altText: 'My Planet travel and adventure planning.' },
      { imagePath: MyPlanetPorfolioThumbnail, altText: 'My Planet travel and adventure planning.' },
    ],
    skills: [
      { name: 'Interactive World Map', icon: <TbMathFunction size={20} /> },
      { name: 'Video Uploads', icon: <HiBookOpen size={20} /> },
      { name: 'Custom Backgrounds', icon: <MdForum size={20} /> },
      { name: 'Sun and Moon Tracing', icon: <GrUserAdmin size={20} /> },
      {
        name: 'Slideshow Generator', 
        icon: <TbTransformPointTopRight size={20} />,
      },
    ],
    headline: 'My Planet travel and adventure planning guide.',
    description: 'My Planet was a Travel website prototype we built to show off what we can do. Creating a interactive world map and building a unique profile and tourist points of interest for each country. You can upload images to your profile for each country you have visted and generate a slideshow of your travels. There is also a profile settings for one person, a couple or a family so everyone can take part. There are also mutliple map styles to select from and minor animations of sun and moon. ',
    featuredComponent: 'Animated Astrological Events',
  },
  {
    id: portfolioPageDataId++,
    title: 'MonCards',
    tags: ['members', 'game', 'interactive'],
    thumbnail: { imagePath: MonCardsPorfolioThumbnail, altText: 'MonCards monster trading card game.' },
    slug: 'moncards',
    github: 'https://github.com/webdesignsbytom/moncards',
    demoUrl: 'https://mon-cards.netlify.app/',
    images: [
      { imagePath: MonCardsPorfolioThumbnail, altText: 'MonCards monster trading card game.' },
      { imagePath: MonCardsPorfolioThumbnail, altText: 'MonCards monster trading card game.' },
      { imagePath: MonCardsPorfolioThumbnail, altText: 'MonCards monster trading card game.' },
    ],
    skills: [
      { name: 'Advanced Algorithms', icon: <TbMathFunction size={20} /> },
      { name: 'Interactive Tutorial', icon: <HiBookOpen size={20} /> },
      { name: 'Plotting canvas', icon: <MdForum size={20} /> },
      { name: 'Bluetooth/WiFi', icon: <GrUserAdmin size={20} /> },
      {
        name: 'Graphical language',
        icon: <TbTransformPointTopRight size={20} />,
      },
      { name: 'Robot design', icon: <FaUserAlt size={20} /> },
    ],
    headline: 'MonCards monster trading card game.',
    description: 'MonCards or Monster Cards, is a generic trading card games based on Pokemon with a fantasy theme. We wanted to create a project with a massive amount of requirements. The site has users collecting and trading cards. The battle zone and card game can be played between players online. A shop to buy card packs, decks and magic items. Daily rewards for user engagement. A customizable card album and user records book. There are all the features users would expect from a large online game. We are also very proud of the CSS only Holgraphic cards you can get in packs. The availablity of this game is currently limited because the server costs would be huge to host it.',
    featuredComponent: 'Shiny Holographic Cards',
  },
  {
    id: portfolioPageDataId++,
    title: 'Classic Pub',
    tags: ['portfolio', 'business', 'place'],
    thumbnail: { imagePath: PubExamplePorfolioThumbnail, altText: 'Classic pub and resturant website.' },
    slug: 'classic-pub',
    github: 'https://github.com/webdesignsbytom/classic-pub',
    demoUrl: 'https://classic-pub.netlify.app',
    images: [
      { imagePath: PubExamplePorfolioThumbnail, altText: 'Classic pub and resturant website.' },
      { imagePath: PubExamplePorfolioThumbnail, altText: 'Classic pub and resturant website.' },
      { imagePath: PubExamplePorfolioThumbnail, altText: 'Classic pub and resturant website.' },
    ],
    skills: [
      { name: '100% SEO', icon: <MdForum size={20} /> },
      { name: 'Analytics', icon: <GrUserAdmin size={20} /> },
      { name: 'Trendy Map', icon: <GrUserAdmin size={20} /> },
      { name: 'Menu and Booking', icon: <GrUserAdmin size={20} /> },
    ],
    headline: 'Classic English Pub Website',
    description: 'A traditional English pub website with a focus on providing essential information for customers. Features include a detailed menu, pub history, upcoming events, and food booking options. Designed with classic aesthetics to mirror the warm and inviting ambiance of a real pub. Add a booking system to your website to save time and money filling your',
    featuredComponent: 'Booking for food',
    featuredDisplay: '',
  },
  {
    id: portfolioPageDataId++,
    title: 'Flowers by Irene',
    tags: ['sales', 'shop', 'florist'],
    thumbnail: { imagePath: FlowersByIreneThumbnail, altText: 'Flowers by Irene website.' },
    slug: 'flowers-by-irene',
    github: 'https://github.com/webdesignsbytom/bytetoast_studio/tree/master/portfolio/src/pages/fbi',
    demoUrl: 'https://portfolio.bytetoast-studio.co.uk/flowers-by-irene',
    images: [
      { imagePath: FlowersByIreneThumbnail, altText: 'Flowers by Irene website.' },
      { imagePath: FlowersByIreneThumbnail, altText: 'Flowers by Irene website.' },
      { imagePath: FlowersByIreneThumbnail, altText: 'Flowers by Irene website.' },
    ],
    skills: [
      { name: 'Online Store', icon: <HiBookOpen size={20} /> },
      { name: '100% SEO', icon: <MdForum size={20} /> },
      { name: 'Analytics', icon: <GrUserAdmin size={20} /> },
    ],
    headline: 'Elegant Florist Website',
    description: 'A beautifully designed website for a florist, featuring natural and calming colors that highlight the beauty of flowers. Includes an online shop for purchasing bouquets, arrangements, and floral gifts. The site embodies a tranquil and fresh aesthetic, making it perfect for flower lovers.',
    featuredComponent: 'Floral delights for any occasion.',
  },
  {
    id: portfolioPageDataId++,
    title: 'Gents barber shop',
    tags: ['business', 'hair', 'place'],
    thumbnail: { imagePath: BarberExamplePorfolioThumbnail, altText: 'Gents barber shop website.' },
    slug: 'barber-shop',
    github: 'https://github.com/webdesignsbytom/barber-shop',
    demoUrl: 'https://barber-shop.netlify.app',
    images: [
      { imagePath: BarberExamplePorfolioThumbnail, altText: 'Gents barber shop website.' },
      { imagePath: BarberExamplePorfolioThumbnail, altText: 'Gents barber shop website.' },
      { imagePath: BarberExamplePorfolioThumbnail, altText: 'Gents barber shop website.' },
    ],
    skills: [
      { name: 'Featured Haircuts', icon: <HiBookOpen size={20} /> },
      { name: 'Booking System', icon: <HiBookOpen size={20} /> },
      { name: '100% SEO', icon: <MdForum size={20} /> },
      { name: 'Analytics', icon: <GrUserAdmin size={20} /> },
    ],
    headline: 'Classic pub and resturant example page.',
    description: 'A sleek and straightforward website for a classic men’s barber shop. Highlights include services offered, pricing details, and a gallery of haircuts. Designed with bold, masculine aesthetics to create a modern yet timeless look for a traditional barbershop experience. Showing cuts and fades as an example and letting customers know where you are and when you are open. With 100% SEO scores to get you high up the google page ranking. A booking system can easily save you time and effort making money.',
    featuredComponent: 'Featured Haircuts',
  },
  {
    id: portfolioPageDataId++,
    title: 'Fresh Stop vegetable store',
    tags: ['shop', 'sales', 'food'],
    thumbnail: { imagePath: FreshStopThumbnail, altText: 'Fresh Stop vegetable store website.' },
    slug: 'fresh-stop',
    github: 'https://github.com/webdesignsbytom/bytetoast_studio/tree/master/portfolio/src/pages/freshStop',
    demoUrl: 'https://portfolio.bytetoast-studio.co.uk/fresh-stop',
    images: [
      { imagePath: FreshStopThumbnail, altText: 'Fresh Stop vegetable store website.' },
      { imagePath: FreshStopThumbnail, altText: 'Fresh Stop vegetable store website.' },
      { imagePath: FreshStopThumbnail, altText: 'Fresh Stop vegetable store website.' },
    ],
    skills: [
      { name: 'Search Bar', icon: <TbMathFunction size={20} /> },
      { name: 'Discount Codes', icon: <HiBookOpen size={20} /> },
      { name: 'Store Front', icon: <MdForum size={20} /> },
    ],
    headline: 'Classic pub and resturant example page.',
    description: 'An online store offering fresh vegetables and fruits, complete with a shopping cart and checkout system. The site is vibrant and user-friendly, showcasing fresh produce with high-quality imagery and an intuitive interface for a seamless shopping experience. ',
    featuredComponent: 'Booking for food',
  },
];


export const circuitDisplayItemData = {
  mainImage: Circuit1,
  images: [
    Circuit1,
    Circuit4,
    ArduWeb,
    Circuit3,
    Circuit1,
    Arduino,
    ArduWeb,
    Circuit3,
  ],
  video: ArduplotVideo,
  description: [
    `This robot is 100% custom designed and built to serve a purpose to simplify life. It is programmed to play a game on a mobile phone. The arm moves along a grid and presses down the finger on the Z axis when instructed. The arm is programmed via a website where you can plot points on a phone display to move as and when you desire. `,
    `The project is a great example of automation and modern methods of controlling and programming the machine without needing technical skill. This technology can be adapted in hundreds of ways to suit your needs. It can be equipped with cameras and more arms or fingers.`,
    `Cheap to make coming in at under £400 in parts and materials and printing power.`,
  ],
};
