import React, { useEffect } from 'react';
// Constants
import { CompanyName } from '../../utils/Constants';
// Components
import Navbar from '../../components/nav/Navbar';
import { HelmetItem } from '../../components/utils/HelmetItem';
import FooterComponent from '../../components/footer/FooterComponent';
import HeaderServices from '../../components/headers/services/HeaderServices';
import ProjectTypesComponent from '../../components/projects/ProjectTypesComponent';
import ProjectStepsComponent from '../../components/projects/ProjectStepsComponent';
import BoltOnsCta from '../../components/cta/BoltOnsCta';
import PortfolioAndContactCta from '../../components/cta/PortfolioAndContactCta';
// Data
import {
  servicesPageAdditionalMeta,
  servicesPageStructuredData,
} from '../../utils/data/MetaData';
import { MainServicesArray } from '../../utils/data/CompanyData';

function ServicesPage() {
  useEffect(() => {
    const serviceItems = document.querySelectorAll('.service-item');
    serviceItems.forEach((item, index) => {
      setTimeout(() => {
        item.classList.add('fade-up-animation');
      }, index * 300); 
    });
  }, []);

  return (
    <>
      <HelmetItem
        PageName='Services'
        desc={`Discover the wide range of services offered by ${CompanyName}, including web design, circuit design, and more.`}
        keywords={`services, web design, circuit design, robotics, ${CompanyName}, custom solutions`}
        additionalMeta={servicesPageAdditionalMeta}
        structuredData={servicesPageStructuredData}
      />

      <div className='grid min-h-screen bg-main-background font-poppins overflow-x-hidden overflow-y-auto'>
        <div className='grid gap-28 h-full w-full'>
          {/* Header + Navigation */}
          <section className='grid relative md:h-screen min-h-screen'>
            <div className='grid grid-rows-reg bg-green-200'>
              <Navbar />
              <HeaderServices />
            </div>

            {/* Phone animations */}
            <section className='absolute grid lg:hidden gap-6 top-2/3 left-1/2 transform -translate-x-1/2 z-10 w-full px-8'>
              {MainServicesArray.map((service) => (
                <div
                  key={service.label}
                  className='service-item grid grid-cols-reg w-full h-fit bg-colour1 no__highlights px-4 border-solid border-colour5 border-4 rounded-lg py-4 shadow-cardShadow max-w-[500px] mx-auto'
                >
                  <div className='grid items-center justify-center'>
                    {service.icon}
                  </div>
                  <div className='text-center'>
                    <h4>{service.label}</h4>
                  </div>
                </div>
              ))}
            </section>
          </section>

          {/* Main page content */}
          <main role='main' className='grid mt-28 lg:-mt-8 gap-28'>
            <div>
              <ProjectTypesComponent />
            </div>
            <div>
              <ProjectStepsComponent />
            </div>
            <div>
              <PortfolioAndContactCta isCentered={true} />
            </div>
            <div>
              <BoltOnsCta isCentered={false} />
            </div>
          </main>

          {/* Footer */}
          <FooterComponent />
        </div>
      </div>
    </>
  );
}

export default ServicesPage;
