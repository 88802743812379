import React from 'react';

function SubHeaderComponent({ title, subtitle, isCentered }) {
  return (
    <>
      <section className={`grid justify-center ${isCentered ? 'lg:justify-center' : 'lg:justify-start'}`}>
        <article className='grid'>
          <div className={`text-center ${isCentered ? 'lg:text-center' : 'lg:text-left'} py-2`}>
            <h5 className='text-gray-600'>{subtitle}</h5>
            <h4 className='text-colour3 font-semibold lg:text-lg'>
              {title}
            </h4>
            <div className='border-b-2 border-solid border-colour5 pt-2'></div>
          </div>
        </article>
      </section>
    </>
  );
}

export default SubHeaderComponent;
