import React from 'react';
import { ProjectCompletionStepsArray } from '../../utils/data/CompanyData';
import SubHeaderComponent from '../headers/SubHeaderComponent';

function ProjectStepsComponent() {
  return (
    <section
      className='grid h-full w-full min-h-screen'
      aria-labelledby='project-steps-heading'
    >
      <div className='grid grid-rows-reg gap-8 h-full w-4/5 mx-auto'>
        <SubHeaderComponent
          isCentered={false}
          title={'The Process of Building Your Project'}
          subtitle={'Production Steps'}
        />

        <section className='grid'>
          <div className='grid items-center xl:px-4'>
            <div className='grid grid-rows-2 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 h-fit gap-x-4 gap-y-6'>
              {ProjectCompletionStepsArray.map((projectType, index) => (
                <StepCard key={index} projectType={projectType} />
              ))}
            </div>
          </div>
        </section>
      </div>
    </section>
  );
}

function StepCard({ projectType }) {
  return (
    <article
      role='region'
      aria-labelledby={`step-label-${projectType.label}`}
      className='border-2 border-colour5 border-solid w-full px-4 py-6 rounded shadow-cardShadow'
    >
      <div className='grid grid-cols-reg gap-4 px-1'>
        <div className='grid items-center'>
          <h3
            id={`step-label-${projectType.label}`}
            className='text-lg font-semibold leading-6 text-colour3'
          >
            {projectType.label}
          </h3>
        </div>
        <div className='grid justify-end'>
          <div
            className='grid items-center justify-center min-w-12 min-h-12 w-12 h-12 bg-gray-200 rounded'
            aria-hidden='true'
          >
            <span
              className='text-colour5 text-xl'
              aria-label={`${projectType.label} step icon`}
            >
              {projectType.icon}
            </span>
          </div>
        </div>
      </div>
      <div className='pt-2'>
        <p className='text-gray-600 text-sm'>{projectType.content}</p>
      </div>
    </article>
  );
}

export default ProjectStepsComponent;
