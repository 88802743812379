import React, { useEffect, useState } from 'react';
import { isTablet } from 'react-device-detect';
// Images
import LaptopLidClosed from '../../assets/images/backgrounds/laptop-animation-closed-lid-part-svg.svg';
import LaptopLidOpen from '../../assets/images/backgrounds/laptop-animation-open-lid-part-svg.svg';
import LaptopBase from '../../assets/images/backgrounds/laptop-animation-base-part-svg.svg';

function LaptopAnimationComponent({
  portfolioWebItems,
  currentIndex,
  setCurrentIndex,
}) {
  const [isFirstRun, setIsFirstRun] = useState(true);

  useEffect(() => {
    let interval;

    if (isFirstRun) {
      const timeout = setTimeout(() => {
        setIsFirstRun(false);
        interval = setInterval(() => {
          setCurrentIndex(
            (prevIndex) => (prevIndex + 1) % portfolioWebItems.length
          );
        }, 2200);
      }, 2000);

      return () => clearTimeout(timeout);
    } else {
      interval = setInterval(() => {
        setCurrentIndex(
          (prevIndex) => (prevIndex + 1) % portfolioWebItems.length
        );
      }, 2200);
    }

    return () => clearInterval(interval);
  }, [isFirstRun, portfolioWebItems.length, setCurrentIndex]);


  const selectNextItem = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % portfolioWebItems.length);
  };

  const selectPreviousItem = () => {
    setCurrentIndex(
      (prevIndex) =>
        (prevIndex - 1 + portfolioWebItems.length) % portfolioWebItems.length
    );
  };

  useEffect(() => {
    const laptopContainer = document.getElementById('laptop_container');
    let touchStartX = 0;
    let touchEndX = 0;

    const handleSwipe = () => {
      if (touchEndX < touchStartX - 50) {
        selectNextItem();
      }
      if (touchEndX > touchStartX + 50) {
        selectPreviousItem();
      }
    };

    const handleTouchStart = (e) => {
      touchStartX = e.changedTouches[0].screenX;
    };

    const handleTouchEnd = (e) => {
      touchEndX = e.changedTouches[0].screenX;
      handleSwipe();
    };

    laptopContainer.addEventListener('touchstart', handleTouchStart);
    laptopContainer.addEventListener('touchend', handleTouchEnd);

    setTimeout(() => {
      laptopContainer.classList.add('isActive');
    }, 1600);

    return () => {
      laptopContainer.removeEventListener('touchstart', handleTouchStart);
      laptopContainer.removeEventListener('touchend', handleTouchEnd);
    };
  }, []);

  return (
    <section className='grid h-full w-full px-2 min-h-[75vh] no__highlights'>
      <div className='grid h-full w-full p-2 laptop_border_animation'>
        <section className='grid w-full h-full items-center no__highlights'>
          <section className='grid text-center title_appearing md:w-fit md:mx-auto'>
            <h2 className='text-colour3 font-medium text-2xl'>
              Swipe for more website examples
            </h2>
            <div className='border-b-2 border-solid border-colour5 pt-2'></div>
          </section>
          <section className='w-[88vw] mx-auto relative'>
            {/* Computer buttons */}
            {!isTablet && (
              <>
                <section className='hidden lg:grid absolute top-1/2 xl:left-10 w-fit z-50 text-black'>
                  <button
                    onClick={selectPreviousItem}
                    aria-label='Show previous portfolio item'
                    className='bg-colour5 text-colour1 font-bold px-4 py-2 rounded-lg shadow-md active:scale-95 active:shadow-colour5/50'
                  >
                    Prev
                  </button>
                </section>
                <section className='hidden lg:grid absolute top-1/2 right-0 xl:right-10 w-fit z-50 text-black'>
                  <button
                    onClick={selectNextItem}
                    aria-label='Show next portfolio item'
                    className='bg-colour5 text-colour1 font-bold px-4 py-2 rounded-lg shadow-md active:scale-95 active:shadow-colour5/50'
                  >
                    Next
                  </button>
                </section>
              </>
            )}
            <div className='text-center touch-none scale-in-center py-8 px-2 sm:px-4 w-fit mx-auto'>
              <section
                id='laptop_container'
                className='mockup mockup-macbook loaded opened h-fit'
              >
                <div className='part top'>
                  <img
                    src={LaptopLidClosed}
                    alt='Laptop closed lid component for animation'
                    className='top part x-class'
                  />
                  <img
                    src={LaptopLidOpen}
                    alt='Laptop open lid component for animation'
                    className='cover part hidden'
                  />

                  <section className='w-full h-full absolute top-0 left-0 p-2'>
                    <div className='relative w-full h-full'>
                      <img
                        src={
                          portfolioWebItems[currentIndex].thumbnail.imagePath
                        }
                        aria-live='polite'
                        aria-atomic='true'
                        alt={portfolioWebItems[currentIndex].thumbnail.altText}
                        className='main_portfolio_image border-[1px] border-solid border-gray-400 rounded-md'
                      />
                    </div>
                  </section>
                </div>
                <div className='part bottom'>
                  <img
                    src={LaptopLidOpen}
                    alt='Laptop open lid component for animation'
                    className='cover part'
                  />
                  <img
                    src={LaptopBase}
                    alt='Laptop base component for animation'
                    className='bottom part'
                  />
                </div>
              </section>
            </div>
          </section>
        </section>
      </div>
    </section>
  );
}

export default LaptopAnimationComponent;
