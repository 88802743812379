export const boltOnServicesArray = [
  { 
    id: "automatic-text-replies", 
    title: "Automatic Text Replies", 
    price: "£5/month", 
    description: "Never miss a customer with automated text replies for missed calls.", 
    details: "Set up automatic text replies to inform customers about their missed calls. Improve engagement and keep your leads warm without manual effort.", 
    icon: "📱" 
  },
  { 
    id: "booking-system-integration", 
    title: "Booking System Integration", 
    price: "£7/month", 
    description: "Let customers book appointments directly from your site.", 
    details: "Integrate a seamless booking system tailored to your business needs. Ideal for service-based industries like salons, consultations, or gyms.", 
    icon: "📅" 
  },
  { 
    id: "live-chat-support", 
    title: "Live Chat Support", 
    price: "£25/month", 
    description: "Provide instant help with live chat support.", 
    details: "Enable real-time communication on your website to boost customer satisfaction and sales. Customizable to your brand.", 
    icon: "💬" 
  },
  { 
    id: "seo-optimization", 
    title: "SEO Optimization", 
    price: "£30/month", 
    description: "Improve your search engine rankings.", 
    details: "Boost your online visibility by optimizing your website's content and structure. Stay ahead of the competition with top-notch SEO techniques. We update your site weekly to get you on top.", 
    icon: "🔍" 
  },
  { 
    id: "performance-analytics", 
    title: "Performance Analytics", 
    price: "£10/month", 
    description: "Track and improve site performance.", 
    details: "Get detailed insights into your website's traffic, performance, and user behavior. Optimize based on real data. Learn where people are clicking and where you can improve.", 
    icon: "📊" 
  },
  { 
    id: "email-accounts", 
    title: "Email Accounts", 
    price: "£3/month", 
    description: "Unlimted company wide email addresses.", 
    details: "Reach customers with professional personal or business email address and set up employee accounts or help desks.", 
    icon: "✉️" 
  },
  { 
    id: "custom-contact-forms", 
    title: "Custom Contact Forms", 
    price: "£5/month", 
    description: "Tailored forms for any business need.", 
    details: "Create highly customized forms to capture user data efficiently. Fully responsive and easy to manage.", 
    icon: "📄" 
  },
  { 
    id: "social-media-feed-integration", 
    title: "Social Media Feed Integration", 
    price: "£25/month", 
    description: "Display live social media updates on your site.", 
    details: "Engage users with live social media feeds directly on your website. Connect Instagram, Twitter, or Facebook in real time.", 
    icon: "🌐" 
  },
  { 
    id: "payment-gateway-integration", 
    title: "Payment Gateway Integration", 
    price: "£35/month", 
    description: "Secure and seamless online payments.", 
    details: "Accept payments online via Stripe, PayPal, or custom gateways. Ensure secure transactions for your customers.", 
    icon: "💳" 
  },
  { 
    id: "content-management-system", 
    title: "Content Management System", 
    price: "£50/month", 
    description: "Easily update your site's content.", 
    details: "Add, edit, or remove content on the fly with a custom CMS built for your business.", 
    icon: "📝" 
  },
  { 
    id: "advanced-security-monitoring", 
    title: "Advanced Security Monitoring", 
    price: "£35/month", 
    description: "Protect your site from cyber threats.", 
    details: "Keep your website secure with 24/7 monitoring and advanced security features. Automated backups and vulnerability alerts included.", 
    icon: "🔐" 
  },
  { 
    id: "push-notification-integration", 
    title: "Push Notification Integration", 
    price: "£12/month", 
    description: "Engage users with push notifications.", 
    details: "Notify your users about updates, offers, and more directly on their devices.", 
    icon: "🔔" 
  },
  { 
    id: "event-calendar-integration", 
    title: "Event Calendar Integration", 
    price: "£6/month", 
    description: "Keep users informed about your events.", 
    details: "Display your upcoming events with an interactive calendar. Sync with Google or Outlook.", 
    icon: "📆" 
  },
  { 
    id: "automated-reports", 
    title: "Automated Reports", 
    price: "£22/month", 
    description: "Receive regular performance insights.", 
    details: "Automate the generation of performance reports. Analyze your progress without lifting a finger.", 
    icon: "📈" 
  },
];
