import React from 'react';
// Constants
import { CompanyName } from '../../utils/Constants';
// Components
import Navbar from '../../components/nav/Navbar';
import { HelmetItem } from '../../components/utils/HelmetItem';
import FooterComponent from '../../components/footer/FooterComponent';
import PortfolioAndContactCta from '../../components/cta/PortfolioAndContactCta';

import ShowcasePageHeader from '../../components/headers/showcase/ShowcasePageHeader';
import FrontendShowcaseComponent from '../../components/showcase/FrontendShowcaseComponent';
import ServerDbShowcaseComponent from '../../components/showcase/ServerDbShowcaseComponent';
import CircuitShowcaseComponent from '../../components/showcase/CircuitShowcaseComponent';
import RobotShowcaseComponent from '../../components/showcase/RobotShowcaseComponent';
import ArduplotShowcaseSummaryComponent from '../../components/showcase/ArduplotShowcaseSummaryComponent';
// Data
import { showcasePageAdditionalMeta, showcasePageStructuredData } from '../../utils/data/MetaData';

function ShowcasePage() {
  return (
    <>
      {/* Tab Data */}
      <HelmetItem
        PageName="Showcase"
        desc={`Explore the diverse showcase prject of ${CompanyName}, featuring projects in web design, server/database management, circuit design, and robotics.`}
        keywords={`showcase, web design, circuit design, robotics, ${CompanyName}, projects, case studies`}
        additionalMeta={showcasePageAdditionalMeta}
        structuredData={showcasePageStructuredData}
      />

      {/* Page start */}
      <div className='grid min-h-screen bg-main-background font-poppins overflow-x-hidden overflow-y-auto'>
        <div className='grid gap-28 h-full w-full'>
          {/* Header + Navigation */}
          <section className='grid min-h-screen'>
            <div className='grid grid-rows-reg h-full'>
              <Navbar />
              <ShowcasePageHeader />
            </div>
          </section>

          {/* Main page content */}
          <main
            role='main'
            className='grid gap-20 md:gap-28 xl:gap-36 min-h-screen px-4 sm:px-10 lg:px-32 xl:px-40 2xl:px-48'
          >
            <div>
              <FrontendShowcaseComponent />
            </div>
            <div>
              <ServerDbShowcaseComponent />
            </div>
            <div>
              <CircuitShowcaseComponent />
            </div>
            <div>
              <RobotShowcaseComponent />
            </div>
            <div>
              <ArduplotShowcaseSummaryComponent />
            </div>
          </main>
            <div>
              <PortfolioAndContactCta />
            </div>

          {/* Footer */}
          <FooterComponent />
        </div>
      </div>
    </>
  );
}

export default ShowcasePage;
