import React, { useEffect, useState } from 'react';
// Components
import DesignInformationHeader from './DesignInformationHeader';
// Icons
import { BsCheck2Circle } from 'react-icons/bs';
import { FaClipboardQuestion } from 'react-icons/fa6';

function ProjectDesignInformationComponent({ type, changeDisplayedProduct }) {
  const [showAll, setShowAll] = useState(false);
  const [displayedServices, setDisplayedServices] = useState([]);
  const [activeFaqIndex, setActiveFaqIndex] = useState(null);

  useEffect(() => {
    if (type && type.services) {
      setDisplayedServices(showAll ? type.services : type.services.slice(0, 5));
    }
  }, [showAll, type]);

  const toggleFaq = (index) => {
    setActiveFaqIndex(activeFaqIndex === index ? null : index);
  };

  return (
    <section
      className='grid w-full h-full px-4 sm:px-10 mt-4'
      aria-labelledby='project-design-info-header'
    >
      <div className='grid mx-auto bg-white h-fit w-full rounded-lg overflow-hidden shadow-cardShadow'>
        {/* Header */}
        <DesignInformationHeader
          type={type}
          changeDisplayedProduct={changeDisplayedProduct}
        />

        <section
          className='grid lg:grid-cols-2 gap-4 h-full w-full px-2 py-2 md:px-4 md:py-4'
          aria-labelledby='design-info-section'
        >
          {/* Main Article */}
          <article
            className='grid h-full'
            role='region'
            aria-labelledby='design-info-article'
          >
            <div
              className='grid text-sm h-full bg-white shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] rounded-lg px-4 sm:px-8 py-6 sm:py-8 lg:px-8'
              id='design-info-article'
            >
              <article className='grid gap-6'>
                <section>
                  <h3
                    className='text-2xl text-colour3 font-medium'
                    id='project-title'
                  >
                    {type?.articleTitle}
                  </h3>
                </section>
                <section>
                  <p className='text-lg' id='project-para1'>
                    {type?.content?.para1}
                  </p>
                </section>
                <section>
                  <p className='text-lg' id='project-para2'>
                    {type?.content?.para2}
                  </p>
                </section>
              </article>
            </div>
          </article>

          {/* Services and FAQ Section */}
          <section
            className='grid grid-rows-[auto_auto] gap-4'
            aria-labelledby='services-and-faq'
          >
            {/* Services Section */}
            <article
              className='grid gap-4 bg-white shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] rounded-lg px-2 lg:px-4 pb-2 pt-4'
              role='region'
              aria-labelledby='services-section'
            >
              <section
                className='grid w-fit mx-auto h-fit'
                id='services-section'
              >
                <h3
                  className='text-colour3 text-center font-medium text-xl lg:text-2xl mb-2'
                  aria-label='Services Offered'
                >
                  Services Offered
                </h3>
                <div
                  className='border-b-2 border-solid border-colour5'
                  role='separator'
                  aria-hidden='true'
                ></div>
              </section>
              <section
                className='grid lg:overflow-y-auto h-full lg:max-h-[300px] overflow-x-hidden'
                aria-label='List of services'
              >
                <ul className='grid gap-2 justify-items-center px-2 pb-4'>
                  {displayedServices?.map((service, index) => (
                    <li key={index} className='flex gap-2 text-center'>
                      <div className='grid min-w-[24px]'>
                        <BsCheck2Circle
                          className='text-xl text-colour5'
                          aria-hidden='true'
                        />
                      </div>
                      <span
                        className={`font-medium ${
                          index % 2 && 'text-colour3'
                        }`}
                      >
                        {service}
                      </span>
                    </li>
                  ))}
                </ul>
              </section>
            </article>

            {/* FAQ Section */}
            <section className='grid' aria-labelledby='faq-section'>
              <article
                className='grid gap-4 bg-white shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] rounded-lg'
                id='faq-section'
              >
                <section className='grid items-center px-4 py-2 border-b-2 border-solid border-gray-600'>
                  <h4
                    className='text-colour3 flex font-medium items-center gap-2 text-lg'
                    aria-label='Frequently Asked Questions'
                  >
                    FAQ
                    <FaClipboardQuestion className='text-colour5' />
                  </h4>
                </section>
                <section className='grid px-4 pb-4'>
                  {type?.designCriteria?.map((criteria, index) => (
                    <section
                      key={index}
                      className='border-b-2 border-solid border-gray-300'
                      aria-labelledby={`faq-question-${index}`}
                    >
                      <button
                        id={`faq-question-${index}`}
                        className='w-full flex justify-between items-center text-left text-lg font-medium text-gray-800 focus:outline-none'
                        onClick={() => toggleFaq(index)}
                        aria-expanded={activeFaqIndex === index}
                        aria-controls={`faq-answer-${index}`}
                      >
                        <span>{criteria.title}</span>
                        <span>{activeFaqIndex === index ? '-' : '+'}</span>
                      </button>
                      {activeFaqIndex === index && (
                        <div
                          id={`faq-answer-${index}`}
                          className='mt-2 text-gray-600'
                          role='region'
                          aria-live='polite'
                        >
                          {criteria.question}
                        </div>
                      )}
                    </section>
                  ))}
                </section>
              </article>
            </section>
          </section>
        </section>
      </div>
    </section>
  );
}

export default ProjectDesignInformationComponent;
