import React, { useState } from 'react';
// Images
import BoltOnSVG from '../../assets/images/icons/bolt-ons-nut-and-bolts-icon.svg';
// Components
import SubHeaderComponent from '../headers/SubHeaderComponent';
import {
  CompanyName,
  CONTACT_PAGE_URL,
  INFO_PAGE_URL,
} from '../../utils/Constants';
import { boltOnServicesArray } from '../../utils/data/InfoPageData';

function BoltOnsCta({ isCentered }) {
  const [exampleServices] = useState(boltOnServicesArray.slice(0, 3));
  return (
    <section className='grid w-full h-full' aria-labelledby='bolt-ons-section'>
      <div className='grid gap-12 lg:gap-16 h-full w-full container mx-auto'>
        <div className='px-4'>
          <SubHeaderComponent
            isCentered={isCentered}
            title={'Additional Products and Services Available'}
            subtitle={'Bolt On Extras'}
          />
        </div>

        <section className='grid w-full h-full three_item_grid2 mx-auto mt-4'>
          {exampleServices?.map((service, index) => (
            <BoltOnCard
              key={index}
              title={service.title || 'Service card'}
              description={
                service.description ||
                `Service offered by ${CompanyName} for your project to improve productivity.`
              }
              image={service.icon}
              id={service.id}
            />
          ))}
        </section>

        <section className='grid px-4 mt-6' aria-labelledby='bolt-ons-description'>
          <h2 id='bolt-ons-description' className='sr-only'>
            Explore Additional Products and Services
          </h2>
          <div className='grid gap-4 lg:gap-12 lg:grid-cols-reg px-6 py-8 lg:px-12 md:max-w-[66vw] xl:max-w-[55vw] md:mx-auto rounded-xl border-2 border-colour5 border-solid neo_two'>
            {/* Icon Section */}
            <section
              className='grid justify-center lg:items-center'
              aria-hidden='true'
            >
              <img
                src={BoltOnSVG}
                alt='Nuts and bolts icon representing additional services'
                className='w-full max-w-32 lg:max-w-48'
              />
            </section>
            {/* Content Section */}
            <section className='grid w-full h-full sm:px-10 sm:py-4 lg:px-0'>
              <article className='grid gap-8'>
                <div className='grid gap-4 px-2'>
                  <h4
                    className='text-colour3 font-medium text-lg'
                    id='bolt-ons-header'
                  >
                    Select one or more extra services for your project
                  </h4>
                  <p>
                    Choose from over 30 optional extras for an additional
                    monthly price or combine multiple options in a custom
                    package. Contact us for more information.
                  </p>
                </div>
                {/* Links Section */}
                <section className='grid gap-4'>
                  <a
                    href={`${INFO_PAGE_URL}?type=features`}
                    onClick={() => {
                      window.scroll(0, 0);
                    }}
                    className='grid py-4 px-8 text-2xl font-semibold border-2 border-solid border-colour5 bg-colour1 hover:brightness-110 rounded-2xl text-center text-colour5'
                    aria-label='View the full list of bolt-ons available'
                  >
                    View Bolt Ons List
                  </a>
                  <a
                    href={CONTACT_PAGE_URL}
                    onClick={() => {
                      window.scroll(0, 0);
                    }}
                    className='text-center py-2 px-1 bg-colour5 text-colour1 rounded-lg active:brightness-110 ease-in hover:brightness-110 shadow-cardShadow'
                    aria-label='Contact us now to discuss bolt-on services'
                  >
                    <span>Contact Us Now</span>
                  </a>
                </section>
              </article>
            </section>
          </div>
        </section>
      </div>
    </section>
  );
}

function BoltOnCard({ title, description, image, id }) {
  return (
    <div className='grid justify-center three_item2'>
      <article class='relative flex w-80 flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-md'>
        <div class='relative mx-4 -mt-6 h-40 overflow-hidden rounded-xl bg-blue-gray-500 bg-clip-border text-white shadow-lg shadow-blue-gray-500/40 bg-gradient-to-r from-colour5 to-colour6'><div className='grid h-full text-8xl items-center justify-center'>{image}</div></div>
        <div class='p-6'>
          <h5 class='mb-2 text-colour3 block text-xl font-semibold leading-snug tracking-normal antialiased'>
            {title}
          </h5>
          <p class='block text-base font-light leading-relaxed text-inherit antialiased'>
            {description}
          </p>
        </div>
        <div class='p-6 pt-0'>
          <a
          href={`${INFO_PAGE_URL}?type=features#${id}`}
            data-ripple-light='true'
            class='select-none rounded-lg bg-colour5 py-3 px-6 text-center align-middle text-xs font-bold uppercase text-white shadow-md shadow-colour5/20 transition-all hover:shadow-lg hover:shadow-colour5/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'
          >
            Learn More
          </a>
        </div>
      </article>
    </div>
  );
}
export default BoltOnsCta;
