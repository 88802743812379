import React from 'react';
// Analytics
import { usePageTracking } from '../../hooks/useAnalytics';
// Constants
import { CompanyName } from '../../utils/Constants';
// Components
import Navbar from '../../components/nav/Navbar';
import { HelmetItem } from '../../components/utils/HelmetItem';
import FooterComponent from '../../components/footer/FooterComponent';
import ServicesComponent from '../../components/home/ServicesComponent';
import FeaturedProjectsComponent from '../../components/home/FeaturedProjectsComponent';
import MeetTheTeamComponent from '../../components/home/MeetTheTeamComponent';
import CustomerReviewsComponent from '../../components/reviews/CustomerReviewsComponent';
import PortfolioAndContactCta from '../../components/cta/PortfolioAndContactCta';
import HomePageHeader from '../../components/headers/home/HomePageHeader';
import BuildYourBusniessComponent from '../../components/home/BuildYourBusniessComponent';
import LatestBlogPostsComponent from '../../components/blog/LatestBlogPostsComponent';
import FaqComponent from '../../components/faq/FaqComponent';
// Data
import {
  homePageAdditionalMeta,
  homePageStructuredData,
} from '../../utils/data/MetaData';

const HomePage = React.memo(() => {
  usePageTracking(); // Tracks page views

  return (
    <>
      {/* Tab Data */}
      <HelmetItem
        PageName='Home'
        desc={`${CompanyName} offers expert web and circuit design solutions in England. Discover our services and featured projects.`}
        keywords={`web design, circuit design, ${CompanyName}, England, UK, custom solutions`}
        additionalMeta={homePageAdditionalMeta}
        structuredData={homePageStructuredData}
      />

      {/* Page start */}
      <div className='grid min-h-screen font-poppins overflow-x-hidden overflow-y-auto'>
        <div className='grid gap-28 h-full w-full'>
          {/* Header + Navigation */}
          <section className='grid lg:h-screen lg:max-h-screen min-h-screen overflow-x-hidden'>
            <div className='grid lg:grid-rows-reg overflow-x-hidden'>
              <Navbar />
              <HomePageHeader />
            </div>
          </section>

          {/* Main page content */}
          <main role='main' className='grid gap-28'>
            <div>
              <ServicesComponent />
            </div>
            <div>
              <BuildYourBusniessComponent />
            </div>
            <div>
              <FeaturedProjectsComponent />
            </div>
            <div>
              <CustomerReviewsComponent isCentered={true} />
            </div>
            <div>
              <MeetTheTeamComponent isCentered={false} />
            </div>
            <div>
              <PortfolioAndContactCta isCentered={true} />
            </div>
            <div>
              <LatestBlogPostsComponent isCentered={false} />
            </div>
            <div>
              <FaqComponent isCentered={true} />
            </div>
          </main>

          {/* Footer */}
          <FooterComponent />
        </div>
      </div>
    </>
  );
});

export default HomePage;
