import React from 'react';
// Components
import SubHeaderComponent from '../headers/SubHeaderComponent';
// Images
import WorkerAtComputerImage from '../../assets/images/about/man-thinking-about-solution.jpg'
import WorkPeopleImage from '../../assets/images/about/female-male-workers-wearing-work-clothing.jpg'
import WorkerAtShopImage from '../../assets/images/about/portrait-female-shopkeeper-holding-jar-turkish-sweets-counter.jpg'

function WhoWeWorkWithComponent({ isCentered }) {
  return (
    <section className='grid w-full h-full'>
      <div className='grid container mx-auto w-4/5 grid-rows-reg gap-8'>
        <SubHeaderComponent
          isCentered={isCentered}
          title={'Offering expertise and friendly service.'}
          subtitle={'Who We Work With'}
        />

        <section className='grid'>
          <article className='grid gap-8 h-fit'>
            <div>
              <p>
                Who we work with at Byte Toast Studio. We partner with a diverse
                range of clients, from ambitious inventors and startups to small
                businesses looking to enhance their online presence. Whether
                you are an entrepreneur with a groundbreaking idea or a local
                business in need of a professional website, we are here to help.
              </p>
            </div>
            <section className='grid grid-cols-3 h-1/2 py-4'>
              <figure>
                <img src={WorkerAtComputerImage} alt='Worker thinking about a solution at the computer.' />
              </figure>
              <figure>
                <img src={WorkPeopleImage} alt='Hard working trades people.' />
              </figure>
              <figure>
                <img src={WorkerAtShopImage} alt='Worker at sweet shop serving turkish delight.' />
              </figure>
            </section>
            <div>
              <p>
                We specialize in providing tailored web services, including
                design, development, and hosting, ensuring your digital
                platforms run smoothly and effectively. Our goal is to make
                cutting-edge technology accessible to everyone, regardless of
                technical know-how. If you’ve got the vision, we’ve got the
                expertise to make it happen.
              </p>
            </div>
          </article>
        </section>
      </div>
    </section>
  );
}

export default WhoWeWorkWithComponent;
