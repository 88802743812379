import React, { useRef, useState } from 'react';
// Constants
import { CompanyName } from '../../utils/Constants';
// Components
import Navbar from '../../components/nav/Navbar';
import { HelmetItem } from '../../components/utils/HelmetItem';
import FooterComponent from '../../components/footer/FooterComponent';
import ProjectPageHeader from '../../components/headers/project/ProjectPageHeader';
import ProjectDesignInformationComponent from '../../components/projects/ProjectDesignInformationComponent';
// Data
import {
  projectPageAdditionalMeta,
  projectPageStructuredData,
} from '../../utils/data/MetaData';
// Styles
import '../../styles/project.css'

function ProjectPage() {
  const sectionRef = useRef(null);
  const textRef = useRef(null);

  const [salesOptionSelected, setSalesOptionSelected] = useState({});

  const selectAppType = (appType) => {
    setSalesOptionSelected(appType);

    setTimeout(() => {
      animateChange();
    }, 1900);
  };

  const animateChange = () => {
    const sectionElement = sectionRef.current;
    const textElement = textRef.current;

    sectionElement.classList.remove('hidden-container');
    textElement.classList.add('lg:hidden-container');
    sectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const changeDisplayedProduct = (option) => {
    setSalesOptionSelected(option);
  };

  return (
    <>
      {/* Tab Data */}
      <HelmetItem
        PageName='Project'
        desc={`Explore detailed projects and solutions provided by ${CompanyName}, showcasing our expertise in web and circuit design.`}
        keywords={`projects, case studies, web design, circuit design, ${CompanyName}, innovative solutions`}
        additionalMeta={projectPageAdditionalMeta}
        structuredData={projectPageStructuredData}
      />

      {/* Page start */}
      <div className='grid min-h-screen bg-main-background font-poppins overflow-x-hidden overflow-y-auto'>
        <div className='grid h-full w-full'>
          {/* Header + Navigation */}
          <section className='grid border-b-2 border-solid border-black lg:h-screen lg:max-h-screen min-h-screen overflow-x-hidden'>
            <div className='grid grid-rows-reg overflow-x-hidden'>
              <Navbar />
              <ProjectPageHeader
                selectAppType={selectAppType}
                thisRef={textRef}
              />
            </div>
          </section>

          {/* Main page content */}
          <main role='main' className='grid h-full w-full mt-12'>
            <div ref={sectionRef} className='hidden-container mb-12'>
              <ProjectDesignInformationComponent
                type={salesOptionSelected}
                changeDisplayedProduct={changeDisplayedProduct}
              />
            </div>
          </main>

          {/* Footer */}
          <FooterComponent />
        </div>
      </div>
    </>
  );
}

export default ProjectPage;
