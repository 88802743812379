import { BsFillPatchCheckFill } from 'react-icons/bs';
// Images portfolio component
import FullWeb1 from '../../assets/images/projects/fullpage-modern-website-display-personal-site.png';
import FullWeb2 from '../../assets/images/projects/myecoapp-fullpage-demo-website-modern-design.png';
import FullWeb3 from '../../assets/images/projects/blog-website-demo-image-modern-design.png';
// Staff images
import DanProfileImage from '../../assets/images/staff/dan-employee-byte-toast-studio.png';
import TomProfileImage from '../../assets/images/staff/tom-employee-byte-toast-studio.jpg';
import JoshProfileImage from '../../assets/images/staff/josh-employee-byte-toast-studio.png';
// Icons
// import DesktopIcon from '../../assets/images/icons/desktop_software_project_option_icon.svg';
import { IoGameControllerOutline } from 'react-icons/io5';
import { ReactComponent as DesktopIcon } from '../../assets/images/icons/desktop_software_project_option_icon.svg';
import { MdOutlineSensors } from 'react-icons/md';
import { FiServer, FiSmartphone } from 'react-icons/fi';
import { CiWifiOn } from 'react-icons/ci';
import { FaBattleNet } from 'react-icons/fa';
import { GrTechnology, GrTask } from 'react-icons/gr';
import { TbStack3 } from 'react-icons/tb';
import { RiPagesLine } from 'react-icons/ri';
import { PiCircuitryLight } from 'react-icons/pi';
import { MdOutlineDesktopWindows } from 'react-icons/md';
import { BsDatabase } from 'react-icons/bs';
import { FaCode, FaRegHeart } from 'react-icons/fa6';
import { BsBoxSeam } from 'react-icons/bs';
import { LiaUserFriendsSolid } from 'react-icons/lia';
import { LuFolderCog } from 'react-icons/lu';
import {
  MdOutlineWeb,
  MdOutlineComputer,
  MdOutlineDevicesOther,
  MdOutlineHome,
  MdOutlineSmartToy,
} from 'react-icons/md';
// Icons
import ComputerIcon from '../../assets/images/icons/computer-app-design-option-icon.svg';
import MobileIcon from '../../assets/images/icons/mobile-app-design-option-icon.svg';
import WebsiteIcon from '../../assets/images/icons/website-design-option-icon.svg';
import CircuitBoardIcon from '../../assets/images/icons/circuit-board-design-option-icon.svg';
// Main services
export const MainServicesArray = [
  {
    label: 'Websites and Apps',
    icon: <MdOutlineWeb />,
    subHeader: 'Custom-Built for Your Needs',
    content: (
      <>
        Website and Server development is <span className='italic'>our</span>{' '}
        speciality. We create interactive, engaging, and responsive web pages or
        apps tailored to your business goals. Our focus is on delivering
        exceptional user experiences to help attract and retain your audience.
      </>
    ),
  },
  {
    label: 'Desktop PC Software',
    icon: <MdOutlineComputer />,
    subHeader: 'Powerful Tools for Efficiency',
    content: (
      <>
        We design and develop bespoke desktop software to streamline workflows
        and enhance productivity. Whether you need management systems, data
        processing tools, or specialized applications,{' '}
        <span className='italic'>we</span> can build solutions to meet your
        unique requirements.
      </>
    ),
  },
  {
    label: 'Circuits, IoT & Automation',
    icon: <MdOutlineDevicesOther />,
    subHeader: 'Innovative Hardware Solutions',
    content: (
      <>
        From custom circuit design to IoT device integration,{' '}
        <span className='italic'>we</span> bring your ideas to life. Automate
        processes, connect devices, and harness the power of modern technology
        to make your systems smarter and more efficient.
      </>
    ),
  },
  {
    label: 'Smart Home & Wearables',
    icon: <MdOutlineHome />,
    subHeader: 'Enhance Your Lifestyle',
    content: (
      <>
        Upgrade your home or personal tech with smart solutions.{' '}
        <span className='italic'>We</span> create tailored smart home systems
        and wearable tech that seamlessly integrate into your daily life,
        improving convenience, comfort, and connectivity.
      </>
    ),
  },
  {
    label: 'AI and Automation',
    icon: <MdOutlineSmartToy />,
    subHeader: 'Intelligent Solutions for Growth',
    content: (
      <>
        Leverage the power of AI to automate tasks, gain insights, and drive
        innovation. <span className='italic'>We</span> specialize in creating
        intelligent systems to optimize your business processes, improve
        decision-making, and deliver a competitive edge.
      </>
    ),
  },
];

// Long service list
export const ProjectTypesArray = [
  {
    label: 'Website development',
    icon: <RiPagesLine />,
    subTitle: '',
    content: (
      <>
        Information on the project type, how long they usually take, what you
        can do with one, basic design options Information on the project type,
        how long they usually take, what you can do with one, basic design
        options
      </>
    ),
  },
  {
    label: 'Mobile Apps',
    icon: <FiSmartphone />,
    subTitle: '',
    content: (
      <>
        Information on the project type, how long they usually take, what you
        can do with one, basic design options Information on the project type,
        how long they usually take, what you can do with one, basic design
        options
      </>
    ),
  },
  {
    label: 'Desktop Widgets',
    icon: <MdOutlineDesktopWindows />,
    subTitle: '',
    content: (
      <>
        Information on the project type, how long they usually take, what you
        can do with one, basic design options Information on the project type,
        how long they usually take, what you can do with one, basic design
        options
      </>
    ),
  },
  {
    label: 'Servers and Hosting',
    icon: <FiServer />,
    subTitle: '',
    content: (
      <>
        Information on the project type, how long they usually take, what you
        can do with one, basic design options Information on the project type,
        how long they usually take, what you can do with one, basic design
        options
      </>
    ),
  },
  {
    label: 'SEO and Lead Generation',
    icon: <DesktopIcon />,
    subTitle: '',
    content: (
      <>
        Information on the project type, how long they usually take, what you
        can do with one, basic design options Information on the project type,
        how long they usually take, what you can do with one, basic design
        options
      </>
    ),
  },
  {
    label: 'Circuit Design',
    icon: <PiCircuitryLight />,
    subTitle: '',
    content: (
      <>
        Information on the project type, how long they usually take, what you
        can do with one, basic design options Information on the project type,
        how long they usually take, what you can do with one, basic design
        options
      </>
    ),
  },
  {
    label: 'Sensors and Automation',
    icon: <MdOutlineSensors />,
    subTitle: '',
    content: (
      <>
        Information on the project type, how long they usually take, what you
        can do with one, basic design options Information on the project type,
        how long they usually take, what you can do with one, basic design
        options
      </>
    ),
  },
  {
    label: 'AI Assistants',
    icon: <FaBattleNet />,
    subTitle: '',
    content: (
      <>
        Information on the project type, how long they usually take, what you
        can do with one, basic design options Information on the project type,
        how long they usually take, what you can do with one, basic design
        options
      </>
    ),
  },
  {
    label: 'IoT intergation',
    icon: <GrTechnology />,
    subTitle: '',
    content: (
      <>
        Information on the project type, how long they usually take, what you
        can do with one, basic design options Information on the project type,
        how long they usually take, what you can do with one, basic design
        options
      </>
    ),
  },
  {
    label: 'Wifi and Bluetooth',
    icon: <CiWifiOn />,
    subTitle: '',
    content: (
      <>
        Information on the project type, how long they usually take, what you
        can do with one, basic design options Information on the project type,
        how long they usually take, what you can do with one, basic design
        options
      </>
    ),
  },
  {
    label: 'Database',
    icon: <BsDatabase />,
    subTitle: '',
    content: (
      <>
        Information on the project type, how long they usually take, what you
        can do with one, basic design options Information on the project type,
        how long they usually take, what you can do with one, basic design
        options
      </>
    ),
  },
  {
    label: 'Games',
    icon: <IoGameControllerOutline />,
    subTitle: '',
    content: (
      <>
        Information on the project type, how long they usually take, what you
        can do with one, basic design options Information on the project type,
        how long they usually take, what you can do with one, basic design
        options
      </>
    ),
  },
];

// Dont need
export const SkillsListArray = [
  {
    label: 'Linux',
  },
  {
    label: 'Javascript',
  },
  {
    label: 'NodeJS',
  },
  {
    label: 'C++',
  },
  {
    label: 'GoLang',
  },
  {
    label: 'Ruby',
  },
  {
    label: 'C#',
  },
  {
    label: 'Python',
  },
  {
    label: 'Dart',
  },
  {
    label: 'Flutter',
  },
  {
    label: 'React Native',
  },
  {
    label: 'Blazor',
  },
  {
    label: 'Figma',
  },
  {
    label: 'Blender',
  },
  {
    label: 'Neural Networks',
  },
  {
    label: 'Raspberry Pi',
  },
  {
    label: 'Arduino',
  },
  {
    label: '3D Printing',
  },
  {
    label: 'Mechanical Engineering',
  },
  {
    label: 'Electrical Engineering',
  },
  {
    label: 'Nginx',
  },
];

// Steps of a project
export const ProjectCompletionStepsArray = [
  {
    label: 'Customer Development',
    icon: <LiaUserFriendsSolid />,
    content:
      'Learning from you and your team about exactly what you need and how technology can be used to increase your profits, productivity, user experience and more. Streamlining and beautifying your web or technical needs.',
  },
  {
    label: 'Project Planning',
    icon: <GrTask />,
    content:
      'Creating a sample for you to approve before we go ahead and build. Deciding what technical needs you have and how we can best serve them. As engineers we understand the principles or practicality, simplicity and cost.',
  },
  {
    label: 'Tech Stack Selection',
    icon: <TbStack3 />,
    content:
      'We do not know what will suit your needs best until we test the huge amount of options their are available. We create test code or virtual ciruits that can lead us to selecting the best and most affordable options for you project.',
  },
  {
    label: 'Product Execution',
    icon: <FaCode />,
    content:
      'Build stage. Creating your project from scratch and customizing it exactly to suit your needs. You will be kept informed as we progress.',
  },
  {
    label: 'Testing and Proving',
    icon: <LuFolderCog />,
    content:
      'The final testing stage to confirm what we build is working. We provide long term free testing of most products by collecting running data and checking for bugs. ',
  },
  {
    label: 'Customer Review',
    icon: <FaRegHeart />,
    content:
      'Your chance to review what we have created. Test it, run it as a user and see if it works like you expected. We can make changes constantly and update it at request.',
  },
  {
    label: 'Delivery Of Product',
    icon: <BsBoxSeam />,
    content:
      'Final delivery of product is where we have both agreed that it suits our standards and can deliver a completed project, file, circuit or anything you had us build.',
  },
];

// Staff data
export const StaffListArray = [
  {
    name: 'Tom',
    position: 'Software and Electrical Engineer',
    bio: 'The leader of the team with 15 years of experience in engineering and software development. I love working with my hands and creating weird and awesome products and gadgets.',
    imageSource: TomProfileImage,
  },
  {
    name: 'Josh',
    position: 'Web Developer',
    bio: 'A master builder who retrained as a web developer. Has the eye for detail needed to create works of art. CSS and styling anything you can imagine, never been seen without a large coffee.',
    imageSource: JoshProfileImage,
  },
  {
    name: 'Dan',
    position: 'Multitool',
    bio: 'Dan is our everything. She keeps us working and accounts organised. Training as a web developer and knows everything about the lastest styles and trends. Repeatedly tells us who that famous person is.',
    imageSource: DanProfileImage,
  },
];

// Skill Data
export const frontendSkills = [
  { name: 'Go', icon: <BsFillPatchCheckFill />, level: 'Experienced' },
  { name: 'CSS3', icon: <BsFillPatchCheckFill />, level: 'Experienced' },
  { name: 'JavaScript', icon: <BsFillPatchCheckFill />, level: 'Experienced' },
  { name: 'TypeScript', icon: <BsFillPatchCheckFill />, level: 'Experienced' },
  { name: 'React JS', icon: <BsFillPatchCheckFill />, level: 'Experienced' },
  { name: 'Next JS', icon: <BsFillPatchCheckFill />, level: 'Experienced' },
  { name: 'Python', icon: <BsFillPatchCheckFill />, level: 'Experienced' },
  {
    name: 'Wordpress/Wix',
    icon: <BsFillPatchCheckFill />,
    level: 'Experienced',
  },
  { name: 'C#', icon: <BsFillPatchCheckFill />, level: 'Experienced' },
  { name: 'C++', icon: <BsFillPatchCheckFill />, level: 'Experienced' },
];

export const backendSkills = [
  { name: 'Express', icon: <BsFillPatchCheckFill />, level: 'Experienced' },
  { name: 'Node.js', icon: <BsFillPatchCheckFill />, level: 'Experienced' },
  { name: 'MySQL', icon: <BsFillPatchCheckFill />, level: 'Experienced' },
  { name: 'PostgreSQL', icon: <BsFillPatchCheckFill />, level: 'Experienced' },
  { name: 'Prisma', icon: <BsFillPatchCheckFill />, level: 'Experienced' },
  { name: 'MongoDB', icon: <BsFillPatchCheckFill />, level: 'Experienced' },
  { name: 'Linux', icon: <BsFillPatchCheckFill />, level: 'Experienced' },
  { name: 'AWS', icon: <BsFillPatchCheckFill />, level: 'Experienced' },
  { name: `VM's`, icon: <BsFillPatchCheckFill />, level: 'Experienced' },
];

let webImageId = 0;
export const portfolioWebImagesArray = [
  {
    id: webImageId++,
    image: FullWeb1,
    alt: 'Web and app display',
  },
  {
    id: webImageId++,
    image: FullWeb2,
    alt: 'Web and app display',
  },
  {
    id: webImageId++,
    image: FullWeb3,
    alt: 'Web and app display',
  },
];

// Not ure
export const minorServicesDataArray = [
  {
    title: 'UI/UX Design',
    borderColor: '#1f2937',
    items: [
      'Wireframe and figma designs using the latest design tools',
      'All devices conformed to style',
      'Faster streamlined services',
      'User friendly layouts',
      'Testing and collecting user experience data',
      'Colour and style options. Hue matching and pallet building tools.',
      'Root set styles for speedy changes througout project.',
    ],
  },
  {
    title: 'Software/App Development',
    borderColor: '#ecc94b',
    items: [
      'Fullstack design and development',
      'Database and user services.',
      'Game and animation Development.',
      'Reactive and mobile friendly or mobile first design',
      'Social medias supporting post/sharing dynamic',
      'Video, webcam and chat communication services',
      'Smart home and wireless connectivity',
      'Restful API services',
    ],
  },
  {
    title: 'Other Skills',
    borderColor: '#38a169',
    items: [
      'Arduino/Pi development',
      'Circuit design and development',
      'Customer relations',
      'CI/CD development',
      'Documentation and auditing',
      'Mechanical maintainance',
      '3D printer models',
    ],
  },
];

// Project creation information
export const salesAppTypesArray = [
  {
    label: 'Web',
    articleTitle: 'Website Development for Sales and Exposure.',
    linkSlug: '/web',
    name: 'web',
    image: WebsiteIcon,
    content: {
      para1:
        'Websites are an online business card, as well as a portal to all the information a customer could need about your business. People have got used to the internet being fast and websites need to keep up with user expectations. Most people are only willing to wait 3 seconds for a page to load and if they cant find the infomation or product immediately they will leave. Consider your need and goals before jumping in. The more information you can provide to a developer will help to make sure customers are finding what they came to your site for. Sites are modelled around you and your business, a developer can only recommend the best practices and layout for a project by knowing as much as psossible.',
      para2:
        "Modern sites are dialed back compared to the 90's, made with simple and smooth animations and leveraging psycological studies to understand how a viewers mind works when viewing a site. This includes know the best place to put text or headers. Provding small snippets of information in an arrangement that is visually easy to read, flows with the page and only convays the most important information as readers get bored and click away if they were not expecting a long article. With the latest web technologies, we build sites that are secure, scalable, and easy to manage. Our comprehensive approach includes SEO optimization, ensuring your site ranks well on search engines and attracts the right audience.",
    },
    services: [
      'High SEO score',
      'User friendly layouts',
      'Reactive and mobile friendly or mobile first design',
      'Wireframe and Figma designs',
      'Testing and collecting user experience data',
      'Colour and style options. Hue matching and palette building tools',
      'Fullstack design and development',
      'Social media supporting post/sharing dynamic',
      'Database and user services',
      'Game and animation Development',
      'Video, webcam and chat communication services',
      'Smart home and wireless connectivity',
      'Restful API services',
      'Hosting from £3.99 pm',
      '3 Months free testing',
      'SSL Certificates and security as standard',
      'Admin areas',
      'Continous software updates',
    ],
    designCriteria: [
      {
        title: 'Main Selling Point',
        question: 'What is the key message or feature you want to highlight?',
      },
      {
        title: 'Design Preferences',
        question:
          'Do you have a preferred color scheme or style? (Modern, minimalistic, bold, etc.)',
      },
      {
        title: 'Content',
        question:
          'Do you have existing content, or do you need help creating text, images, or videos?',
      },
      {
        title: 'Functionality',
        question:
          'What specific features are required? (E.g., e-commerce, contact forms, user logins)',
      },
      {
        title: 'Competitor Inspiration',
        question:
          'Are there websites you admire or would like us to reference?',
      },
    ],
  },
  {
    label: 'App',
    articleTitle: 'App Creation for Monitization.',
    linkSlug: '/app',
    name: 'app',
    image: MobileIcon,
    content: {
      para1:
        'Mobile apps are fantasic choices for always available software that uses can keep in their pocket. They all customers and businesses to track, update, check in, purchase practically anything they want, without need complex set ups or external power sources. Your employees can update your servers from a field in the middle of nowhere in an instant. Your customers can come straight to you, without going through search engines and seeing competition that might be paying more for adverts that day. Modern apps have every feature of a website of PC software. With superior connectivity in some cases with bluetooth and wifi as standard allowing you to connect to any machine from a mobile device.',
      para2:
        'Cutting edge mobile apps are getting faster an easier to produce. You can finally use one code on both Apple and Android devices. No longer do you need to write the same app prices. As well as hosting and production fees paid to Apple and Google Play having come down greatly. Apps are more single focus than a website and usually are design to complete tasks for your business specifically. A great example of an app is a custom tool for creating custom quotes, connecting to secure databases, or allowing admins to update or moderate from anywhere they have a phone.',
    },
    services: [
      'iOS and Android compatible',
      'User friendly layouts',
      'Wireframe and Figma designs',
      'Testing and collecting user experience data',
      'Database and user services',
      'Games and animation',
      'Social media',
      'AI Intergration with ChatGPT',
      'Smart home and wireless connectivity',
      'Accounting, quoting or ordering software',
      'Stripe, Paypal or Visa payments',
      'Admin area',
    ],
    designCriteria: [
      {
        title: 'Target Platforms',
        question: 'Should the app be designed for iOS, Android, or both?',
      },
      {
        title: 'User Features',
        question:
          'What functionalities do you need? (E.g., push notifications, GPS tracking, payment integration)',
      },
      {
        title: 'Connectivity',
        question:
          'Should the app integrate with other devices or systems (e.g., Bluetooth, smart home systems)?',
      },
      {
        title: 'Monetization',
        question:
          'Will the app include in-app purchases or subscription options?',
      },
      {
        title: 'User Access',
        question:
          'Will there be user roles (e.g., admin, regular user) and what permissions will they have?',
      },
    ],
  },
  {
    label: 'Software',
    articleTitle: 'Desktop Software and Custom Programs.',
    linkSlug: '/software',
    name: 'software',
    image: ComputerIcon,
    content: {
      para1:
        'Custom PC applications can have thousands of services plugged into it inclduing AI, Web servers, Internet of Things. You can create anything for a PC to assist your business or your customers. Software can be used to connect to external machines connected via the internet so you can interact when needed from any location. Creating software that automates factory or business processes can be a key to saving time and money by simplifying repetative tasks. You can connect and talk to different devices, see their condition, access sensor infomation as well as having all the data represented as graphs and charts that are easy to understand.',
      para2:
        'Our team of skilled developers combines extensive experience with the latest industry standards to deliver software that is not only reliable but also easy to use and maintain. We focus on creating intuitive interfaces and ensuring seamless integration with your existing systems. Creating the perfect software for your needs requires our team to interact with you and your business, gaining insight to provide all the features you need to streamline and improve your tasks.',
    },
    services: [
      'Wireframe and Figma',
      'C#, Golang, Python',
      'Windows PC applications',
      'User friendly layouts',
      'SQL/PostgreSQL Databases',
      'Connect to machines',
      'Social media supporting post/sharing dynamic',
      'Smart home and wireless connectivity',
      'Control devices and circuitry',
      'Output to databases',
    ],
    designCriteria: [
      {
        title: 'Core Functionality',
        question:
          'What is the primary task or problem this software should address?',
      },
      {
        title: 'Integration',
        question:
          'Does the software need to connect with existing tools, APIs, or systems?',
      },
      {
        title: 'Platform',
        question: 'Is this for Windows, Mac, Linux, or cross-platform?',
      },
      {
        title: 'User Roles',
        question:
          'Are there multiple user types? If so, what are their permissions or functionalities?',
      },
      {
        title: 'Security',
        question: 'Do you have specific security or compliance requirements?',
      },
    ],
  },
  {
    label: 'Tech',
    articleTitle: 'Circuit Design and Intergation.',
    linkSlug: '/tech',
    name: 'tech',
    image: CircuitBoardIcon,
    content: {
      para1:
        "Explore the future of technology with our advanced circuit board, Arduino, and Pi solutions that communicate seamlessly with apps. Perfect for IoT projects, automation systems, and innovative tech solutions, our hardware expertise ensures reliable and efficient performance. Whether you're developing a prototype or a full-scale product, we provide the tools and support to bring your ideas to life.",
      para2:
        "Our team excels in integrating hardware with software, creating cohesive systems that are easy to manage and expand. With our focus on cutting-edge technology and user-centric design, we help you build powerful solutions that are both practical and innovative. Trust us to deliver hardware solutions that enhance your project's capabilities and open up new possibilities.",
    },
    services: [
      '3D printer models',
      'Custom circuit boards',
      'Bluetooth and Wifi connectivity',
      'Smart home intergration',
      'CE certified products',
      'Arduino/Pi development',
      'Circuit board can communicate via wifi, sim card or bluetooth',
      'Circuit design and development',
      'Documentation and auditing',
      'Fully customized components',
    ],
    designCriteria: [
      {
        title: 'Usage',
        question:
          'What is the intended use case for this hardware? (E.g., IoT automation, prototyping, etc.)',
      },
      {
        title: 'Connectivity',
        question: 'Should it include Bluetooth, Wi-Fi, or cellular options?',
      },
      {
        title: 'Customization',
        question:
          'Do you have preferences for the hardware’s size, shape, or color?',
      },
      {
        title: 'Power Requirements',
        question:
          'Does the hardware need to be battery-operated, plug-in, or both?',
      },
      {
        title: 'Integration',
        question:
          'Will this hardware need to communicate with an app or external system?',
      },
    ],
  },
];

export const generalProjectQuestionsArray = [
  {
    title: 'Project Goals',
    question:
      'What is the primary purpose of this project? (e.g., business promotion, user engagement, automation, etc.)',
  },
  {
    title: 'Target Audience',
    question:
      'Who are you trying to reach with this product? (e.g., age group, interests, geographic location)',
  },
  { title: 'Budget', question: 'Do you have a budget range for this project?' },
  { title: 'Timeline', question: 'When do you need the project completed?' },
];
