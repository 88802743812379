import React from 'react';
// Constants
import { ARDUPLOT_URL } from '../../utils/Constants';

function ArduplotShowcaseSummaryComponent() {
  return (
    <section className='grid h-full w-full xl:w-1/2 xl:mx-auto'>
      <div className='py-2'>
        <h6 className='text-xl'>Thanks for reading!</h6>
      </div>
      <section className='grid gap-4'>
        <div>
          Remember you can download any code, models or databases you want.
          Use the links below to download or visit the live site now by{' '}
          <a
            href={ARDUPLOT_URL}
            target='_blank'
            rel='noreferrer'
            className='text-colour5 hover:brightness-75 font-medium italic'
          >
            Clicking Here!
          </a>
        </div>
        <div>
          <p>
            If you build your own robot,{' '}
            <span className='font-medium'>feel free</span> to use the website
            and database to store your paths. Just be sure to share any good
            ones in the library for everyones benifit!
          </p>
        </div>
      </section>
    </section>
  );
}

export default ArduplotShowcaseSummaryComponent;
