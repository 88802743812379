import React from 'react';
// Images
import ServerImage from '../../assets/images/tech/server_tower.png';
import LenovoImage from '../../assets/images/tech/x3650_xeon_m5_server_rack.png';
// Icons
import { BsCheck2Circle } from 'react-icons/bs';

function ServerDbShowcaseComponent() {
  return (
    <section className='grid h-full w-full'>
      <div className='grid gap-6 w-full h-full'>
        <section className='grid gap-6 h-fit px-4'>
          {/* Header */}
          <div className='grid text-center'>
            <div>
              <h4 className='text-xl font-semibold'>
                Stage <span className='text-colour5'>02</span>
              </h4>
            </div>
            <div>
              <h5 className='text-gray-600 text-2xl'>Backend Server and Database</h5>
            </div>
            <div className='border-b-2 border-solid border-colour5 pt-2'></div>
          </div>

          {/* Content */}
          <div className='grid mt-4 md:mt-6 h-min px-2'>
            <div className='grid w-full h-fit'>
              <img
                src={ServerImage}
                alt='Server and database'
                className='mx-auto max-h-[280px] w-auto'
              />
            </div>
          </div>

          <article className='grid gap-6'>
            <div className='text-center'>
              <h5 className='text-xl'>Building the <span className='text-colour5'>Connectivity</span></h5>
            </div>
            <div className='grid gap-4 px-4 xl:px-12'>
              <div>
                <p>
                  <span className='font-semibold'>The server</span> we created to handle a huge number of features. We
                  picked Node JS because it allows us to use the same code
                  throughout the project. Using a reverse proxy encrypted
                  traffic to connect the front and back ends on the same machine
                  to ensure fast connections.
                </p>
              </div>
              <div>
                <p>
                  A PostgreSQL database for user services and the saved profiles
                  for the robot to read directly from the server. Running
                  monitoring programs peroidically for the robot as well as
                  backing up and creating redudencies for all data.
                </p>
              </div>
              <div>
                <p>
                  Hosted on a <span className='font-medium'>X3650 M5 Xeon</span> for an overkill of a dedicated
                  server we are able to handle more traffic and processing power
                  than will ever required. With RAID 1 Backups and 256GB of RAM.
                </p>
              </div>
            </div>
          </article>
        </section>

        {/* Text */}
        <section className='grid pt-8 h-full w-full'>
          <article className='grid gap-4 lg:gap-6 h-full w-full px-4'>
            <div className='grid font-medium'>
              <div className='lg:text-center'>
                <h6 className='text-xl'>
                  Its features <span className='text-colour5'>include:</span>
                </h6>
              </div>
              <div>
                <ul className='grid gap-2 justify-center py-10'>
                  <li className='grid justify-center'>
                    <div className='flex gap-2'>
                      <BsCheck2Circle size={30} className='text-colour5' />
                      <span>User database</span>
                    </div>
                  </li>
                  <li className='grid justify-center'>
                    <div className='flex gap-2'>
                      <BsCheck2Circle size={30} className='text-colour5' />
                      <span>Save plot maps and loops</span>
                    </div>
                  </li>
                  <li className='grid justify-center text-center'>
                    <div className='flex gap-2'>
                      <BsCheck2Circle size={30} className='text-colour5' />
                      <span>Send files to robot</span>
                    </div>
                  </li>
                  <li className='grid justify-center'>
                    <div className='flex gap-2'>
                      <BsCheck2Circle size={30} className='text-colour5' />
                      <span>Camera view support</span>
                    </div>
                  </li>
                  <li className='grid justify-center'>
                    <div className='flex gap-2'>
                      <BsCheck2Circle size={30} className='text-colour5' />
                      <span>Wake up the robot</span>
                    </div>
                  </li>
                  <li className='grid justify-center'>
                    <div className='flex gap-2'>
                      <BsCheck2Circle size={30} className='text-colour5' />
                      <span>Monitor Performance</span>
                    </div>
                  </li>
                  <li className='grid justify-center'>
                    <div className='flex gap-2'>
                      <BsCheck2Circle size={30} className='text-colour5' />
                      <span>Send alerts to user</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            {/* Info */}
            <section className='grid xl:grid-cols-2 xl:mt-10 xl:gap-4'>
              <div className='grid justify-center py-12 xl:items-center xl:order-2'>
                <img src={LenovoImage} alt='Lenovo X3650 M5 server' />
                <div className='text-end'>
                  <p className='italic text-sm mt-1 font-medium'>
                    Lenovo X3650 M5
                  </p>
                </div>
              </div>
              {/* text */}
              <div className='grid gap-4 px-4 xl:order-1'>
                <div>
                  <p>
                    We host serveral large servers on premises and created a
                    connection between the server and the robot. Allowing us to
                    wake up the robot when needed to help save power. We can
                    create and send new instructions to the new robot in
                    seconds.{' '}
                  </p>
                </div>
                <div>
                  <p>
                    We can monitor the performance and have the server analyse
                    issues that arrise. The connection include a camera view we
                    can turn on and off, and you can view the progress from the
                    website. Helping to make real time changes to the program
                    without needing to be in the building.
                  </p>
                </div>
                {/* Buttons */}
                <div className='grid w-full pb-2 pt-6 px-4 xl:px-0'>
                  <button className='bg-colour5 text-colour1 w-full py-2 text-xl rounded-lg shadow-cardShadow active:scale-95 hover:brightness-75'>
                    View Code
                  </button>
                </div>
              </div>
            </section>
          </article>
        </section>
      </div>
    </section>
  );
}

export default ServerDbShowcaseComponent;
