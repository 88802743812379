import React from 'react';
// Components
import HeaderTitlesSection from './HeaderTitlesSection';
import HeaderDisplaySection from './HeaderDisplaySection';
// import HeaderBackground from './HeaderBackground';

function HomePageHeader() {
  return (
    <header className='grid bg-colour1 w-full h-full overflow-hidden'>
      <div className='grid px-6 z-10 h-full w-full grid-rows-2 lg:grid-cols-2 lg:grid-rows-1 overflow-hidden'>
        {/* First section */}
        <HeaderTitlesSection />
        {/* Second section */}
        <HeaderDisplaySection />
      </div>
    </header>
  );
}

export default HomePageHeader;
