import React from 'react';
// Data
import { MainServicesArray } from '../../utils/data/CompanyData';

function ProjectTypeSelect({ handleChange }) {
  return (
    <>
      <select
        id='projectType'
        name='projectType'
        onChange={handleChange}
        className={`input_shadow border min-h-8 text-sm rounded w-full h-full px-1 py-2 text-colour3 leading-tight focus:outline-none focus:shadow-outline`}
        required
        aria-label='Select your project type'
        defaultValue='Web'
      >
        <option value='none-selected'>None</option>
        {MainServicesArray.map((option) => {
          return (
            <option key={option.label} value={option.label}>
              {option.label}
            </option>
          );
        })}
        <option value='free-one-page-site'>Free one page</option>
        <option value='other'>Other</option>
      </select>
    </>
  );
}

export default ProjectTypeSelect;
