import React from 'react';
// Images
import TeamImage from '../../assets/images/staff/byte-toast-studio-staff-image.webp';
// Constants
import { CompanyName } from '../../utils/Constants';

function MissionStatementComponent() {
  return (
    <section className='grid w-full h-full'>
      <div className='grid gap-12 lg:grid-cols-2 w-4/5 mx-auto'>
        <section>
          <article>
            <section className='grid gap-10'>
              <section className='py-4 md:px-4 text-center'>
                <h3 className='text-gray-600'>The Mission</h3>
                <span className='text-colour3 font-semibold'>
                  Why we want to help
                </span>
                <div className='border-b-2 border-solid border-colour5 pt-2'></div>
              </section>

              <section>
                <p>
                  At Byte Toast Studio, our mission is to turn imagination into
                  reality. We thrive on bringing new, groundbreaking ideas to
                  life, making us the perfect partner for inventors and
                  visionaries who have bold concepts but need the technical
                  expertise to bring them to fruition. Whether it’s designing
                  intelligent robots, crafting custom circuits, or building
                  dynamic web platforms, we’re here to collaborate, innovate,
                  and make your vision a tangible success.
                </p>
              </section>
            </section>
          </article>
        </section>

        {/* Image section */}
        <section>
          <div>
            <img src={TeamImage} alt={`The ${CompanyName} staff`} />
          </div>
        </section>
      </div>
    </section>
  );
}

export default MissionStatementComponent;
