// Images
import WervingsLogo from '../../assets/images/reviews/wervings-syrups-logo-for-review.png';
import RVSLogo from '../../assets/images/reviews/rapid-van-solutions-logo-for-review.jpg';
import MusicLogo from '../../assets/images/reviews/turnbull-music-review-image.jpeg';
import MyeaLogo from '../../assets/images/reviews/myecoapp-logo-for-review.jpg';

let reviewId = 0;

export const reviewsDataArray = [
    {
        id: reviewId++,
        label: 'Wervings',
        desc: 'Tom has run our business ecommerce website for years now and has always done splendid work! Very easy to work with.',
        url: 'https://www.wervings.co.uk',
        imageSource: WervingsLogo
    },
    {
        id: reviewId++,
        label: 'Rapidvansolutions',
        desc: 'Thank you for designing and building our website. I understand nothing about the internet and Tom worked with that.',
        url: 'https://www.rapidvansolutions.com',
        imageSource: RVSLogo
    },
    {
        id: reviewId++,
        label: 'Stuart Turnbull',
        desc: 'A hard working man and a pleasure to work with. Said yes to everything i asked and didnt over complicate the job.',
        url: 'https://www.stuartturnbull.net',
        imageSource: MusicLogo
    },
    {
        id: reviewId++,
        label: 'Myecoapp.org',
        desc: 'Tom built a beautiful site for us and brings most of the ideas about improvements and modernisations.',
        url: 'https://www.myecoapp.org',
        imageSource: MyeaLogo
    }
]