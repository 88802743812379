import React from 'react';
// Images
import CatAppImage from '../../assets/images/examples/apps/cat-app-mobile-app-home-screen.png';
import ShoppingAppImage from '../../assets/images/examples/apps/e-commerce-shopping-app-home-page.png';
import SpaceInvadersAppImage from '../../assets/images/examples/apps/space-invaders-mobile-game.png';

function FeaturedProjectAppComponent() {
  return (
    <section className='grid h-full w-full'>
      <div className='grid md:grid-cols-2 lg:grid-cols-3 gap-y-6 gap-x-4'>
        {[ShoppingAppImage, CatAppImage, SpaceInvadersAppImage].map(
          (app, index) => {
            return (
              <article
                key={index}
                className='mx-auto border-4 max-h-[70vh] w-2/3 border-solid border-black rounded-2xl overflow-hidden'
              >
                <img src={app} alt='' className='w-full h-full object-fill' />
              </article>
            );
          }
        )}
      </div>
    </section>
  );
}

export default FeaturedProjectAppComponent;
