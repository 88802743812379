import React from 'react';

function AboutUsHeader() {
  return (
    <header className='grid h-full w-4/5 mx-auto -mt-2 lg:-mt-2'>
      <div className='grid gap-12 lg:w-1/2 lg:mx-auto items-center'>
        <article className='grid gap-10 text-center'>
          <section className='py-4'>
            <div>
              <h1 className='text-3xl xs:text-4xl lg:text-5xl 2xl:text-6xl font-bold text-colour3'>
                About the <span className='text-colour5'>Studio</span>
              </h1>
            </div>
            <div>
              <h2 className='lg:text-xl'>Creating and inovating for the customer.</h2>
            </div>
            <div className='border-b-2 border-solid border-colour5 pt-2'></div>
          </section>

          <section>
            <p>
              At <span className='text-colour3 font-semibold'>Byte Toast Studio</span>, we bring ideas to life with innovative
              solutions in web, circuit, and automation design. Whether you’ref
              dreaming of a sleek digital experience, a custom electronic
              system, or an intelligent communication system, our team of three
              passionate creators combines technical expertise with creative
              vision to make it happen. Let's build something extraordinary
              together.
            </p>
          </section>
        </article>
      </div>
    </header>
  );
}

export default AboutUsHeader;
