import React from 'react';

function BlogArticleItem({ post }) {
  return (
    <article className='grid max-w-3xl mx-auto gap-6 px-4 py-6'>
      <section className='grid'>
        <h1 className='text-4xl font-bold mb-4 text-colour3'>{post.title}</h1>
        <p className='text-lg text-gray-600 mb-2'>{post.subTitle}</p>
        <div className='text-sm text-gray-500 mb-4'>
          <span>By {post.authorName}</span> |{' '}
          <time dateTime={post.publishedAt}>
            Published on {new Date(post.publishedAt).toLocaleDateString()}
          </time>
        </div>
        <div className='flex flex-wrap gap-2'>
          {post.tags.map((tag, index) => (
            <span
              key={index}
              className='bg-blue-100 text-blue-800 text-sm font-medium px-3 py-1 rounded-full'
            >
              #{tag}
            </span>
          ))}
        </div>
      </section>
      <section className='grid'>
        <img
          src={post.featuredImage}
          alt={`${post.title} featured`}
          className='w-full h-auto rounded mb-6 shadow'
        />
        <div className='prose mb-6'>
          {post.content.map((blog, index) => {
            return (
              <p className='mb-4 text-lg' key={index}>
                {blog}
              </p>
            );
          })}
        </div>
        <div className='grid grid-cols-2 gap-4 mb-6'>
          {post.images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`${post.title} related ${index + 1}`}
              className='w-full h-auto rounded shadow'
            />
          ))}
        </div>
      </section>
    </article>
  );
}

export default BlogArticleItem;
