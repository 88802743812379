import React from 'react';
import { Link } from 'react-router-dom';
import { isTablet } from 'react-device-detect';
// Constants
import {
  CompanyPhoneNumber,
  CONTACT_PAGE_URL,
  ContactEmailAddress,
  SubtitleMain,
  SubtitleSecondary,
} from '../../../utils/Constants';

function HeaderTitlesSection() {
  return (
    <section className='grid w-full h-full md:max-w-[60vw] md:mx-auto lg:max-w-full lg:mx-0'>
      {/* Header article and cta */}
      <article className='grid w-full h-full py-12 lg:py-4 lg:pl-6'>
        <section className='lg:pl-4 lg:my-auto'>
          <section className='grid gap-2 my-auto mb-4 lg:my-0 py-4 h-fit lg:-mt-6'>
            <div className='h-fit text-center lg:text-left'>
              <h2 className='text-base xl:text-lg'>{SubtitleMain}</h2>
            </div>
            <div className='text-center lg:text-left lg:pl-4 py-6'>
              <h1 className='px-2 text-4xl md:text-5xl xl:text-5xl 2xl:text-[72px] font-bold text-colour3 uppercase text-stroke-black '>
                Web,{' '}
                <span className='text-colour5 text-stroke-colour3'>App</span>,
                Desktop and{' '}
                <span className='text-colour5 text-stroke-colour3'>
                  Circuit
                </span>{' '}
                Development <br /> and{' '}
                <span className='text-colour5 text-stroke-colour3'>
                  Invention!
                </span>
              </h1>
            </div>
            <div className='hidden lg:grid h-fit text-center text-lg mx-4 py-1 md:bg-colour1'>
              <h3 className=''>{SubtitleSecondary}</h3>
            </div>
            <div className='lg:hidden px-4 h-fit text-center lg:text-left glass_bg mx-4 py-1 md:bg-colour1'>
              <h3 className=''>{SubtitleSecondary}</h3>
            </div>
          </section>

          {/* Buttons */}
          <section className='grid lg:grid-cols-2 gap-2 px-4 py-4 lg:px-8'>
            <div className='grid w-full'>
              <Link
                to={CONTACT_PAGE_URL}
                className='text-center py-2 px-1 bg-colour5 text-colour1 rounded-lg active:brightness-110 ease-in hover:brightness-110 shadow-cardShadow'
              >
                <span>Fill Out Contact Form</span>
              </Link>
            </div>
            <div className='grid w-full'>
              <Link
                to={`${CONTACT_PAGE_URL}?form=callback`} // Pass query parameter
                className='text-center py-2 px-1 bg-colour1 text-colour5 border-2 border-solid border-colour5 hover:brightness-110 shadow-cardShadow rounded-lg active:brightness-125 ease-in'
              >
                <span>Request A Callback</span>
              </Link>
            </div>
          </section>

          {/* Contact info */}
          <section className='grid gap-2 text-center pt-4'>
            {isTablet ? (
              <>
                <div className='grid lg:grid-cols-2 lg:px-4'>
                  <a href={`mailto:${ContactEmailAddress}`}>
                    <p className='text-colour3'>
                      <span className='font-semibold text-colour5'>
                        Email:{' '}
                      </span>
                      {ContactEmailAddress}
                    </p>
                  </a>
                </div>
                <div>
                  <a href={`tel:${CompanyPhoneNumber}`}>
                    <p className='text-colour3'>
                      <span className='font-semibold text-colour5'>
                        Phone:{' '}
                      </span>
                      {CompanyPhoneNumber}
                    </p>
                  </a>
                </div>
              </>
            ) : (
              <div className='grid lg:grid-cols-2 lg:px-4'>
                <p className='text-colour3'>
                  <span className='font-semibold text-colour5'>Email: </span>
                  {ContactEmailAddress}
                </p>
                <p className='text-colour3'>
                  <span className='font-semibold text-colour5'>Phone: </span>
                  {CompanyPhoneNumber}
                </p>
              </div>
            )}
          </section>
        </section>
      </article>
    </section>
  );
}

export default HeaderTitlesSection;
