import React from 'react';

function ServiceHeaderAnimation() {
  return (
    <section className='hidden lg:grid absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pb-16 z-0'>
      <div className='circle_animation' style={{ '--quantity': 9 }}>
        <div className='circle_item' style={{ '--position': 1 }}>
          Websites
        </div>
        <div className='circle_item' style={{ '--position': 2 }}>
          Mobile Apps
        </div>
        <div className='circle_item' style={{ '--position': 3 }}>
          Servers + Database
        </div>
        <div className='circle_item' style={{ '--position': 4 }}>
          Circuit Design
        </div>
        <div className='circle_item' style={{ '--position': 5 }}>
          Ecommerce
        </div>
        <div className='circle_item' style={{ '--position': 6 }}>
          Wireless Sensors
        </div>
        <div className='circle_item' style={{ '--position': 7 }}>
          Smart Devices
        </div>
        <div className='circle_item' style={{ '--position': 8 }}>
          3D Modelling
        </div>
        <div className='circle_item' style={{ '--position': 9 }}>
          Desktop Software
        </div>
      </div>
    </section>
  );
}

export default ServiceHeaderAnimation;
