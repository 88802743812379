import React from 'react';
// Images
import BlenderModelImage from '../../assets/images/projects/blender_3d_model_arduplot_mk4.png';
import ArduinoBoardImage from '../../assets/images/tech/arduino-circuit-board-design.png';
// Icons
import { BsCheck2Circle } from 'react-icons/bs';
// Files
import BlenderModelFile from '../../assets/files/blender/arduplot-mk4-master-byte-toast-studio-robot.blend';

function RobotShowcaseComponent() {
  return (
    <section className='grid h-full w-full'>
      <div className='grid gap-6 w-full h-full'>
        {/* Section 1 */}
        <section className='grid gap-6 h-fit px-4'>
          {/* Header */}
          <div className='grid text-center'>
            <div>
              <h4 className='text-xl font-semibold'>
                Stage <span className='text-colour5'>04</span>
              </h4>
            </div>
            <div>
              <h5 className='text-gray-600 text-2xl'>Robot Housing Modelling</h5>
            </div>
            <div className='border-b-2 border-solid border-colour5 pt-2'></div>
          </div>

          {/* Info */}
          <div className='grid gap-6 pt-4 md:pt-6 xl:pt-12'>
            <div className='px-4 xl:px-24'>
              <img
                src={BlenderModelImage}
                alt='circuit design'
                className='w-full h-full object-cover p-1 border-4 border-solid border-colour5 shadow-cardShadow rounded'
              />
            </div>
            <div className='text-center'>
              <h6 className='text-xl xl:text-2xl'>
                <span className='text-colour5'>3D </span>Modelling + Printing
              </h6>
            </div>
            <div className='grid gap-4 md:pt-2 px-4 xl:pt-12 xl:px-12'>
              <div>
                <p>
                  <span className='font-medium'>Using 3D modelling software</span> we are able to design our
                  robots body down to the millimeter! Using silver steel rails and Nema 17
                  motors to control the position of the central unit, the
                  tapping finger. 
                </p>
              </div>
              <div>
                <p>
                  We model the housing unit for the circuit boards and can
                  ensure all the parts fit. You can see how we model each pin,
                  screw and steel rod to assamble the smoothly fitting parts.
                  This was the smallest possible version of the robots body we
                  could make. It printed in less than a day and was being fitted
                  with a variety of sensors the next day. We were quickly about
                  to work out a number of bugs and test alternate designs before
                  commiting to anything and without spending much.
                </p>
              </div>
              <div>
                <p>
                  Using a variety of 3D printers within hours we have a model we
                  can test with. Each model evolves and this is the Mark.4 where
                  we started to reduce the size and waste as much as possible.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Section 2 */}
        <section className='px-4 grid xl:grid-cols-2 xl:mt-12'>
          <div className='xl:px-24'>
            <div className='text-left lg:text-center pt-6 font-medium'>
              <h6 className='text-xl'>
                Prototype Design <span className='text-colour5'>Features:</span>
              </h6>
            </div>
            <ul className='grid gap-2 justify-center font-medium py-10'>
              <li className='grid justify-center'>
                <div className='flex gap-2'>
                  <BsCheck2Circle size={30} className='text-colour5' />
                  <span>Mounting and Housing</span>
                </div>
              </li>
              <li className='grid justify-center'>
                <div className='flex gap-2'>
                  <BsCheck2Circle size={30} className='text-colour5' />
                  <span>Efficient design</span>
                </div>
              </li>
              <li className='grid justify-center'>
                <div className='flex gap-2'>
                  <BsCheck2Circle size={30} className='text-colour5' />
                  <span>Prototype models</span>
                </div>
              </li>
              <li className='grid justify-center'>
                <div className='flex gap-2'>
                  <BsCheck2Circle size={30} className='text-colour5' />
                  <span>Cost effective</span>
                </div>
              </li>
              <li className='grid justify-center'>
                <div className='flex gap-2'>
                  <BsCheck2Circle size={30} className='text-colour5' />
                  <span>Multiple generations</span>
                </div>
              </li>
              <li className='grid justify-center'>
                <div className='flex gap-2'>
                  <BsCheck2Circle size={30} className='text-colour5' />
                  <span>Huge model libraries</span>
                </div>
              </li>
            </ul>

            <div className='grid w-full pb-2 pt-6 px-4'>
              <a
                href={BlenderModelFile}
                download='mk4_master.blend'
                className='bg-colour5 text-colour1 w-full py-2 text-xl rounded-lg shadow-cardShadow active:scale-95 hover:brightness-75 text-center block'
              >
                Download Models
              </a>
            </div>
          </div>

          {/* Image */}
          <section className='hidden xl:grid xl:items-center xl:px-24'>
            <img
              src={ArduinoBoardImage}
              alt='Arduino prototype circuit board'
              className=''
            />
          </section>
        </section>
      </div>
    </section>
  );
}

export default RobotShowcaseComponent;
