import React from 'react';
// Analytics
import { usePageTracking } from '../../hooks/useAnalytics';
// Constants
import { CompanyName } from '../../utils/Constants';
// Data
import {
  freeOnePageWebsiteMeta,
  freeOnePageWebsiteStructuredData,
} from '../../utils/data/MetaData';
// Components
import { HelmetItem } from '../../components/utils/HelmetItem';
import Navbar from '../../components/nav/Navbar';
import OfferHeader from '../../components/headers/offer/OfferHeader';
import OnePageOfferMainContainer from '../../components/offers/OnePageOfferMainContainer';
import FooterComponent from '../../components/footer/FooterComponent';

function FreeOnePageWebsitePage() {
  usePageTracking(); // Tracks page views

  return (
    <>
      {/* Tab Data */}
      <HelmetItem
        PageName='Offer'
        desc={`${CompanyName} offers expert web and circuit design solutions in England. Discover our services and featured projects.`}
        keywords={`web design, circuit design, ${CompanyName}, England, UK, custom solutions`}
        additionalMeta={freeOnePageWebsiteMeta}
        structuredData={freeOnePageWebsiteStructuredData}
      />

      {/* Page start */}
      <div className='grid min-h-screen font-poppins overflow-x-hidden overflow-y-auto'>
        <div className='grid h-full w-full'>
          <section className='grid grid-rows-reg min-h-screen overflow-hidden h-screen'>
            <Navbar />
            <OfferHeader />
          </section>

          <OnePageOfferMainContainer />

          {/* Footer */}
          <FooterComponent />
        </div>
      </div>
    </>
  );
}

export default FreeOnePageWebsitePage;
