import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
// Icons
import { IoMdMenu } from 'react-icons/io';
// Constants
import {
  ABOUT_PAGE_URL,
  CompanyName,
  CONTACT_PAGE_URL,
  HOME_PAGE_URL,
  PORTFOLIO_PAGE_URL,
  PROJECT_PAGE_URL,
  SERVICES_PAGE_URL,
  SHOWCASE_PAGE_URL,
} from '../../utils/Constants';
// Images
import BtsLogo from '../../assets/images/logos/byte-toast-studio-logo-web-developer-transparent-svg.svg';
import BtsLogoOrange from '../../assets/images/logos/byte-toast-studio-logo-web-developer-orange-svg.svg';

function Navbar() {
  const [isPhoneNavOpen, setIsPhoneNavOpen] = useState(false);

  const location = useLocation();

  useEffect(() => {
    // Close mobile nav on location change
    setIsPhoneNavOpen(false);
  }, [location.pathname]);

  const togglePhoneNav = () => {
    setIsPhoneNavOpen((prev) => !prev);
  };

  const navItems = [
    { url: HOME_PAGE_URL, title: 'Home' },
    { url: ABOUT_PAGE_URL, title: 'About Us' },
    { url: SERVICES_PAGE_URL, title: 'Services' },
    { url: SHOWCASE_PAGE_URL, title: 'Showcase' },
    { url: PORTFOLIO_PAGE_URL, title: 'Portfolio' },
    { url: PROJECT_PAGE_URL, title: 'Project' },
    { url: CONTACT_PAGE_URL, title: 'Contact' },
  ];

  return (
    <nav
      role='navigation'
      aria-label='Main Navigation'
      className={`relative grid min-h-[64px] ${
        location.pathname === SERVICES_PAGE_URL
          ? 'bg-colour3'
          : 'bg-colour1'
      }`}
    >
      <div className='grid grid-cols-[auto_auto] justify-between px-4 lg:px-5 py-4'>
        <section className='grid items-center w-fit'>
          {/* Logo Section */}
          <figure className='grid w-fit'>
            <NavLink to={HOME_PAGE_URL}>
              <img
                src={location.pathname === SERVICES_PAGE_URL ? BtsLogoOrange : BtsLogo}
                alt={`${CompanyName} navbar logo`}
                className='min-w-32 h-auto max-h-14'
              />
            </NavLink>
          </figure>
        </section>

        <section className='grid justify-end'>
          {/* Mobile Menu Toggle */}
          <section className='grid lg:hidden items-center justify-end h-full'>
            <button
              onClick={togglePhoneNav}
              aria-label='Toggle navigation menu'
              className='grid min-w-12 w-full h-fit items-center justify-center text-4xl text-colour5 dark:text-dark-text-light active:brightness-90'
            >
              <IoMdMenu className='active:scale-90 duration-300' />
            </button>
          </section>

          {/* Large Screen Menu */}
          <ul className='hidden lg:grid grid-flow-col gap-6 items-center text-colour5'>
            {navItems.map(({ url, title }) => (
              <NavItem key={url} url={url} title={title} location={location} />
            ))}
          </ul>
        </section>
      </div>

      {/* Mobile Navbar */}
      <div className={`phone-nav  ${isPhoneNavOpen ? 'open' : ''} grid`}>
        <ul
          className={`grid gap-8 items-center justify-center ${
            location.pathname === SERVICES_PAGE_URL
              ? 'bg-colour3'
              : 'bg-colour1'
          } py-12 text-center text-colour5`}
        >
          {navItems.map(({ url, title }) => (
            <NavItem key={url} url={url} title={title} location={location} />
          ))}
        </ul>
      </div>
    </nav>
  );
}

// NavItem Component
const NavItem = ({ url, title, location }) => {
  return (
    <>
      {url === CONTACT_PAGE_URL ? (
        <li className='active:scale-90'>
          <NavLink
            to={url}
            aria-label={`${title} page navigation tab`}
            className={`text-lg font-medium bg-colour5 text-colour1 px-3 py-1 rounded-lg font-poppins hover:bg-colour1 hover:!text-colour5 hover:outline-2 hover:outline hover:outline-colour5 duration-300 active:scale-75 ease-in`}
            aria-current={({ isActive }) => (isActive ? 'page' : undefined)}
            style={({ isActive }) => {
              return isActive ? { color: 'var(--colour1)' } : {};
            }}
          >
            <span>{title}</span>
          </NavLink>
        </li>
      ) : (
        <li className='active:scale-90'>
          <NavLink
            to={url}
            aria-label={`${title} page navigation tab`}
            className={`text-lg font-medium font-poppins hover:brightness-75 duration-300 active:scale-75 ease-in`}
            aria-current={({ isActive }) => (isActive ? 'page' : undefined)}
            style={({ isActive }) => {
              if (location.pathname === SERVICES_PAGE_URL) {
                return isActive ? { color: 'var(--colour1)' } : {};
              } else {
                return isActive ? { color: 'var(--colour3)' } : {};
              }
            }}
          >
            <span>{title}</span>
          </NavLink>
        </li>
      )}
    </>
  );
};

export default Navbar;
