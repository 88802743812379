import React, { useState } from 'react';
import SubHeaderComponent from '../headers/SubHeaderComponent';

function FaqComponent({ isCentered }) {
  const [activeIndex, setActiveIndex] = useState(null);

  const questionsAndAnswers = [
    {
      question: 'What services does Byte Toast Studio provide?',
      answer:
        'We specialize in web development, app development and circuit design and automation for businesses.',
    },
    {
      question: 'How much does it cost to develop a website?',
      answer:
        'Simple websites that do not require a server can be built from £200. Customised websites with advanced features and graphichs are available from £1000. Shops and Ecommerce Services are available at similar prices but can be more expensive to host.',
    },
    {
      question: 'How long does it take to develop a website?',
      answer:
        'It depends on the complexity of the project. On average, a small to custom medium-sized website takes 4-6 weeks to complete. A free single page can take as little as 2 days to complete.',
    },
    {
      question: 'How much is hosting?',
      answer:
        'We can provide all your hosting need and monitoring from £12.50 per month for a simple website and shops from £40 per month.',
    },
    {
      question: 'Can you help with IoT-based circuit designs?',
      answer:
        'Yes, we have expertise in Internet of Things and smart home devices, and can design custom circuits tailored to your specific needs for work or home.',
    },
    {
      question: 'Do you offer support after project delivery?',
      answer:
        'Absolutely! We provide ongoing support and maintenance to ensure your project runs smoothly. This includes 3 months of bug testing after the launch of every product. Along with monitoring and free updates to webstes and apps.',
    },
    {
      question: 'What technologies do you use for web development?',
      answer:
        'We primarily use React, Tailwind CSS, and Node.js for robust and scalable web applications. We prefer to code the entire website and not rely on prebuilt templates or codeless solutions such as wordpress. This gives us total control over every element.',
    },
  ];

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className='grid h-full w-full'>
      <div className='grid grid-rows-reg gap-6 w-3/4 mx-auto'>
        <SubHeaderComponent
          isCentered={isCentered}
          title={'Frequently Asked Questions'}
          subtitle={'Got Questions?'}
        />

        <section className='grid'>
          {questionsAndAnswers.map((item, index) => (
            <div
              key={index}
              className='border-b-2 border-solid border-gray-300'
            >
              <button
                className='w-full flex justify-between items-center py-4 text-left text-lg font-medium text-gray-800 focus:outline-none'
                onClick={() => toggleAnswer(index)}
              >
                <span>{item.question}</span>
                <span>{activeIndex === index ? '-' : '+'}</span>
              </button>
              {activeIndex === index && (
                <div className='px-4 pb-4 text-gray-600 h-auto'>
                  {item.answer}
                </div>
              )}
            </div>
          ))}
        </section>
      </div>
    </section>
  );
}

export default FaqComponent;
