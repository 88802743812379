import React from 'react';
// Components
import FeaturedProjectWebComponent from './FeaturedProjectWebComponent';
import FeaturedProjectAppComponent from './FeaturedProjectAppComponent';
import FeaturedProjectCircuitComponent from './FeaturedProjectCircuitComponent';
// Icons
import { FaNetworkWired } from 'react-icons/fa';
// Constants
import { CONTACT_PAGE_URL, PORTFOLIO_PAGE_URL } from '../../utils/Constants';

function FeaturedProjectDisplayComponent({ selectedType, displayTypes }) {
  return (
    <section className='grid grid-rows-rev gap-10 h-full lg:pb-20'>
      {/* Featured display */}

      {selectedType === displayTypes[0].displayType && (
        <FeaturedProjectWebComponent />
      )}
      {selectedType === displayTypes[1].displayType && (
        <FeaturedProjectAppComponent />
      )}
      {selectedType === displayTypes[2].displayType && (
        <FeaturedProjectCircuitComponent />
      )}

      <section className='grid h-full py-6 w-full'>
        <div className='grid lg:grid-cols-rev gap-10 justify-between w-full'>
          <div className='grid items-center text-center lg:text-end'>
            <p>
              Learn more about
              <span className='font-semibold italic'> graphic design </span> and
              style by viewing our Demo page for
              <span className='italic font-semibold'> inspiration</span>.
            </p>
          </div>
          <div className='grid md:grid-cols-2 gap-4 w-full'>
            <a
              href={CONTACT_PAGE_URL}
              onClick={() => {
                window.scroll(0, 0);
              }}
              aria-label='Link to contact page'
              className='text-center w-full h-fit py-2 px-4 bg-colour5 text-colour1 border-2 border-solid border-colour5 hover:bg-colour1 hover:text-colour5 hover:brightness-110 shadow-cardShadow rounded-lg active:brightness-125 ease-in duration-300'
            >
              <span>Contact Now</span>
            </a>

            <a
              href={PORTFOLIO_PAGE_URL}
              onClick={() => {
                window.scroll(0, 0);
              }}
              aria-label='Link to portfolio page for more website and app exampels'
              className='text-center h-fit py-2 px-4 bg-colour1 text-colour5 border-2 border-solid border-colour5 hover:brightness-110 shadow-cardShadow rounded-lg active:brightness-125 ease-in duration-300'
            >
              <div className='flex justify-center h-fit w-full gap-4 mx-auto'>
                Demo Projects{' '}
                <span className='items-center my-auto'>
                  <FaNetworkWired />
                </span>
              </div>
            </a>
          </div>
        </div>
      </section>
    </section>
  );
}

export default FeaturedProjectDisplayComponent;
