import React from 'react';
// Components
import SubHeaderComponent from '../headers/SubHeaderComponent';
// Images
import Website1 from '../../assets/images/blog/flower-shop-ecommerce-website-display.png'
import Website2 from '../../assets/images/blog/beauty-salon-hair-website-hero-section-full-page-social-post.jpg'
import Website3 from '../../assets/images/blog/space-planets-website-hero-section-full-page-social-post.png'

function WhatOnePageCanDo() {
  return (
    <section className='grid gap-8 h-full w-full py-12'>
      <section className='grid px-6'>
        <SubHeaderComponent
          title={
            <>
              What <span className='text-colour5'>You</span> Can Do With A One
              Page Website
            </>
          }
          subtitle={'Grow for free'}
          isCentered={true}
        />
      </section>
      <section className='grid gap-8 px-8 max-w-3xl mx-auto'>
        <section>
          <p className='text-lg text-gray-700 leading-relaxed'>
            A single-page website may seem simple, but its potential is endless.
            Whether you're showcasing your brand, testing new ideas, or
            promoting an event, it’s the perfect solution to get started.
            Tailored with professional design and optimized for SEO, a one-page
            website can help attract new customers, display your work, and even
            drive sales— all while being easy to manage and affordable.
          </p>
        </section>
        <section className='grid grid-cols-3 gap-4'>
          <div className='text-center'>
            <img
              src={Website1}
              alt='Website example 1'
              className='w-full h-auto rounded shadow-md'
            />
            <p className='mt-2 text-sm text-gray-700'>Website Example 1</p>
          </div>
          <div className='text-center'>
            <img
              src={Website2}
              alt='Website example 2'
              className='w-full h-auto rounded shadow-md'
            />
            <p className='mt-2 text-sm text-gray-700'>Website Example 2</p>
          </div>
          <div className='text-center'>
            <img
              src={Website3}
              alt='Website example 3'
              className='w-full h-auto rounded shadow-md'
            />
            <p className='mt-2 text-sm text-gray-700'>Website Example 3</p>
          </div>
        </section>

        <section>
          <ul className='list-disc list-inside space-y-4 text-gray-700'>
            <li>
              <strong className='text-colour5'>Showcase Your Brand:</strong>{' '}
              Highlight your mission, values, and uniqueness.
            </li>
            <li>
              <strong className='text-colour5'>Attract New Customers:</strong>{' '}
              Reach more people with SEO optimization.
            </li>
            <li>
              <strong className='text-colour5'>Promote Events:</strong> Create a
              page for sales, promotions, or special occasions.
            </li>
            <li>
              <strong className='text-colour5'>Share Your Portfolio:</strong>{' '}
              Present your work to impress potential clients.
            </li>
            <li>
              <strong className='text-colour5'>Drive Leads and Sales:</strong>{' '}
              Use forms and CTAs to turn visitors into customers.
            </li>
            <li>
              <strong className='text-colour5'>Test New Ideas:</strong> Start
              small and explore what works without big costs.
            </li>
          </ul>
        </section>
      </section>
    </section>
  );
}

export default WhatOnePageCanDo;
