import React, { useEffect, useRef } from 'react';
// Images
import MainRobotImage from '../../../assets/images/projects/arduplot-3d-printed-robot.png';
import ArduplotWebImage from '../../../assets/images/projects/arduplot-website-programming-controller.png';
// Constants
import { RobotName } from '../../../utils/Constants';
// Icons
import { FaCircleNotch } from 'react-icons/fa';

function ShowcasePageHeader() {
  const ImageRef = useRef();
  const labelRefs = useRef([...Array(8)].map(() => React.createRef())); // Array of 8 refs
  const timeouts = useRef([]); // Store timeouts to clear them on cleanup

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (ImageRef.current) {
          labelRefs.current.forEach((labelRef, index) => {
            const timeout = setTimeout(() => {
              if (labelRef.current) {
                labelRef.current.classList.toggle(
                  'animate_robot_label',
                  entry.isIntersecting
                );
              }
            }, index * 500);
            timeouts.current.push(timeout); // Store timeout for cleanup
          });
        }
      },
      { threshold: 0.5 }
    );

    if (ImageRef.current) {
      observer.observe(ImageRef.current);
    }

    // Cleanup function
    return () => {
      if (ImageRef.current) {
        observer.unobserve(ImageRef.current);
      }
      // Clear timeouts to prevent memory leaks
      timeouts.current.forEach(clearTimeout);
      timeouts.current = [];
    };
  }, []);

  return (
    <header className='grid gap-6 h-full w-full px-8 sm:px-10'>
      <section className='text-center mt-16 lg:pt-8 max-w-4/5 lg:w-1/2 lg:mx-auto'>
        <div className='px-4'>
          <h1 className='text-3xl xs:text-4xl lg:text-5xl 2xl:text-6xl font-bold text-colour3'>
            Showcase Project <span className='text-colour5'>ArduPlot3D</span>
          </h1>
        </div>
        <div className='mt-4 xl:mt-6'>
          <h2 className='text-gray-600 text-lg'>
            A display of skills and inventiveness
          </h2>
          <div className='border-b-2 border-solid border-colour5 pt-2'></div>
        </div>
        <div className='grid gap-4 lg:text-lg mt-2 px-4 py-8'>
          <div>
            <p>
              We created a project using a wide range of skills including
              Robotics, User interfaces, Backend Servers, 3D printing, and
              graphic plotting languages.
            </p>
          </div>
          <div>
            <p>
              The robot now runs and plays mobile games using its moving fingers
              and{' '}
              <span className='font-medium italic text-colour5'>
                earns money
              </span>{' '}
              doing it.
            </p>
          </div>
          <div>
            <p>
              This is just{' '}
              <span className='font-medium italic text-colour5'>one</span>{' '}
              example of what can be built for you if you imagine it!
            </p>
          </div>
        </div>
      </section>

      <section className='grid gap-8 px-4 lg:w-1/2 lg:mx-auto'>
        <div className='grid justify-center text-center lg:py-10'>
          <h3 className='text-3xl xl:text-4xl font-bold text-colour3 up'>
            Meet {RobotName}
          </h3>
          <div className='border-b-2 border-solid border-colour5 pt-2'></div>
        </div>

        <section id='robot-first-container' className='relative grid'>
          <div className='grid h-full sm:pt-6'>
            <img
              src={MainRobotImage}
              alt='Circuit main'
              ref={ImageRef}
              loading='lazy'
              className='w-full h-full object-cover p-1 border-4 border-solid border-colour5 shadow-cardShadow rounded'
            />
          </div>
          <div className='grid px-4 -mt-12'>
            <img
              src={ArduplotWebImage}
              alt='Circuit main'
              loading='lazy'
              className='w-full h-full object-cover p-1 z-10 bg-colour1 border-4 border-solid border-colour5 shadow-cardShadow rounded'
            />
          </div>

          {/* Labels */}
          {/* {[
            { text: 'Nema 17 Stepper Motors', position: 'left-1/2 top-0' },
            { text: 'Motorised Tapping Finger', position: 'right-1/2 top-16' },
            { text: 'Silver Steel Linear Rails', position: 'left-1/2 top-36' },
            { text: '3D Printed Body', position: 'right-1/2 top-48' },
            {
              text: 'Graphical Plotting Language',
              position: 'left-1/2 top-72',
            },
            { text: 'Website Programming UI', position: 'right-1/2 top-80' },
            { text: '5 Movement Types', position: 'left-1/2 bottom-32' },
            {
              text: 'Editable Data Points and Loops',
              position: 'right-1/2 bottom-20',
            },
          ].map((label, index) => (
            <div
              key={index}
              ref={labelRefs.current[index]}
              className={`absolute robot_label -${label.position} z-20`}
            >
              <span className='flex items-center gap-2 text-colour3'>
                <FaCircleNotch className='text-lg text-colour5' />
                {label.text}
              </span>
              <div className='border-b-2 border-solid border-colour5 pt-2'></div>
            </div>
          ))} */}
        </section>
      </section>
    </header>
  );
}

export default ShowcasePageHeader;
