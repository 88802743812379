import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// Icons
import { MdOutlineEmail } from 'react-icons/md';
import { BsTelephoneOutbound } from 'react-icons/bs';
import { FaWpforms } from "react-icons/fa6";

// Data
import { CompanyPhoneNumber, ContactEmailAddress } from '../../utils/Constants';

function ContactButtons() {
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const formType = query.get('form') || 'standard';

  const toggleFormType = () => {
    const newFormType = formType === 'callback' ? 'standard' : 'callback';
    query.set('form', newFormType);
    navigate(`${location.pathname}?${query.toString()}`);
    
    setTimeout(() => {
      const element = document.getElementById('contact-forms-container');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 0);
  };

  const contactMethods = [
    {
      id: 1,
      href: `mailto:${ContactEmailAddress}`,
      ariaLabel: 'Email us',
      icon: <MdOutlineEmail size={30} className='text-colour1' />,
      heading: 'Mail me at',
      detail: ContactEmailAddress,
      bgColour: 'bg-colour5',
    },
    {
      id: 2,
      href: `tel:${CompanyPhoneNumber}`,
      ariaLabel: 'Call us',
      icon: <BsTelephoneOutbound size={30} className='text-colour1' />,
      heading: 'Call Me On',
      detail: CompanyPhoneNumber,
      bgColour: 'bg-colour5',
    },
    {
      id: 3,
      href: '#',
      ariaLabel: formType === 'callback' ? 'Switch to Contact Form' : 'Request a Callback',
      icon: formType === 'callback' ? <FaWpforms size={30} className='text-colour1' /> : <BsTelephoneOutbound size={30} className='text-colour1' /> ,
      heading: formType === 'callback' ? 'Contact Form' : 'Request a Callback',
      detail: formType === 'callback' ? 'Switch to Contact Form' : 'Open Callback Form',
      bgColour: 'bg-colour5',
      onClick: toggleFormType,
    },
  ];

  return (
    <section className='grid h-fit w-full'>
      {contactMethods.map((method) => (
        <div className={`px-2 mt-4`} key={method.id}>
          {method.onClick ? (
            <button
              onClick={method.onClick}
              className='w-full text-sm'
              aria-label={method.ariaLabel}
            >
              <article
                className={`grid grid-cols-reg gap-4 lg:gap-4 h-full w-full bg-white sm:pr-20 lg:pr-0 shadow-md rounded py-2 px-2 lg:px-4 lg:py-4`}
              >
                <section className='grid items-center justify-center'>
                  <div
                    className={`p-2 ${method.bgColour} rounded text-center neo_orange`}
                  >
                    {method.icon}
                  </div>
                </section>
                <section className='grid justify-start poppins_text w-full grid-rows-2 !text-start'>
                  <div className='grid text-base font-semibold w-full'>
                    <h4 className='text-colour3'>{method.heading}</h4>
                  </div>
                  <div className='text-sm !select-text'>
                    <span className='!select-text'>{method.detail}</span>
                  </div>
                </section>
              </article>
            </button>
          ) : (
            <a
              className='text-sm'
              href={method.href}
              aria-label={method.ariaLabel}
            >
              <article
                className={`grid grid-cols-reg gap-4 lg:gap-4 h-full w-full bg-white sm:pr-20 lg:pr-0 shadow-md rounded py-2 px-2 lg:px-4 lg:py-4`}
              >
                <section className='grid items-center justify-center'>
                  <div
                    className={`p-2 ${method.bgColour} rounded text-center neo_orange`}
                  >
                    {method.icon}
                  </div>
                </section>
                <section className='grid justify-start poppins_text w-full grid-rows-2 !text-start'>
                  <div className='grid text-base font-semibold w-full'>
                    <h4 className='text-colour3'>{method.heading}</h4>
                  </div>
                  <div className='text-sm !select-text'>
                    <span className='!select-text'>{method.detail}</span>
                  </div>
                </section>
              </article>
            </a>
          )}
        </div>
      ))}
    </section>
  );
}

export default ContactButtons;
