import React, { useState } from 'react';
// Components
import FeaturedProjectDisplayComponent from '../projects/FeaturedProjectDisplayComponent';
import SubHeaderComponent from '../headers/SubHeaderComponent';

const displayTypes = [
  {
    label: 'Web',
    displayType: 'web',
  },
  {
    label: 'App',
    displayType: 'app',
  },
  {
    label: 'Circuit',
    displayType: 'circuit',
  },
];

function FeaturedProjectsComponent() {
  const [selectedType, setSelectedType] = useState('web');

  const setDisplayContents = (type) => {
    setSelectedType(type);
  };

  return (
    <section
      id='home-featured-projects'
      className='grid h-full w-full min-h-screen'
    >
      <div className='grid grid-rows-reg gap-y-10 lg:gap-y-16 w-4/5 mx-auto'>
        <section className='grid gap-y-8 lg:grid-flow-col lg:justify-between'>
          <SubHeaderComponent
            isCentered={false}
            title={'Our Professional and Independent Development'}
            subtitle={'Featured Projects'}
          />

          <div className='grid items-end'>
            <div className='grid grid-cols-3 gap-2'>
              {displayTypes.map((type) => (
                <SelectButton
                  key={type.label}
                  onClick={() => setDisplayContents(type.displayType)}
                  label={type.label}
                  isActive={selectedType === type.displayType}
                />
              ))}
            </div>
          </div>
        </section>

        {/* Featured project container */}
        <FeaturedProjectDisplayComponent
          selectedType={selectedType}
          displayTypes={displayTypes}
        />
      </div>
    </section>
  );
}

function SelectButton({ onClick, label, isActive }) {
  return (
    <button
      onClick={onClick}
      className={`px-4 py-2 ${
        isActive
          ? 'bg-colour5 border-2 border-solid border-colour5 text-colour1'
          : 'bg-colour1 border-2 border-solid border-colour5 hover:bg-colour5 hover:text-colour1'
      }`}
      aria-label={label}
    >
      {label}
    </button>
  );
}

export default FeaturedProjectsComponent;
