import React from 'react';
import { ProjectTypesArray } from '../../utils/data/CompanyData';
import SubHeaderComponent from '../headers/SubHeaderComponent';

function ProjectTypesComponent() {
  return (
    <section className='grid h-full w-full'>
      <div className='grid grid-rows-reg h-full gap-10 w-full'>
        <SubHeaderComponent
          isCentered={true}
          title={'Which of our Many Services Can Help You?'}
          subtitle={'Project Types'}
        />

        <section className='grid'>
          <div className='grid w-4/5 mx-auto items-center'>
            <div className='grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 h-fit gap-x-2 gap-y-4'>
              {ProjectTypesArray.map((projectType) => {
                return (
                  <article
                    key={projectType.label}
                    className='grid items-center no__highlights w-full px-1 md:px-2 py-4 rounded-xl border-2 border-colour5 border-solid hover:bg-colour5 neo_two'
                  >
                    <div className='grid sm:grid-cols-reg gap-4 sm:pl-2'>
                      <div className='grid items-center justify-center text-2xl text-colour3'>
                        {projectType.icon}
                      </div>
                      <div className='grid text-sm sm:text-base text-center'>
                        <h6>{projectType.label}</h6>
                      </div>
                    </div>
                  </article>
                );
              })}
            </div>
          </div>
        </section>
      </div>
    </section>
  );
}

export default ProjectTypesComponent;
