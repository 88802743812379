import React, { useState, useEffect } from 'react';
// Components
import ServiceHeaderAnimation from './ServiceHeaderAnimation';
// Data
import { MainServicesArray } from '../../../utils/data/CompanyData';

function HeaderServices() {
  const [showScrollText, setShowScrollText] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowScrollText(true);
    }, 3000);

    // Cleanup timer on component unmount
    return () => clearTimeout(timer);
  }, []);

  return (
    <header className='relative grid h-full w-full overflow-x-hidden bg-colour3'>
      <div className='grid relative h-full w-full items-center justify-center lg:overflow-hidden'>
        {/* Circular animation PC */}
        <ServiceHeaderAnimation />

        <section className='absolute lg:hidden top-2 grid grid-cols-5 bg-colour5 w-full py-4'>
          {MainServicesArray.map((service) => {
            return (
              <div key={service.label} className='grid no__highlights'>
                <div className='grid justify-center text-colour1 text-3xl'>
                  {service.icon}
                </div>
              </div>
            );
          })}
        </section>

        {/* Header titles */}
        <article className='grid grid-rows-3 h-fit gap-2 text-center z-10 pb-10 -mt-10 lg:-mt-0'>
          <div>
            <h1 className='text-3xl xs:text-4xl lg:text-5xl 2xl:text-6xl font-bold text-colour1'>
              SERVICES
            </h1>
          </div>
          <div className=''>
            <h2 className='text-colour5 font-medium text-xl'>
              How we can help <span className='font-semibold'>YOU!</span>
            </h2>
            <div className='border-b-2 border-solid border-colour5 pt-2'></div>
          </div>
          <div>
            {showScrollText && (
              <h3 className='hidden lg:grid text-colour1 upand-in text-lg'>
                Scroll Down For More
              </h3>
            )}
          </div>
          
        </article>

        {/* Services nav bar */}
        <section className='hidden lg:grid absolute mx-auto w-full bottom-0 border-t-4 border-solid border-colour5 bg-colour1 pb-2 pt-4'>
          <div className='grid md:grid-cols-5'>
            {MainServicesArray.map((service) => {
              return (
                <div key={service.label} className='grid no__highlights'>
                  <div className='grid justify-center text-2xl'>{service.icon}</div>
                  <div className='text-center'>
                    <h4>{service.label}</h4>
                  </div>
                </div>
              );
            })}
          </div>
        </section>
      </div>
    </header>
  );
}

export default HeaderServices;
