import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
// Components
import ContactForm from '../../components/forms/ContactForm';
import ContactButtons from '../../components/contact/ContactButtons';
import CallbackForm from '../forms/CallbackForm';
// Data
import { emptyCallbackFormData, emptyContactFormData } from '../../utils/data/FormData';

function ContactPageMainContainer() {
  const query = new URLSearchParams(useLocation().search);

  const [formData, setFormData] = useState(emptyContactFormData);
  const [callbackFormData, setcallbackFormData] = useState(emptyCallbackFormData);

  const formType = query.get('form') || 'standard'; // Default to standard form

  return (
    <main role='main' className='grid w-full h-full overflow-hidden'>
      <div className='grid xl:grid-cols-2 w-full h-full overflow-hidden'>
        {/* Header */}
        <section className='grid w-full h-full'>
          <div className='grid items-center text-center pt-16 lg:pt-6'>
            <div className=''>
              <div className='grid w-full px-12 sm:px-20 lg:px-2 lg:pt-10 xl:-mt-20'>
                <span className='xl:!text-start mb-2 block text-lg sm:text-xl !font-semibold poppins_title lg:pl-12 xl:pl-24 text-colour3'>
                  Let's chat
                </span>
                <h1 className='mb-8 text-4xl sm:text-5xl text-colour5 font-bold text-purple-main poppins_title'>
                  Tell me about <span className='text-colour3'>your</span>{' '}
                  <br className='hidden lg:block' /> project and goals.
                </h1>
                <h3 className='text-lg text-colour3 leading-5'>
                  Let's see what we can create!
                </h3>
              </div>

              <div className='grid px-6 sm:px-20 md:px-32 pt-12 xl:pt-0 w-full'>
                <ContactButtons />
              </div>
            </div>

            <div className='my-12 xl:hidden w-full'>
              <span className='text-xl !font-semibold'>~ OR ~</span>
            </div>
          </div>
        </section>

        {/* Contact component */}
        <section className='grid w-full max-w-full overflow-hidden'>
          <div
            id='contact-forms-container'
            className={`grid px-4 sm:px-20 md:px-32 xl:px-6 pb-12 xl:py-6 xl:mr-20 ${
              formType === 'callback' && 'items-center'
            }`}
          >
            {formType === 'callback' ? (
              <CallbackForm
                callbackFormData={callbackFormData}
                setcallbackFormData={setcallbackFormData}
              />
            ) : (
              <ContactForm formData={formData} setFormData={setFormData} />
            )}
          </div>
        </section>
      </div>
    </main>
  );
}

export default ContactPageMainContainer;
