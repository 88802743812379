import React, { useState } from 'react';
// Data
import { salesAppTypesArray } from '../../utils/data/CompanyData';

function DesignInformationHeader({ type, changeDisplayedProduct }) {
  const [salesOptions] = useState(salesAppTypesArray);

  return (
    <section className='grid bg-colour5 text-colour1 grid-flow-col items-center h-fit px-6 py-2 overflow-hidden'>
      <section className='rounded-lg px-4'>
        <h2 className='text-2xl text-center lg:text-left py-1'>
          {type.label} Project
        </h2>
      </section>

      <section className='hidden md:grid grid-flow-col justify-end'>
        <section className='grid grid-flow-col gap-4 items-center w-full'>
          <div className='grid justify-center'>
            <ul className='flex gap-6'>
              {salesOptions.map((type, index) => (
                <li key={index}>
                  <span
                    onClick={() => changeDisplayedProduct(type)}
                    className='hover:brightness-75 cursor-pointer'
                  >
                    {type.label}
                  </span>
                </li>
              ))}
            </ul>
          </div>

          {/* Image */}
          <section className='p-2 bg-white w-12 h-12 rounded-xl m-1 shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px]'>
            <img src={type.image} alt='object-cover' loading='lazy' />
          </section>
        </section>
      </section>
    </section>
  );
}

export default DesignInformationHeader;
