import React from 'react';
// Constants
import { CompanyName } from '../../utils/Constants';
// Data
import {
  aboutUsAdditionalMeta,
  aboutUsStructuredData,
} from '../../utils/data/MetaData';
// Components
import Navbar from '../../components/nav/Navbar';
import { HelmetItem } from '../../components/utils/HelmetItem';
import FooterComponent from '../../components/footer/FooterComponent';
import AboutUsHeader from '../../components/headers/about/AboutUsHeader';
import MeetTheTeamComponent from '../../components/home/MeetTheTeamComponent';
import MissionStatementComponent from '../../components/about/MissionStatementComponent';
import LatestBlogPostsComponent from '../../components/blog/LatestBlogPostsComponent';
import PortfolioAndContactCta from '../../components/cta/PortfolioAndContactCta';
import WhoWeWorkWithComponent from '../../components/about/WhoWeWorkWithComponent';
import CustomerReviewsComponent from '../../components/reviews/CustomerReviewsComponent';

function AboutUsPage() {
  return (
    <>
      {/* Tab Data */}
      <HelmetItem
        PageName='About Us'
        desc={`Learn more about ${CompanyName}, a leading web and circuit design company in the UK.`}
        keywords={`about us, web design, circuit design, ${CompanyName}, UK, innovative solutions`}
        additionalMeta={aboutUsAdditionalMeta}
        structuredData={aboutUsStructuredData}
      />

      {/* Page start */}
      <div className='grid min-h-screen bg-main-background font-poppins overflow-x-hidden overflow-y-auto'>
        <div className='grid h-full w-full'>
          {/* Header + Navigation */}
          <section className='grid h-screen min-h-screen w-full'>
            <div className='grid grid-rows-reg w-full'>
              <Navbar />
              <AboutUsHeader />
            </div>
          </section>

          {/* Main page content */}
          <main role='main' className='grid gap-16 mb-12'>
            <div className='lg:pt-12'>
              <MissionStatementComponent />
            </div>
            <div>
              <WhoWeWorkWithComponent isCentered={true} />
            </div>
            <div>
              <MeetTheTeamComponent isCentered={false} />
            </div>
            <div>
              <CustomerReviewsComponent isCentered={true} />
            </div>
            <div>
              <PortfolioAndContactCta isCentered={false} />
            </div>
            <div>
              <LatestBlogPostsComponent isCentered={true} />
            </div>
          </main>

          {/* Footer */}
          <FooterComponent />
        </div>
      </div>
    </>
  );
}

export default AboutUsPage;
