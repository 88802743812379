import React, { useState } from 'react';
// Data
import { portfolioPageDisplayProjectsDataArray } from '../../utils/data/PortfolioDataUtils';
// Components
import LaptopAnimationComponent from './LaptopAnimationComponent';

function WebsiteDisplayPortfolioContainer() {
  const [portfolioWebItems] = useState(portfolioPageDisplayProjectsDataArray);
  const [displayInfoItem, setDisplayInfoItem] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);

  const openInfoPanel = (item) => {
    setDisplayInfoItem(item);
  };

  return (
    <section className='grid gap-8 lg:gap-12'>
      <LaptopAnimationComponent
        portfolioWebItems={portfolioWebItems}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
      />

      {/* Display items */}
      <section className='grid h-full w-full'>
        <div className='laptop_fade_in grid sm:px-8 sm:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-16 lg:gap-y-20 mx-auto'>
          {portfolioWebItems.map((item) => (
            <DisplaySiteCard
              key={item.id}
              item={item}
              openInfoPanel={openInfoPanel}
            />
          ))}
        </div>
      </section>
    </section>
  );
}

function DisplaySiteCard({ item, openInfoPanel }) {
  return (
    <article
      className='grid justify-center'
      aria-label={`${item.title} display site.`}
    >
      <div className='relative gap-2 flex w-80 sm:w-72 md:w-80 flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-md pb-6'>
        <section className='relative mx-4 -mt-6 h-40 overflow-hidden rounded-xl bg-blue-gray-500 bg-clip-border text-white shadow-lg shadow-blue-gray-500/40 bg-gradient-to-r from-colour5 to-colour6'>
          {/* Header image */}
          <figure className='grid h-full text-8xl items-center justify-center'>
            <img src={item.thumbnail.imagePath} alt={item.thumbnail.altText} />
          </figure>
        </section>
        <section className='grid grid-rows-rev gap-2 px-6 mt-2'>
          <section className='grid'>
            <h5 className='mb-2 block text-colour3 font-bold text-xl leading-snug tracking-normal antialiased'>
              {item.title}
            </h5>
            <h6 className='text-sm font-medium text-colour3 mb-4'>
              {item.headline}
            </h6>
            <p className='line-clamp-2 text-gray-700'>{item.description}</p>
          </section>
          <section className='grid grid-cols-2 gap-2 mt-4'>
            <div>
              <button
                href='/'
                data-r1ipple-light='true'
                onClick={() => {
                  openInfoPanel(item);
                }}
                className='select-none rounded-lg bg-colour5 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-colour5/20 transition-all hover:shadow-lg hover:shadow-colour5/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'
              >
                Learn More
              </button>
            </div>
            <div className='mx-auto my-auto'>
              <a
                href={item.demoUrl}
                target='_blank'
                rel='noreferrer'
                data-r1ipple-light='true'
                aria-label={`Link to live site for ${item.title}`}
                className='select-none rounded-lg py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-colour5  transition-all disabled:pointer-events-none disabled:opacity-50'
              >
                Live Site
              </a>
            </div>
          </section>
        </section>
      </div>
    </article>
  );
}

export default WebsiteDisplayPortfolioContainer;
