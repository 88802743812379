import React from 'react';
import { CompanyName } from '../../utils/Constants';

function BlogPageHeader() {
  return (
    <header className='grid w-full h-full'>
      <section className='grid gap-4 md:gap-6 lg:gap-8 py-4 w-full h-full text-center'>
        <div>
          <h1 className='text-2xl md:text-3xl lg:text-5xl font-bold text-colour3'>
            Welcome to Our <span className='text-colour5'>Blog</span>
          </h1>
        </div>
        <div className='lg:max-w-xl lg:mx-auto'>
          <h2>
            Discover what's going on at {CompanyName} recently and more. Our
            articles aim to show of some fun ideas we have been working on. As
            well as a display of our technical skills and share tips and
            designs.
          </h2>
        </div>
        <div>
          <ul className='grid md:grid-cols-3 gap-2 w-full md:w-fit md:mx-auto mdjustify-evenly text-xl text-colour5 font-semibold'>
            <li className='min-w-36 grid w-full justify-center bg-white border-solid border-colour5 border-2 rounded'>
              <span>Learn</span>
            </li>
            <li className='min-w-36 grid w-full justify-center bg-white border-solid border-colour5 border-2 rounded'>
              <span>Entertain</span>
            </li>
            <li className='min-w-36 grid w-full justify-center bg-white border-solid border-colour5 border-2 rounded'>
              <span>Inspiration</span>
            </li>
          </ul>
        </div>
      </section>
    </header>
  );
}

export default BlogPageHeader;
