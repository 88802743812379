import React from 'react';
// Video
import ArduplotVideo from '../../assets/video/device/arduplot_robot_demo_working_product.mp4';
// Icons
import { BsCheck2Circle } from 'react-icons/bs';
import { ARDUPLOT_URL } from '../../utils/Constants';

function FrontendShowcaseComponent() {
  return (
    <section className='grid h-full w-full'>
      <div className='grid gap-6 w-full h-full'>
        <section className='grid gap-8 h-fit px-4'>
          {/* Header */}
          <div className='grid text-center'>
            <div>
              <h4 className='text-xl font-semibold'>
                Stage <span className='text-colour5'>01</span>
              </h4>
            </div>
            <div>
              <h5 className='text-gray-600 text-2xl'>Frontend User Interface</h5>
            </div>
            <div className='border-b-2 border-solid border-colour5 pt-2'></div>
          </div>

          {/* Little info */}
          <section className='grid xl:grid-cols-2 pt-4 md:pt-6 xl:pt-12'>
            <div className='grid gap-4 px-4'>
              <div>
                <p>
                  <span className='font-semibold'>Deciding</span> to create a
                  website and mobile app as a user interface, allows us to
                  program and design movement patterns for our robot. The web
                  canvas is a powerful tool and can be used to create plotted
                  charts of motions for the robot to take across the phone.
                </p>
              </div>
              <div>
                <p>
                  We created a simple to use site with a handy toolbar of
                  features. It's designed to be understandable without
                  instructions. The site goal is for us to be able to program in
                  robot arm movement patterns. We achieve this by plotting graph
                  points on the screen designs to be the size of the phone
                  selected.
                </p>
              </div>
            </div>

            {/* VIDEO */}
            {/* TODO: Replace with website video  */}
            <div className=''>
              <div className='pt-8 xl:pt-0 px-4 md:px-6'>
                <video
                  width='100%'
                  muted
                  autoPlay={true}
                  loading='lazy'
                  aria-label='A demo video of the Arduplot robot in action'
                  className='w-full h-full object-cover p-1 border-4 border-solid border-colour5 shadow-cardShadow rounded'
                >
                  <source src={ArduplotVideo} type='video/mp4' />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </section>
        </section>

        <section className='px-4 font-medium'>
          <div className='text-left lg:text-center pt-4 lg:pt-6'>
            <h6 className='text-xl'>
              Web Interface <span className='text-colour5'>Features:</span>
            </h6>
          </div>
          <ul className='grid gap-2 justify-center py-10'>
            <li className='grid justify-center'>
              <div className='flex gap-2'>
                <BsCheck2Circle size={30} className='text-colour5' />
                <span>Graphical Plotting Canvas</span>
              </div>
            </li>
            <li className='grid justify-center'>
              <div className='flex gap-2'>
                <BsCheck2Circle size={30} className='text-colour5' />
                <span>5 Movement Types</span>
              </div>
            </li>
            <li className='grid justify-center'>
              <div className='flex gap-2'>
                <BsCheck2Circle size={30} className='text-colour5' />
                <span>Tap 1-3 Fingers</span>
              </div>
            </li>
            <li className='grid justify-center'>
              <div className='flex gap-2'>
                <BsCheck2Circle size={30} className='text-colour5' />
                <span>Drag and Pinch Plotting</span>
              </div>
            </li>
            <li className='grid justify-center'>
              <div className='flex gap-2'>
                <BsCheck2Circle size={30} className='text-colour5' />
                <span>Responsive design</span>
              </div>
            </li>
            <li className='grid justify-center'>
              <div className='flex gap-2'>
                <BsCheck2Circle size={30} className='text-colour5' />
                <span>Design autosave</span>
              </div>
            </li>
          </ul>

          <div className='xl:w-1/2 mx-auto'>
            <div className='grid w-full py-2 px-4'>
              <a
                href={ARDUPLOT_URL}
                target='_blank'
                rel='noreferrer'
                className='bg-colour5 text-center text-colour1 w-full py-2 text-xl rounded-lg shadow-cardShadow active:scale-95 hover:brightness-75'
              >
                Visit Site
              </a>
            </div>
            <div className='grid w-full py-2 px-4'>
              <button className='bg-colour5 text-colour1 w-full py-2 text-xl rounded-lg shadow-cardShadow active:scale-95 hover:brightness-75'>
                View Code
              </button>
            </div>
          </div>
        </section>
      </div>
    </section>
  );
}

export default FrontendShowcaseComponent;
