import {
  ABOUT_PAGE_URL,
  BLOG_PAGE_URL,
  CONTACT_PAGE_URL,
  HOME_PAGE_URL,
  PROJECT_PAGE_URL,
  SERVICES_PAGE_URL,
  SHOWCASE_PAGE_URL,
} from '../Constants';

export const FooterLinksArray = [
  {
    name: 'Home',
    route: HOME_PAGE_URL,
  },
  {
    name: 'About',
    route: ABOUT_PAGE_URL,
  },
  {
    name: 'Services',
    route: SERVICES_PAGE_URL,
  },
  {
    name: 'Showcase',
    route: SHOWCASE_PAGE_URL,
  },
  {
    name: 'Project',
    route: PROJECT_PAGE_URL,
  },
  {
    name: 'Contact',
    route: CONTACT_PAGE_URL,
  },
  {
    name: 'Blog',
    route: BLOG_PAGE_URL,
  },
];
