import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
// Api
import client from '../../api/client';
// Constants
import { GET_BLOG_POST_BY_TITLE_API } from '../../utils/Constants';
// Data
import {
  blogPostAdditionalMeta,
  blogPostStructuredData,
} from '../../utils/data/MetaData';
// Components
import Navbar from '../../components/nav/Navbar';
import { HelmetItem } from '../../components/utils/HelmetItem';
import FooterComponent from '../../components/footer/FooterComponent';
import BlogArticleItem from '../../components/blog/BlogArticleItem';

const BlogPostPage = React.memo(() => {
  const { title } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [post, setPost] = useState(location.state?.post || null);
  const [loading, setLoading] = useState(!post);
  const [error, setError] = useState(null);

  // useEffect(() => {
  //   if (!post) {
  //     setLoading(true);
  //     client
  //       .get(`${GET_BLOG_POST_BY_TITLE_API}/${title}`, false)
  //       .then((res) => {
  //         setLoading(false);
  //         setPost(res.data.post);
  //       })
  //       .catch((err) => {
  //         setLoading(false);
  //         setError('Unable to fetch post data');
  //         console.error('Unable to retrieve post data', err);
  //       });
  //   }
  // }, [title, post]);

  // if (loading) {
  //   return <div>Loading...</div>;
  // }

  // if (error) {
  //   return <div>{error}</div>;
  // }

  // if (!post) {
  //   return <div>No post found.</div>;
  // }

  return (
    <>
      {/* SEO */}
      <HelmetItem
        PageName={post.title}
        desc={post.excerpt || post.description}
        keywords={`${post.keywords}, blog, Byte Toast Studio`}
        additionalMeta={blogPostAdditionalMeta(post)}
        structuredData={blogPostStructuredData(post)}
      />

      {/* Page */}
      <div className='grid min-h-screen lg:h-screen bg-colour1 font-poppins'>
        <div className='grid gap-28 h-full w-full'>
          <Navbar />

          {/* Blog Article */}
          <main className='container mx-auto p-4'>
            <BlogArticleItem post={post} />
            <section className='grid px-4'>
              <button
                onClick={() => navigate(-1)} // Navigate back to the previous page
                className='text-center py-2 px-1 bg-colour1 text-colour5 border-2 border-solid border-colour5 hover:brightness-110 shadow-cardShadow rounded-lg active:brightness-125 ease-in'
              >
                Back
              </button>
            </section>
          </main>

          {/* Footer */}
          <FooterComponent />
        </div>
      </div>
    </>
  );
});

export default BlogPostPage;
