import React from 'react';
import { Link } from 'react-router-dom';
// Data
import { boltOnServicesArray } from '../../utils/data/InfoPageData';
// Components
import PortfolioAndContactCta from '../cta/PortfolioAndContactCta';
// Constants
import { CONTACT_PAGE_URL } from '../../utils/Constants';

function OptionalServicesContainer({ setSelectedService }) {
  return (
    <>
      <header className='grid gap-4 md:gap-6 lg:gap-8 px-8 py-4 text-center container lg:w-1/2 mx-auto mt-16 mb-12'>
        <h1 className='text-3xl md:text-4xl lg:text-5xl font-extrabold text-colour3'>
          <span className='text-colour5'>Enhance</span> Your{' '}
          <span className='text-colour5'>Website</span> with Our Services
        </h1>
        <div className='h-fit'>
          <p className='text-lg max-w-2xl mx-auto'>
            Explore our add-on services designed to enhance your website's
            functionality and customer experience. Ask today for more
            information.
          </p>
        </div>
        <div>
          <Link
            to={CONTACT_PAGE_URL}
            onClick={() => {
              window.scroll(0, 0);
            }}
            className='text-colour5 text-lg'
            aria-label='Contact us to learn more about our website enhancement services'
          >
            Contact Us
          </Link>
        </div>
      </header>
      <main role='main' className='grid gap-12'>
        <div className='grid container mx-auto px-4'>
          <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8'>
            {boltOnServicesArray.map((service) => (
              <div className='grid relative' key={service.id}>
                <ServiceCard
                  service={service}
                  onClick={(s) => setSelectedService(s)}
                />
              </div>
            ))}
          </div>
        </div>

        <PortfolioAndContactCta />
      </main>
    </>
  );
}

const ServiceCard = ({ service, onClick }) => (
  <article
    className='group hover:absolute border cursor-pointer rounded-lg bg-white shadow-md hover:shadow-2xl transition transform hover:-translate-y-1 hover:scale-105 w-full hover:z-20'
    role='button'
    tabIndex='0'
    id={service.id}
    onClick={() => onClick(service)}
  >
    <div className='relative w-full h-full p-6'>
      <div
        className='absolute top-4 right-4 bg-gray-100 rounded-full p-2 shadow-md text-2xl'
        aria-label={service.title || 'Service Icon'}
      >
        {service.icon}
      </div>
      <p className='text-colour5 mt-2'>{service.price}</p>
      <div>
        <h3 className='text-xl font-semibold text-colour3 group-hover:text-colour5 group-hover:text-2xl duration-150'>
          {service.title}
        </h3>
      </div>
      <p className='text-sm text-gray-600 mt-3'>{service.description}</p>
      <span className='text-sm italic font-poppins text-colour5'>
        Learn More
      </span>
    </div>
  </article>
);

export default OptionalServicesContainer;
