import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'; // For query parsing
// Data
import {
  infoPageAdditionalMeta,
  infoPageStructuredData,
} from '../../utils/data/MetaData';
// Hooks
import { usePageTracking } from '../../hooks/useAnalytics';
import useNavigateToPage from '../../hooks/useNavigateToPage';
// Components
import Navbar from '../../components/nav/Navbar';
import { HelmetItem } from '../../components/utils/HelmetItem';
import FooterComponent from '../../components/footer/FooterComponent';
// Constants
import { CompanyName, ALLOWED_PAGE_TYPES } from '../../utils/Constants';
import OptionalServicesContainer from '../../components/info/OptionalServicesContainer';

// Helper to parse query string
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const InfoPage = React.memo(() => {
  usePageTracking(); // Track page views
  const navigateToPage = useNavigateToPage();

  const query = useQuery();
  const pageType = query.get('type') || 'sales';

  const [selectedService, setSelectedService] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!ALLOWED_PAGE_TYPES.includes(pageType)) {
      setError('Invalid page type');
      navigateToPage('/404'); // Redirect to a 404 page or custom error page
      return;
    }

    // fetchData();
  }, [query, pageType]);

  return (
    <>
      <HelmetItem
        PageName={pageType.charAt(0).toUpperCase() + pageType.slice(1)} // Capitalizes the first letter of the pageType
        desc={`Explore more about ${
          pageType === 'offer'
            ? 'our latest special offers'
            : pageType === 'items'
            ? 'available items in our store'
            : `our ${pageType}`
        } at ${CompanyName}.`}
        keywords={`${pageType}, ${CompanyName}, web services`}
        additionalMeta={infoPageAdditionalMeta(pageType)}
        structuredData={infoPageStructuredData(pageType)}
      />

      <div className='grid min-h-screen font-poppins overflow-x-hidden overflow-y-auto'>
        <div className='grid grid-rows-reg h-full overflow-hidden'>
          <Navbar />

          <OptionalServicesContainer setSelectedService={setSelectedService} />

          <div className='grid h-full w-full mt-20'>
            <FooterComponent />
          </div>
        </div>

        {selectedService && (
          <Modal
            service={selectedService}
            onClose={() => setSelectedService(null)}
          />
        )}
      </div>
    </>
  );
});

const Modal = ({ service, onClose }) => (
  <section className='fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50 px-4'>
    <div className='bg-white p-8 rounded-lg max-w-xl shadow-xl relative'>
      <button
        className='absolute top-4 right-4 text-gray-500 hover:text-gray-800'
        onClick={onClose}
      >
        ✖
      </button>
      <h3 className='text-3xl font-bold mb-4 text-colour3 border-solid border-b-2 border-colour5 w-fit pb-1'>
        {service.title}
      </h3>
      <p className='text-gray-700 mb-6'>{service.details}</p>
      <p className='text-lg font-semibold text-colour5'>{service.price}</p>
      <button
        className='mt-6 px-4 py-2 bg-colour5 text-white rounded hover:brightness-110 transition'
        onClick={onClose}
      >
        Close
      </button>
    </div>
  </section>
);

export default InfoPage;
