import React from 'react';
// Data
import { MainServicesArray } from '../../utils/data/CompanyData';
import SubHeaderComponent from '../headers/SubHeaderComponent';

function ServicesComponent() {
  return (
    <section className='grid h-full w-full min-h-screen'>
      <div className='grid grid-rows-reg gap-6 h-full w-full'>
        <div className='px-4'>
          <SubHeaderComponent
            isCentered={true}
            title={'Our Professional and Independent Development'}
            subtitle={'Our Services'}
          />
        </div>

        <section className='grid px-8 xl:w-4/5 mx-auto'>
          <div className='grid py-6'>
            <div className='five_item_grid'>
              {MainServicesArray.map((service) => (
                <Card service={service} key={service.label} />
              ))}
            </div>
          </div>
        </section>
      </div>
    </section>
  );
}

function Card({ service }) {
  return (
    <article className='five_item h-full'>
      <div className='grid'>
        <div className='grid items-center justify-center mx-auto py-4 neo_round w-16 h-16 mb-4 text-3xl text-colour3'>
          {service.icon}
        </div>
        <div className='text-center'>
          <h4 className='font-medium text-lg text-colour5'>{service.label}</h4>
        </div>
        <div className='text-center font-sm'>
          <p>{service.content}</p>
        </div>
      </div>
    </article>
  );
}

export default ServicesComponent;
