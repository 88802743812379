import React from 'react';
// Data
import { reviewsDataArray } from '../../utils/data/ReviewsDataUtil';
import SubHeaderComponent from '../headers/SubHeaderComponent';

function CustomerReviewsComponent({ isCentered }) {
  return (
    <section
      className='grid h-full w-full mt-4'
      aria-labelledby='customer-reviews-heading'
    >
      <div className='grid grid-rows-reg gap-8 w-3/4 mx-auto mb-6'>
        <SubHeaderComponent
            isCentered={isCentered}
            title={'How we have helped our customers achieve their goals'}
            subtitle={'Customer Reviews'}
          />

        {/* Reviews list */}
        <section className='grid items-start'>
          <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4'>
            {reviewsDataArray.map((review, index) => (
              <CustomerReviewCard key={index} review={review} />
            ))}
          </div>
        </section>
      </div>
    </section>
  );
}

function CustomerReviewCard({ review }) {
  return (
    <article
      role='region'
      aria-labelledby={`review-label-${review.label}`}
      aria-describedby={`review-desc-${review.label}`}
      className='grid text-center bg-colour3 hover:bg-colour1 text-colour1 hover:text-black duration-150 ease-in w-full h-full px-4 py-6 border-4 border-solid border-colour5 rounded-lg overflow-hidden neo_review'
    >
      <div className='grid'>
        <h3
          id={`review-label-${review.label}`}
          className='font-semibold text-lg truncate'
        >
          {review.label}
        </h3>
        <div aria-hidden='true'>⭐⭐⭐⭐⭐</div>
        <div className='grid py-4 justify-center items-center'>
          <img
            src={review.imageSource}
            alt={`${review.label} - Reviewer`}
            className='rounded-full object-cover w-20 h-20 xl:w-32 xl:h-32'
          />
        </div>
        <div id={`review-desc-${review.label}`} className='grid items-start'>
          <p className='text-sm'>{review.desc}</p>
        </div>
        <div className='pt-2'>
          <a
            href={review.url}
            target='_blank'
            rel='noreferrer'
            className='text-colour5 italic hover:brightness-125 focus:underline focus:text-colour3'
            aria-label={`Visit site for ${review.label}`}
          >
            Visit Site
          </a>
        </div>
      </div>
    </article>
  );
}

export default CustomerReviewsComponent;
