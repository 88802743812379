import React from 'react';
// Image
import CircuitImage from '../../assets/images/projects/arduplot-circuit-board-and-housing.png';
import ArduinoBoardImage from '../../assets/images/tech/arduino-circuit-board-design.png';
// Icons
import { BsCheck2Circle } from 'react-icons/bs';

function CircuitShowcaseComponent() {
  return (
    <section className='grid h-full w-full'>
      <div className='grid gap-6 xl:gap-24 w-full h-full'>
        <section className='grid gap-6 h-fit px-4'>
          {/* Header */}
          <div className='grid text-center'>
            <div>
              <h4 className='text-xl font-semibold'>
                Stage <span className='text-colour5'>03</span>
              </h4>
            </div>
            <div>
              <h5 className='text-gray-600 text-2xl'>Circuit Design and Controller</h5>
            </div>
            <div className='border-b-2 border-solid border-colour5 pt-2'></div>
          </div>

          <section className='grid gap-4 xl:grid-cols-2 w-full px-4 pt-4 md:pt-6 xl:pt-12'>
            <div className='grid gap-4'>
              <div>
                <p>
                  <span className='font-semibold'>In our lab</span> for electrical engineering we can prototype a great
                  number of devices without needing to spend a large amount.
                  This prototype is made from every day parts, and we send the
                  finished CAD designs of a circuit to a professional printers
                  in Germany for a factory quality finish.
                </p>
              </div>
              <div>
                <p>
                  Using the advanced prototyping boards, like the Arduino Mega
                  2560 in this project we can test circuit designs, power draw
                  and work out glitches before going to print. We are able to
                  extensively test and modify the circuit and housing using our
                  3D printers for creating the body and perfectly fitting
                  circuit housing.
                </p>
              </div>
              <div>
                <p>
                  We chose the <span className='font-medium'>Arduino Mega</span> for this project, it comes with
                  built in wifi and bluetooth connectivity. Incldes over 50
                  input and output sensor ports and can be adapted to complete
                  any tasks. AI controlled remote devices, Sensor platforms
                  connected to networks, robotics, and security. Arduino,
                  Raspberry Pi, ESP and many other prototyping circuit boards
                  can be used to quickly create and program circuits.
                </p>
              </div>
            </div>
            <div className='py-4 md:px-6'>
              <img
                src={CircuitImage}
                alt='circuit design'
                className='w-full h-full object-cover p-1 border-4 border-solid border-colour5 shadow-cardShadow rounded'
              />
            </div>
          </section>
        </section>

        {/* Second section */}
        <section className='grid px-4'>
          <div className='text-left lg:text-center'>
            <h6 className='text-xl font-medium'>
              Prototype Design <span className='text-colour5'>Features:</span>
            </h6>
          </div>
          <ul className='grid gap-2 justify-center font-medium py-10'>
            <li className='grid justify-center'>
              <div className='flex gap-2'>
                <BsCheck2Circle size={30} className='text-colour5' />
                <span>Arduino Mega2560</span>
              </div>
            </li>
            <li className='grid justify-center'>
              <div className='flex gap-2'>
                <BsCheck2Circle size={30} className='text-colour5' />
                <span>Nema 17 stepper motors</span>
              </div>
            </li>
            <li className='grid justify-center'>
              <div className='flex gap-2'>
                <BsCheck2Circle size={30} className='text-colour5' />
                <span>Wifi + Bluetooth</span>
              </div>
            </li>
            <li className='grid justify-center'>
              <div className='flex gap-2'>
                <BsCheck2Circle size={30} className='text-colour5' />
                <span>Power bus</span>
              </div>
            </li>
            <li className='grid justify-center'>
              <div className='flex gap-2'>
                <BsCheck2Circle size={30} className='text-colour5' />
                <span>LCD Screen</span>
              </div>
            </li>
            <li className='grid justify-center'>
              <div className='flex gap-2'>
                <BsCheck2Circle size={30} className='text-colour5' />
                <span>5 Control buttons</span>
              </div>
            </li>
            <li className='grid justify-center'>
              <div className='flex gap-2'>
                <BsCheck2Circle size={30} className='text-colour5' />
                <span>SD Card reader</span>
              </div>
            </li>
          </ul>

          <section className='grid xl:grid-cols-2 xl:gap-6 xl:mt-12'>
            <div className='grid gap-4 px-4 xl:px-12 xl:h-fit xl:my-auto'>
              <div>
                <p>
                  We designed the circuit with simpliciy and connectivity as the
                  goal. The parts are afforable off the shelf components with
                  affordability and reliability in mind. It can connect to
                  computers and mobile devices using the Arduino Mega's wifi and
                  bluetooth modules. In additon to that a <span className='font-medium'>Micro SD card</span> reader
                  is included.
                </p>
              </div>
              <div>
                <p>
                  USB power options as well as a mains converter. This device
                  was built with a redundancy for everything. The prototypes are
                  made from reusable parts and we use the top board printing
                  services to get printed circuit boards made to our designs
                  with quarantees of the highest quality.
                </p>
              </div>
            </div>

            <div className='grid xl:px-24'>
              <div className='hidden xl:grid'>
                <img
                  src={ArduinoBoardImage}
                  alt='Arduino prototype circuit board'
                  className=''
                />
              </div>
              <div className='grid w-full py-8 px-4'>
                <button className='bg-colour5 text-colour1 w-full py-2 text-xl rounded-lg shadow-cardShadow active:scale-95 hover:brightness-75'>
                  View Code
                </button>
              </div>
            </div>
          </section>
        </section>
      </div>
    </section>
  );
}

export default CircuitShowcaseComponent;
