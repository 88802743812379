import React, { useState } from 'react';
// Api
import client from '../../api/client';
// Form utils
import ProjectTypeSelect from './ProjectTypeSelect';
// Constants
import { SUBMIT_CALLBACK_FORM_API } from '../../utils/Constants';
// Data
import { emptyCallbackFormData } from '../../utils/data/FormData';
import LoadingSpinner from '../utils/LoadingSpinner';

function CallbackForm({ callbackFormData, setcallbackFormData }) {
  const submitStates = ['waiting', 'inprogress', 'failed', 'success'];
  const [errors, setErrors] = useState({});

  const [submitState, setSubmitState] = useState(submitStates[0]); // Default to "waiting"

  const validate = () => {
    const newErrors = {};
    if (!callbackFormData.fullName.trim()) {
      newErrors.fullName = 'First name is required.';
    }
    if (!callbackFormData.phoneNumber.trim()) {
      newErrors.phoneNumber = 'Phone number is required.';
    } else if (!/^\+?[0-9]{7,15}$/.test(callbackFormData.phoneNumber)) {
      newErrors.phoneNumber = 'Enter a valid phone number.';
    }
    return newErrors;
  };

  const handleCallbackChange = (e) => {
    const { name, value } = e.target;

    if (name === 'phoneNumber') {
      // Allow only numbers, spaces, and +
      const filteredValue = value.replace(/[^0-9+ ]/g, '');
      setcallbackFormData({ ...callbackFormData, [name]: filteredValue });
    } else {
      setcallbackFormData({ ...callbackFormData, [name]: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitState(submitStates[1]); // "inprogress"

    const validationErrors = validate();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      client
        .post(SUBMIT_CALLBACK_FORM_API, callbackFormData, false)
        .then((res) => {
          setSubmitState(submitStates[3]); // "success"
          setcallbackFormData(emptyCallbackFormData);
        })
        .catch((err) => {
          console.error('Unable to submit contact request', err);
          setSubmitState(submitStates[2]); // "failed"
        });

      setErrors({});
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className='grid gap-3 rounded-xl shadow-cardShadow bg-white px-4 py-4 h-fit'
    >
      <section>
        <div className='pt-2'>
          <h2 className='text-2xl text-colour3 font-bold mb-4 text-center'>
            Request a Callback
          </h2>
        </div>
      </section>

      <section>
        <div className='mb-4'>
          <label htmlFor='fullName' className='block text-gray-700 font-medium'>
            Full Name <span className='text-colour5'>*</span>
          </label>
          <input
            type='text'
            id='fullName'
            name='fullName'
            value={callbackFormData.fullName}
            onChange={handleCallbackChange}
            className='w-full p-2 border border-gray-300 rounded input_shadow'
          />
          {errors.fullName && (
            <p className='text-red-500 text-sm'>{errors.fullName}</p>
          )}
        </div>

        <div className='mb-4'>
          <label
            htmlFor='phoneNumber'
            className='block text-gray-700 font-medium'
          >
            Phone Number <span className='text-colour5'>*</span>
          </label>
          <input
            type='tel'
            id='phoneNumber'
            name='phoneNumber'
            value={callbackFormData.phoneNumber}
            onChange={handleCallbackChange}
            className='w-full p-2 border border-gray-300 rounded input_shadow'
            placeholder='+1234567890'
          />
          {errors.phoneNumber && (
            <p className='text-error-red text-sm'>{errors.phoneNumber}</p>
          )}
        </div>
        <div className='grid mb-4'>
          <label
            className='block text-colour3 text-sm font-semibold'
            htmlFor='projectType'
          >
            Project Type (optional)
          </label>
          <div className='grid w-full'>
            <ProjectTypeSelect onChange={handleCallbackChange} />
          </div>
        </div>
      </section>
      <section>
        <div className='grid items-center'>
          <button
            type='submit'
            className={`bg-colour5 text-colour1 w-full py-2 rounded text-lg button_shadow flex justify-center`}
            aria-label='Submit your form'
            disabled={submitState === submitStates[1]} // "inprogress"
          >
            {submitState === submitStates[1] ? (
              <LoadingSpinner sm={true} />
            ) : (
              <span>Request Callback</span>
            )}
          </button>
        </div>

        {submitState === submitStates[2] && ( // "failed"
          <div
            role='alert'
            aria-live='assertive'
            className='text-colour5 mt-4 text-center'
          >
            Unable to submit the form. Please try again.
          </div>
        )}
        {submitState === submitStates[3] && ( // "success"
          <div
            role='alert'
            aria-live='polite'
            className='text-green-500 mt-4 text-center'
          >
            Form submitted successfully!
          </div>
        )}
      </section>
    </form>
  );
}

export default CallbackForm;
