import React from 'react';
// Data
import { StaffListArray } from '../../utils/data/CompanyData';
import SubHeaderComponent from '../headers/SubHeaderComponent';
// Constants
import { CompanyName } from '../../utils/Constants';

function MeetTheTeamComponent({ isCentered }) {
  return (
    <section
      className='grid w-full min-h-screen'
      aria-labelledby='team-heading'
    >
      <div className='grid grid-rows-reg gap-6 items-center my-auto h-fit w-5/6 lg:w-3/4 mx-auto'>
        <SubHeaderComponent
          isCentered={isCentered}
          title={'Meet the Team Bringing Your Ideas to Life!'}
          subtitle={'The Design Team'}
        />

        {/* Members list */}
        <section className='grid mt-24 px-2'>
          <div className='grid grid-cols-3 three_item_grid gap-2 md:gap-8 mx-auto'>
            {StaffListArray.map((staffMember, index) => (
              <TeamMemberCard
                key={index}
                staffMember={staffMember}
                index={index}
              />
            ))}
          </div>
        </section>
      </div>
    </section>
  );
}
function TeamMemberCard({ staffMember, index }) {
  return (
    <article
      role='group'
      aria-labelledby={`staff-name-${staffMember.name}`}
      aria-describedby={`staff-bio-${staffMember.name}`}
      className={`relative grid px-2 py-1 shadow-cardShadow rounded-md max-w-sm three_item bg-colour1 ${
        index === 0
          ? 'shadow-colour5/50 hover:shadow-colour5'
          : index === 1
          ? 'shadow-orange-600/50 hover:shadow-orange-600'
          : 'shadow-orange-700/50 hover:shadow-orange-700'
      }`}
    >
      <figure className='w-44 h-44 mx-auto rounded-full overflow-hidden shadow-cardShadow outline outline-4 outline-colour6'>
        <img
          src={staffMember.imageSource}
          alt={`${staffMember.name}, ${staffMember.position} at ${CompanyName}`}
          className={`w-full bg-white object-fill h-auto `}
        />
      </figure>
      <div className='grid px-1 pb-4'>
        <div className='text-center'>
          <h3
            id={`staff-name-${staffMember.name}`}
            className='text-colour5 text-3xl lg:text-4xl font-abril italic text-stroke-colour3'
          >
            {staffMember.name}
          </h3>
        </div>
        <div className='text-center text-lg'>
          <span>{staffMember.position}</span>
        </div>
        <div
          id={`staff-bio-${staffMember.name}`}
          className='text-center text-gray-600'
        >
          {staffMember.bio}
        </div>
      </div>
    </article>
  );
}

export default MeetTheTeamComponent;
