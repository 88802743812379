import React, { useState } from 'react';
// Data
import { salesAppTypesArray } from '../../../utils/data/CompanyData';
// Constants
import { CompanyName } from '../../../utils/Constants';

function ProjectPageHeader({ selectAppType, thisRef }) {
  const [salesOptions] = useState(salesAppTypesArray);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleClick = (type) => {
    setSelectedOption(type.name);
    selectAppType(type);
  };

  return (
    <header className='grid w-full h-full'>
      <div className='grid w-full h-full overflow-hidden p-2 lg:px-4'>
        <div className='grid grid-rows-a1a'>
          <section className='grid pt-4 my-2 text-center xs:px-4 lg:px-10'>
            <div className='grid gap-2 w-fit mx-auto px-4'>
              <div>
                <h2 className='lg:text-xl'>{CompanyName} Projects</h2>
              </div>
              <div>
                <h1 className='text-3xl xs:text-4xl lg:text-5xl 2xl:text-6xl font-bold text-colour3'>
                  Learn About Your <span className='text-colour5'>Project</span>{' '}
                  Type!
                </h1>
              </div>
              <div className='border-b-2 border-solid border-colour5 pt-2'></div>
            </div>
          </section>
          <div className='grid items-center mt-6 lg:mt-0'>
            <section className='relative grid w-[80%] h-fit mx-auto justify-center items-center'>
              <div className='grid grid-cols-2 lg:grid-cols-4 gap-4 px-2'>
                {salesOptions.map((type, index) => {
                  const isSelected = selectedOption === type.name;
                  const fadeClass =
                    selectedOption && !isSelected ? 'fade-out' : '';
                  const moveClass = isSelected ? '' : '';
                  return (
                    <section
                      key={index}
                      title={type.label}
                      onClick={() => handleClick(type)}
                      className={`rounded-xl bg-white max-w-[200px] lg:max-w-[280px] shadow-[0_3px_10px_rgb(0,0,0,0.2)] active:scale-95 cursor-pointer duration-200 ${fadeClass} ${moveClass}`}
                    >
                      <div className='p-6'>
                        <img src={type.image} alt={type.label} loading='lazy' />
                      </div>
                    </section>
                  );
                })}
              </div>
            </section>
          </div>

          <section ref={thisRef} className='grid my-2 w-full'>
            <div className='grid justify-center pb-4'>
              <ul className='flex gap-6 text-colour5 text-xl font-semibold'>
                {salesOptions.map((type, index) => (
                  <li key={index}>
                    <span
                      onClick={() => handleClick(type)}
                      className='hover:brightness-75 cursor-pointer'
                    >
                      {type.label}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </section>
        </div>
      </div>
    </header>
  );
}

export default ProjectPageHeader;
