import React, { useEffect } from 'react';
// Images
import DisplayImage from '../../assets/images/components/multiple-screen-working-person.png';
// Icons
import { FaCircleNotch } from 'react-icons/fa';
// Constants
import { FREE_SITE_PAGE_URL, INFO_PAGE_URL } from '../../utils/Constants';
// Components
import SubHeaderComponent from '../headers/SubHeaderComponent';

const buildBusinessDataArray = [
  {
    name: 'experience',
    label: '35 Years Experience',
    description: (
      <>
        <span className='font-bold text-colour3'>With</span> 35 years of
        combined experience across web, circuit, and robot design, we’ve honed
        our skills to deliver innovative, reliable solutions. From simple
        websites to complex automation projects, our team’s expertise ensures
        top-notch results, tailored to meet your unique needs and vision.
      </>
    ),
  },
  {
    name: 'projects',
    label: '50+ Projects',
    description: (
      <>
        <span className='font-bold text-colour3'>We have</span> successfully
        completed many diverse projects, each tailored to our clients’
        specific goals. Whether it is a sleek website, a custom electronic
        solution, or a cutting-edge robot, every project reflects our commitment
        to quality and innovation. Your next project could be our best one yet.
      </>
    ),
  },
  {
    name: 'offer',
    label: 'Free One Page Site',
    description: (
      <>
        <span className='font-bold text-colour3'>To get</span> you started, we
        offer a free one-page website, perfect for showcasing your business or
        idea. It is our way of helping you establish an online presence quickly
        and affordably, while demonstrating the value we can bring to your
        larger digital strategy.{' '}
        <a
          href={FREE_SITE_PAGE_URL}
          className='text-colour5 font-medium italic underline underline-offset-2'
        >
          See offer!
        </a>
      </>
    ),
  },
  {
    name: 'bolt-ons',
    label: 'Add+ Features',
    description: (
      <>
        <span className='font-bold text-colour3'>Enhance</span> your business
        operations a huge amount time-saving features, including automated
        calendars, missed call text responses, and more. These bolt-ons
        streamline everyday tasks, improve efficiency, and help you focus on
        what matters most: growing your business.{' '}
        <a
          href={`${INFO_PAGE_URL}?type=features`}
          className='text-colour5 font-medium italic underline underline-offset-2'
        >
          View Options
        </a>
      </>
    ),
  },
];

function BuildYourBusniessComponent() {

  useEffect(() => {
    // Select the container
    const container = document.querySelector('.container-class'); // Replace with your container's class
    const targetDivs = document.getElementsByClassName('target-div');

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          Array.from(targetDivs).forEach((el) => {
            setTimeout(() => {
              el.classList.add('fade-up-animation');
            });
          });
        } else {
          Array.from(targetDivs).forEach((el) => {
            el.classList.remove('fade-up-animation');
          });
        }
      },
      { threshold: 0.2 }
    );

    if (container) observer.observe(container);

    return () => {
      if (container) observer.unobserve(container);
    };
  }, []);

  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     (entries) => {
  //       entries.forEach((entry) => {
  //         if (entry.isIntersecting) {
  //           entry.target.classList.add('fade-up-animation');
  //         }
  //       });
  //     },
  //     { threshold: 0.1 }
  //   );

  //   refs.current.forEach((el) => {
  //     if (el) observer.observe(el);
  //   });

  //   return () => {
  //     refs.current.forEach((el) => {
  //       if (el) observer.unobserve(el);
  //     });
  //   };
  // }, []);

  return (
    <section className='grid h-full w-full items-center'>
      <div className='grid h-fit xl:grid-cols-reg gap-8 lg:gap-20 w-4/5 mx-auto'>
        <section className='grid grid-rows-reg'>
          <SubHeaderComponent
            isCentered={false}
            title={
              <>
                Build Your Business with <br className='lg:hidden' /> Byte Toast
                Studio
              </>
            }
            subtitle={'What we do for you'}
          />

          <section className='grid w-full h-full'>
            <div
              className='relative grid items-center w-full min-h-[350px] h-full'
              style={{
                backgroundImage: `url(${DisplayImage})`,
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
            ></div>
          </section>
        </section>

        {/* Section 2 */}
        <section className='grid h-full w-full container-class'>
          <div className='grid h-full w-full md:grid-cols-2 gap-8 lg:gap-6 lg:pl-4'>
            {buildBusinessDataArray.length > 0 &&
              buildBusinessDataArray.map((data, index) => {
                return (
                  <section
                    key={data.name}
                    className='px-2 target-div opacity-0'
                  >
                    <div className='flex pb-2 items-center gap-3'>
                      <FaCircleNotch
                        className='text-2xl text-colour5 spin'
                        style={{ animationDelay: `${0.5 * index}s` }} // Adjust delay dynamically
                      />
                      <h6 className='text-lg font-semibold text-colour3 border-solid border-b-2 border-colour5'>
                        {data.label}
                      </h6>
                    </div>
                    <div>
                      <p className='text-sm'>{data.description}</p>
                    </div>
                  </section>
                );
              })}
          </div>
        </section>
      </div>
    </section>
  );
}

export default BuildYourBusniessComponent;
