import React from 'react';
// Data
import {
  portfolioPageAdditionalMeta,
  portfolioPageStructuredData,
} from '../../utils/data/MetaData';
// Constants
import { CompanyName } from '../../utils/Constants';
// Components
import { HelmetItem } from '../../components/utils/HelmetItem';
import Navbar from '../../components/nav/Navbar';
import PortfolioPageMainContainer from '../../components/portfolio/PortfolioPageMainContainer';
import WebsiteDisplayPortfolioContainer from '../../components/portfolio/WebsiteDisplayPortfolioContainer';
import FooterComponent from '../../components/footer/FooterComponent';

function PortfolioPage() {
  return (
    <>
      {/* Tab Data */}
      <HelmetItem
        PageName='Portfolio'
        desc={`Explore the portfolio of ${CompanyName}, showcasing our amazing websites, apps, circuits, and robots.`}
        keywords={`portfolio, websites, apps, circuits, robots, ${CompanyName}`}
        additionalMeta={portfolioPageAdditionalMeta}
        structuredData={portfolioPageStructuredData}
      />

      {/* Page */}
      <div className='grid gap-16 min-h-screen bg-main-background font-poppins'>
        <header className='grid grid-rows-reg min-h-screen w-full'>
          {/* Navigation */}
          <Navbar />
          <WebsiteDisplayPortfolioContainer />
        </header>

        {/* Main page content */}
        <PortfolioPageMainContainer />

        {/* Footer */}
        <FooterComponent />
      </div>
    </>
  );
}

export default PortfolioPage;
