// Images
// Post 1
import FlowerShop from '../../assets/images/blog/flower-shop-ecommerce-website-display.png';
import LaunchPost from '../../assets/images/blog/launching-byte-toast-studio-thumbnail.png';
import FitPalPost from '../../assets/images/blog/fitpal-sports-tracker-website.jpg';
// Post 2
import HairPost from '../../assets/images/blog/beauty-salon-hair-website-hero-section-full-page-social-post.jpg';
import FreePost from '../../assets/images/blog/free-one-page-website-social-media-post.png';
import CatAppPost from '../../assets/images/blog/cat-app-hero-section-website-large-screen-social-post.jpg';
import SpacePost from '../../assets/images/blog/space-planets-website-hero-section-full-page-social-post.png';
import LegoPost from '../../assets/images/blog/mr-little-bricks-profile-page-lego-design-large-screen-social-post.jpg';
// Post 3
import FiveThingPost from '../../assets/images/blog/5-things-to-know-web-developer-media-square.png';

export const tempBlogPosts = [
  {
    id: '1',
    title: 'Introducing Byte Toast Studio!',
    subTitle: 'Creating websites, mobile apps and custom circuits.',
    subject: 'Launch Announcement',
    slug: 'byte-toast-studio-launch',
    content: [
      `
      Welcome to Byte Toast Studio and our new website and media, we are your new partner in cutting-edge technology! Hi my name is Tom I am the creator of the studio and here to say. 🎉 We are thrilled to announce our launch as a full-service and friendly web and tech studio specializing in web development, software solutions, and circuit design. Whether you're a business looking to establish a digital presence, an innovator developing custom software, or a tech enthusiast building hardware projects, Byte Toast Studio is here to turn your vision into reality.
      In this first post, we will take you through what Byte Toast Studio offers, our mission, and how we plan to make life easy for you.`,

      `      What Started It All?
      We are a team of three highly skilled software developers, graphic artists and electrical engineers. That grew a bit tired of being limited by the companies we worked for. Coming together and combining our skills we create a business that is enjoyable, creative and pushing our skills to the limit. You can see some of our early projects here in the blog and on the main website www.bytetoast-studio.co.uk.`,

      `      The Mission!
      Our mission is to help individuals and businesses make lives better for themselves by having us create innovative solutions for them. Whether that is a more modern website, a store they can sell online products from, or an app to be used by employees saving time and money.
      We work well with our customers because we are friendly and easy to talk to without overloading you with technical terms. We can find what you need and create it for you, throwing in things you don't even know you needed.`,

      `    You can view our portfolio to get inspired by and see common website features by going to www.bytetoast-studio.co.uk/porfolio.

      Here is a closer look at the core services Byte Toast Studio provides:`,

      `      1. Web Development
        Your website is your digital storefront, and we are here to make sure it leaves a lasting impression. From sleek, user-friendly designs to fully optimized e-commerce platforms. We build responsive websites that look great on any device that are visually stunning, lightning-fast, and optimized for performance.

        Our web development services include:
        Custom Web Design tailored to your brand.
        SEO Optimization to help you rank higher on search engines.
        E-commerce Solutions for online stores.
        Maintenance and Support to keep your site running smoothly.`,

      `      2. Software Development
        Looking to streamline operations or bring a unique idea to life? We offer custom software solutions designed to fit your needs. Whether it is a web app, mobile app, or desktop application, we’ve got you covered.

        Our software development services include:
        Mobile App Development for iOS and Android.
        Web Applications for businesses of all sizes.
        Custom Software Tools to optimize workflows.
        Games and MiniGames.`,

      `      3. Circuit Design and Robotics
        We don’t stop at software and web development—Byte Toast Studio also excels in hardware and robotics. From designing custom circuits to building complex robotics systems, our engineering team ensures your ideas come to life in the real world. 

        Recent highlight: ArduPlot3D, our DIY robotics platform, allows users to design, program, and simulate their own robots. It is a perfect blend of innovation and accessibility for makers and tech enthusiasts. 
        You can view this project from its control website https://arduplot3d.netlify.app

        We also work on: 
        AI Robotics
        Smart Home Devices
        Wearable Tech
        Sensors and Automation`,

      `Our Unique Approach
      At Byte Toast Studio, we understand that every project is unique. That’s why we take a personalized approach to every client. We know you cant always remember everything at once so we take our time to chat about your needs, and don't penalize you for forgetting something you later need.

      Discovery Phase: We listen to your needs and goals.
      Custom Strategy: We create a tailored plan to deliver the best results.
      Iterative Development: You are involved at every stage, ensuring the final product exceeds expectations.
      We combine cutting-edge technology, creative thinking, and technical expertise to deliver projects that make a difference.`,

      `      Projects We are Excited About
      As we kick off our journey, we are excited to showcase some incredible projects:
      ArduPlot3D: A robotics platform where users can design and simulate robots with ease.
      Sun-Tracking Robot: Harness solar energy with a fully automated system.
      Cool Travel Website: A sleek, interactive platform that makes trip planning a breeze.
      Robot Arm Control: Control a robotic arm remotely using a web interface.
      These projects highlight the breadth of what we do—from robotics to software to cutting-edge web design.`,

      `Why Choose Byte Toast Studio?
      With so many tech studios out there, you might be wondering what sets us apart. Here’s why our clients choose Byte Toast Studio:
      End-to-End Services: We handle everything from initial concepts to post-launch support.
      Technical Expertise: Our team is skilled in the latest technologies and frameworks.
      Passion for Innovation: We love tackling complex problems and delivering unique solutions.
      Client-Centric Approach: Your success is our priority, and we work closely with you to achieve it.`,

      `Let’s Build Something Amazing Together`,

      `We are more than just a tech studio; we are your partner in innovation. Whether you are a startup needing a website, a business looking for custom software, or a maker with a robotics project, Byte Toast Studio is here to help.`,

      `Explore our website to learn more about our services or contact us today to discuss your next big project.
      www.bytetoast-studio.co.uk`,

      `Follow us on Instagram for updates, project showcases, and tech tips: @ByteToastStudio`,
    ],
    authorName: 'Tom Brockington',
    featuredImage: LaunchPost,
    thumbnailImage: LaunchPost,
    images: [FlowerShop, FitPalPost],
    isPublished: true,
    publishedAt: '2025-01-01T02:02:59.030Z',
    viewCount: 128,
    tags: ['Announcement', 'Business', 'Technology'],
  },
  {
    id: '2',
    title:
      'Get Online Fast With Byte Toast Studio: Free One-Page Website Offer!',
    subTitle: 'Get online fast and free.',
    subject: 'Offer',
    slug: 'free-one-page-site-offer',
    content: [
      `Starting a business or building your personal brand can be challenging, but getting online shouldn’t be. That’s why we at Byte Toast Studio are excited to offer a hassle-free, affordable solution: a one-page website completely free!`,

      `Whether you are launching a startup, showcasing your portfolio, or need a simple online presence, this free one page website offer is designed to get you online quickly and without extra costs. The site comes free with our low cost hosting packages that start from £12.50 per month. We have a range of options and add-ons available.`,

      `What’s Included in This Offer?`,

      `Here’s what you get when you choose our free one page website package: Attractive Design: A sleek, professional one page website tailored to your needs. Using our wide selection of templates and coding custom components.`,

      `Hosting for websites, server, and databases: Reliable and secure hosting with us, start from just £12.50/    month.
      Mobile-Friendly Design: Your site will look amazing on desktops, tablets, and phones.
      Basic SEO Setup: Ensure your site is easily searchable and ready to attract visitors.
      This offer is perfect for small businesses, freelancers, or anyone who needs a professional online presence without unnecessary hassle or expense.`,

      `Optional Extras - We have a range of features you can add to your site for a small additional fee built into your month bill.`,

      `Booking calanders - Have clients book online reservations.
      Email Accounts - Host as many email accounts as you like for less than most large competitors.
      SEO Performance Monitoring - We can check your sites SEO and other scores to make sure you are as high up as possible on search engines. This can be done monthly, weekly or daily.
      And many more available, you can view the full list of bolt-ons on the main website.`,

      `Whats great about a free one page website?`,

      `Websites are essentially to businesses today. A huge amount of business comes through search engines and the ranking systems of where you appear is heavily based on your website and online presence.
      Having a hastle free website set up for you quickly so you can get online and assess if the website is improving your business before spending large amount of money on custom websites.`,

      `You can monitor the performance of the website (or we can do it for you), see how many views it gets and calls come in from it. Then if you see improvents to your business, we can easily upgrade your website for better reach with more pages and features. If you pay monthly for hosting you can cancel at any time if you don't feel the site has worked out for you.`,

      `Why Choose Byte Toast Studio?`,

      `Because we are a friendly and high quality service, offering a spectacular deal to help you and your business achieve as much as possible. We offer the same support and availability to all our customers and can make sure you only pay for what you need for your business. we do not try and upsell rubbish you do not need. We are a new business and opened the doors officially in January 2025, we hope this free one page website offer will help get us running and spread our name as quickly as possible. We want to create and built tech products for everyone.`,

      `Affordable Pricing: Get a fully functional website and hosting from just £12.50/month—no hidden costs.
      Simple and Straightforward: We handle everything, from design to hosting, so you don’t have to worry about the technical details just send us information about your business and phots and that usually is enough for us to complete the site.`,

      `Quick Setup: Get your site live and ready to go in no time, we aim to have you online in 2 days.
      Ongoing Support: As a small, dedicated team, we’re here to help whenever you need it.
      Upgradable Packages: You can reach out to us at any point and ask for more features. If they are from our standard list then we can add that to your package quickly.
      We are passionate about helping businesses and persons get online easily, affordably, and effectively. We also enjoy challenges and getting the chance to create work we can be proud of.`,

      `How It Works Get in Touch: Reach out to us, and we will discuss your vision and needs.
      Design & Build: We will create your custom one-page website.
      Launch & Host: Once your site is ready, we will host it securely for just £12.50/month.
      It’s that simple—no hidden fees, no complicated processes. Just a professional website and reliable hosting.`,

      `Ready to Get Started?`,

      `Take the first step towards building your online presence today! Whether you are a small business owner, freelancer, or just looking for a personal website, our free one-page website offer is the perfect solution.`,

      `Contact us today to get started, and let Byte Toast Studio bring your vision to life.`,

      `hello@bytetoast-studio.co.uk`,

      `Small Print:
      - If you pay yearly and cancel no refunds.
      - £12.50/month is for the lowest quality of hosting only guaranting 90% reliable up time. This does not mean the site will be down 10% of the time. Its still very rare to lose connection on any priced server. However maintaince may impact uptime of a website. As well as unforcene factors. The price of hosting with 99% uptime guaranteed and multiple servers so maintaince on one will not effect your site, Is still affordable starting at £22.50 a month.
      - We do not provide a domain but can aquire and manage domains for you for £1 a month on top of the domain registra yearly fee.
      - A one page site as standard at £12.50 a month does not include any server based components such as a contact form.
      - Unless we are asked to remove it a small 'Made by bytetoast-studio.co.uk' will be placed at the bottom of the site.
      - We will give your access to all the reports and statistics to your site over any time period. However analyzing it is a bolt-on for you package.
      - We guarantee to check your site for bugs regularly
      - We guarantee to keep your site scoring 95 and above (unless your requested site components have a negative effect and we can prove this is the cause) on all 4 categories of website ranking on google page speed insights and other ranking software similar to googles`,
    ],
    authorName: 'Tom Brockington',
    featuredImage: FreePost,
    thumbnailImage: FreePost,
    images: [HairPost, SpacePost, CatAppPost, LegoPost],
    isPublished: true,
    publishedAt: '2025-01-02T02:02:59.030Z',
    viewCount: 128,
    tags: ['Announcement', 'Business', 'Free'],
  },
  {
    id: '3',
    title: '5 Things You Need to Know Before Hiring a Web Developer',
    subTitle: 'The basics you will be asked.',
    subject: 'Information',
    slug: '5-things-you-need-to-know-before-hiring-web-developer',
    content: [
      `Hiring a web developer can be a game-changer for your business or personal brand. The right developer will turn your vision into a functional, visually stunning website that works seamlessly across all devices. But before you jump into hiring, it’s essential to know what to expect and how to prepare. If you cant explain what it is you want and need, your developer will not be able to do exactly what you need. `,

      `Some companies can try and overload you will technical jargon, but you don't need to be an expert to know what you want.`,

      `Here are five key things you need to know before hiring a web developer to ensure a smooth and successful project.`,

      `1. Know Your Goals and Requirements
      Before you start searching for a web developer, be clear about what you want to achieve with your website. Ask yourself:`,

      `What is the primary purpose of the site? (e.g., selling products, showcasing a portfolio, providing information)
      How many pages will you need, and what content will they contain?
      Are there specific features you require? (e.g., contact forms, booking systems, e-commerce functionality)
      Having a clear vision will not only help you communicate effectively with your developer but also save time and money by reducing revisions.`,

      `NB: A website, the part you see in a browser is hosted on whats called a server. This is called your Frontend. Confusingly your website will also be refered to as 'Having A Server'. This is A different server and is known as the Backend. It serves data to your frontend about things like users, products, logins. There servers are separate and may be paid for seperately depending on your development company. Backend and frontend hosting can grow in cost as more items are added and users visit. `,

      `Pro Tip: Create a simple outline or mockup of your site’s layout to help the developer understand your ideas better.`,

      `1. Understand the Developer’s Role
      Not all web developers are the same, and their expertise can vary widely. Some focus on front-end development (the look and feel of the site), while others specialize in back-end development (the functionality and database work). Full-stack developers handle both.`,

      `Additionally, web developers are not always designers. If your project requires a strong visual identity or branding, you may need to hire a separate web designer or ensure the developer you choose has design experience.`,

      `Ask Questions Like:
      - Do you handle both development and design?
      - What tools or platforms do you use? (e.g., WordPress, custom coding, Shopify)
      - Do you have a portfolio I Can view?
      - What will my SEO (Search Engine Optimization) score be when completed?
      - Will it comply if GDPR laws
      - What will the hosting costs be.  `,

      `At Byte Toast Studio we prefer to use custom code for everything, becuase it give us complete control over what happens in your website. `,

      `3. Budget for the Full Project
      The cost of a website isn’t just about paying the developer. There are other factors to consider:`,

      `Domain Registration: Your site’s unique address (e.g., yourbusiness.com).
      Hosting: The server space needed to keep your website online.
      Ongoing Maintenance: Updates, bug fixes, and support after launch.
      Additional Features: Premium plugins, custom animations, or e-commerce integrations may add to the cost.
      Be upfront about your budget so the developer can give you a realistic idea of what’s possible. Coding can be complex at times and sometimes coding one section can take as long as other entire pages. Your paying them for their time and expertise in the field.`,

      `Pro Tip: Don’t just go for the cheapest option. A poorly built website can cost you more in lost opportunities and future fixes.`,

      `4. Communication Is Key
      Clear and consistent communication is critical to the success of your project. Before hiring a developer, assess how well they communicate:`,

      `Are they responsive to emails or messages?
      Do they explain technical concepts in a way you can understand?
      Do they ask detailed questions about your goals and vision?
      A good developer should make you feel confident and comfortable throughout the process, keeping you updated on progress and timelines.`,

      `Pro Tip: Establish milestones and deadlines at the start of the project to keep everyone aligned`,

      `5. Ask for a Portfolio and References
      A web developer’s past work is the best indicator of what they can deliver for you. Always ask for:`,

      `A Portfolio: Look at examples of their previous projects. Pay attention to design quality, functionality, and how well the sites meet their clients' needs. You can always pick and choose for different projects and combine them into your own website.
      Client Testimonials: Check reviews or ask for references to understand their reliability, professionalism, and ability to meet deadlines.
      If you like what you see, it’s a good sign they can bring your vision to life.`,

      `Final Thoughts`,

      `Hiring a web developer is an important decision that can have a big impact on your business. By understanding your goals, setting realistic expectations, and doing your research, you can find the perfect developer to bring your website to life.`,

      `Need help getting started? At Byte Toast Studio, we specialize in affordable, high-quality websites tailored to your needs. From sleek one-page designs to full-featured business sites, we’ve got you covered.
      From Janurary 2025 we are offering FREE one page websites to get you online fast. View the details here www.bytetoast-studio.co.uk/one-page-free-website`,

      `Contact us today to discuss your project and let’s build something great together!`,
    ],
    authorName: 'Tom Brockington',
    featuredImage: FiveThingPost,
    thumbnailImage: FiveThingPost,
    images: [],
    isPublished: true,
    publishedAt: '2025-01-02T02:02:59.030Z',
    viewCount: 128,
    tags: ['Announcement', 'Business', 'Free'],
  },
];
