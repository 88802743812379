import React from 'react';
// Icons
import ArrowIcon from '../../assets/images/icons/curved_arrow_icon_show_more_indicator.svg';
import { MdOutlineMail } from 'react-icons/md';
import { AiOutlinePhone } from 'react-icons/ai';
import { FaRegCirclePlay } from 'react-icons/fa6';
import { MdOutlineWeb } from 'react-icons/md';
// Constants
import { CONTACT_PAGE_URL, PORTFOLIO_PAGE_URL } from '../../utils/Constants';
// Components
import SubHeaderComponent from '../headers/SubHeaderComponent';

function PortfolioAndContactCta({ isCentered }) {
  return (
    <section
      className='grid h-full w-full min-h-screen'
      aria-labelledby='cta-heading'
    >
      <div className='grid grid-rows-reg gap-8 w-3/4 mx-auto mb-6'>
        <SubHeaderComponent
          isCentered={isCentered}
          title={'Do you need any assistance?'}
          subtitle={'More Information'}
        />

        <section className='grid' aria-labelledby='portfolio-contact-heading'>
          <h2 id='portfolio-contact-heading' className='sr-only'>
            Explore Our Portfolio and Contact Us
          </h2>
          <div className='grid md:grid-cols-2 md:pb-10'>
            {/* Portfolio Section */}
            <section
              className='grid relative w-full h-full'
              aria-labelledby='portfolio-heading'
            >
              <h3 id='portfolio-heading' className='sr-only'>
                Portfolio
              </h3>
              <div className='relative grid w-4/5 h-4/5 mx-auto my-auto z-10'>
                <div
                  className='absolute top-1/3 -left-16 sm:-left-24 md:-left-28 transform -translate-y-1/2'
                  aria-hidden='true'
                >
                  <div className='max-w-16 leading-4 pb-1 font-semibold text-colour5 font-abril'>
                    <span className='text-xl leading-5'>Get Inspired!</span>
                  </div>
                  <img
                    src={ArrowIcon}
                    alt='Arrow pointing to portfolio section'
                    className='md:w-16 lg:w-20'
                  />
                </div>
                <a
                  onClick={() => {
                    window.scroll(0, 0);
                  }}
                  href={PORTFOLIO_PAGE_URL}
                  className='rounded-xl grid items-center max-h-[50vh] justify-center w-full h-full neo_one active:scale-95 duration-150'
                  aria-label='View our portfolio'
                >
                  <div className='grid gap-2 text-colour3'>
                    <div className='grid grid-flow-col w-fit mx-auto gap-2'>
                      <FaRegCirclePlay className='text-3xl sm:text-4xl lg:text-7xl' />
                      <MdOutlineWeb className='text-3xl sm:text-4xl lg:text-7xl' />
                    </div>
                    <span className='text-xl lg:text-2xl font-semibold text-center'>
                      Portfolio
                    </span>
                  </div>
                </a>
              </div>
              <div
                className='absolute grid w-2/3 h-1/2 -mb-[8px] md:-mb-0 bottom-0 right-0 border-b-8 rounded-br-3xl border-solid border-colour5 border-r-4'
                aria-hidden='true'
              ></div>
            </section>
            {/* Contact Section */}
            <section
              className='grid relative w-full h-full'
              aria-labelledby='contact-heading'
            >
              <h3 id='contact-heading' className='sr-only'>
                Contact Us
              </h3>
              <div className='grid w-4/5 h-4/5 mx-auto my-auto p-1 md:p-0 z-10'>
                <div
                  className='absolute bottom-8 -right-10 md:-right-16 transform -translate-y-1/2'
                  aria-hidden='true'
                >
                  <img
                    src={ArrowIcon}
                    alt='Arrow pointing to contact section'
                    className='md:w-16 lg:w-20 rot'
                    style={{ transform: 'rotate(180deg)' }}
                  />
                  <div className='max-w-16 leading-4 pb-1 font-semibold text-colour5 font-abril'>
                    <span className='text-xl leading-5'>Get in Touch!</span>
                  </div>
                </div>
                <a
                  href={CONTACT_PAGE_URL}
                  onClick={() => {
                    window.scroll(0, 0);
                  }}
                  className='rounded-xl grid items-center max-h-[50vh] justify-center w-full h-full neo_one active:scale-95 duration-150'
                  aria-label='Contact us via email or phone'
                >
                  <div className='grid gap-2 text-center text-colour3'>
                    <div className='grid grid-flow-col w-fit mx-auto gap-2'>
                      <MdOutlineMail className='text-3xl sm:text-4xl lg:text-7xl' />
                      <AiOutlinePhone className='text-3xl sm:text-4xl lg:text-7xl' />
                    </div>
                    <span className='text-xl lg:text-2xl font-semibold'>
                      Contact
                    </span>
                  </div>
                </a>
              </div>
              <div
                className='absolute grid w-2/3 h-1/2 border-l-4 rounded-tl-3xl md:-ml-[4px] border-t-8 border-solid border-colour5'
                aria-hidden='true'
              ></div>
            </section>
          </div>
        </section>
        <section className='grid pb-10'>
          <div className='text-center lg:text-end text-xl'>
            <p>Get all the assistance you need to create <span className='text-colour5'>your</span> project.</p>
          </div>
        </section>
      </div>
    </section>
  );
}

export default PortfolioAndContactCta;
